import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "../DataTable";
import DeviceIcon from "../DeviceIcon";
import DeviceModal from "../modals/DeviceModal";
import Spinner from "../Spinner";
import WiFiStrengthIcon from "../WiFiStrengthIcon";
import { t } from "@lingui/macro";
import { getQueryParam } from "../utils/util";
import { useAuth } from "../../services/auth-context";

const DeviceLabel: React.FC<any> = ({ device }) => (
  <span className="flex flex-row items-center">
    {device.label || device.id.split("_")[1]}
  </span>
);

interface DevicesListProps {
  devices: Array<any>;
  onDeviceEdited: any;
  onDeviceRemoved: any;
  locations?: Array<any>;
  merchantId?: string;
  noItemsHeader?: string;
  noItemsBody?: string;
}

const DeviceList: React.FC<DevicesListProps> = ({
  devices,
  onDeviceEdited,
  onDeviceRemoved,
  merchantId,
  locations,
  noItemsHeader = t`No devices found`,
  noItemsBody = t`No devices were found for this search`,
}) => {
  const { isEmployee } = useAuth();
  const urlDeviceID = getQueryParam("selected");

  const [selectedDevice, setSelectedDevice] = useState<any>();
  const selectedDeviceLocation: any =
    locations &&
    selectedDevice &&
    locations.filter((l) => l.id === selectedDevice.location)[0];

  useEffect(() => {
    const selected = devices.filter((d) => d.id === urlDeviceID)[0];
    if (selected) {
      setSelectedDevice(selected);
      window.history.replaceState("", "", "?");
    }
  }, [urlDeviceID, devices]);

  const columns = [
    {
      name: "icon",
      label: "",
      type: "icon",
    },
    {
      name: "id",
      label: t`Label`,
      type: "string",
    },
    {
      name: "serialNumber",
      label: t`Serial Number`,
      type: "string",
    },
    {
      name: "lastSeen",
      label: t`Last Seen`,
      type: "string",
    },
    {
      name: "wifiStrength",
      label: "Wi-Fi",
      type: "icon",
    },
    {
      name: "enabled",
      label: t`Enabled`,
      type: "boolean",
    },
  ];

  if (!devices) {
    return (
      <div className="flex justify-center p-responsive">
        <Spinner size={20} color="gray"></Spinner>
      </div>
    );
  }

  if (locations) {
    columns.splice(4, 0, {
      name: "location",
      label: t`Location`,
      type: "string",
    });
  }

  const columnsData = devices.map((device: any) => ({
    icon: <DeviceIcon device={device} />,
    id: DeviceLabel({ device }),
    serialNumber: device.serial_number,
    location: locations?.find((l: any) => l.id === device.location)?.name,
    lastSeen: moment(device.last_seen).fromNow(),
    wifiStrength: <WiFiStrengthIcon device={device} />,
    enabled: device.enabled ? t`Yes` : t`No`,
    click: isEmployee ? null : () => setSelectedDevice(device),
  }));

  return (
    <>
      <DeviceModal
        visible={!!selectedDevice}
        device={selectedDevice}
        onCancel={() => setSelectedDevice(null)}
        onSubmitSuccess={onDeviceEdited}
        onClickOutside={() => setSelectedDevice(null)}
        onRemove={onDeviceRemoved}
        onEdited={onDeviceEdited}
        merchantId={merchantId}
        location={selectedDeviceLocation}
      />
      <DataTable
        data={columnsData}
        noItemsHeader={noItemsHeader}
        noItemsBody={noItemsBody}
        headerClasses="pl-responsive"
        tdClass="px-responsive"
        tdChildClass="py-2"
        disableFlexBetween={true}
        disableFullWidthCells={true}
        noBorderTopOnHeaders={true}
        roundCorners={true}
        columns={columns}
      />
    </>
  );
};

export default DeviceList;
