import { data } from "autoprefixer";
import React from "react";
import { Link } from "react-router-dom";

type columntType = {
  name: string;
  label: string;
  type: string;
  headerClass?: string;
  tdClass?: string;
};

interface dataTableProps {
  columns: Array<columntType>;
  data: Array<any>;
  tdClass?: string;
  tdChildClass?: string;
  trChildClass?: string;
  noHeaders?: boolean;
  disableFullWidthCells?: boolean;
  disableFlexBetween?: boolean;
  enableHalfWidthCells?: boolean;
  showBottomBorder?: boolean;
  headerClasses?: string;
  roundCorners?: boolean;
  noBorderTopOnHeaders?: boolean;
  noItemsHeader: string;
  noItemsBody: string;
  footer?: any;
}

const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key];

const DataTable: React.FC<dataTableProps> = (props) => {
  const {
    columns,
    noHeaders,
    data: dataRow,
    tdClass,
    tdChildClass,
    trChildClass,
    disableFullWidthCells,
    disableFlexBetween,
    enableHalfWidthCells,
    showBottomBorder,
    headerClasses,
    roundCorners,
    noBorderTopOnHeaders,
    noItemsHeader,
    noItemsBody,
    footer,
  } = props;

  if (!data) return null;

  if (!dataRow.length) {
    return (
      <div className="p-responsive space-y-4">
        <div className="text-responsive-xl text-center">{noItemsHeader}</div>
        <div className="text-responsive-base text-center">{noItemsBody}</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <div
            className={`overflow-hidden border-gray-200 ${
              roundCorners && "sm:rounded-xl"
            }`}
          >
            {/* <table className='min-w-full  table-fixed divide-y divide-gray-200'> */}
            <table className="table-fixed divide-y divide-gray-200 min-w-full">
              {!noHeaders && (
                <thead className="text-black">
                  <tr>
                    {columns.map((column) => (
                      <th
                        key={column.name}
                        scope="col"
                        className={`
                          ${headerClasses}
                          ${column?.headerClass} 
                          whitespace-nowrap py-responsive-sm text-left text-responsive-base font-medium text-black tracking-wider
                          ${!noBorderTopOnHeaders && "border-t"}
                        `}
                      >
                        {column.label}
                      </th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody className="text-responsive-base font-normal">
                {dataRow.map((row, i, { length }) => (
                  <tr
                    key={i}
                    className={
                      (row.highlight ? "bg-green-50" : "bg-white") +
                      ` ${row.click && " cursor-pointer "} hover:bg-gray-50 ${
                        !disableFlexBetween && "flex justify-around"
                      } ${roundCorners && "sm:rounded-xl"} ${trChildClass}`
                    }
                    onClick={row.click}
                  >
                    {columns.map((item: any, j) => (
                      <td
                        key={j}
                        className={`${
                          !disableFullWidthCells && "w-full flex items-center"
                        } ${
                          enableHalfWidthCells && "w-1/2"
                        } whitespace-nowrap text-gray-900 ${
                          (showBottomBorder || i < length - 1) && "border-b"
                        } ${!!tdClass && tdClass}`}
                      >
                        {row.url ? (
                          <Link to={row.url} className={"flex"}>
                            <div
                              className={`${!!tdChildClass && tdChildClass} ${
                                item.tdClass
                              }`}
                            >
                              {getKeyValue(row, item.name)}
                            </div>
                          </Link>
                        ) : (
                          <div
                            className={`${!!tdChildClass && tdChildClass} ${
                              item.tdClass
                            }`}
                          >
                            {getKeyValue(row, item.name)}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
