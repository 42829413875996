const defaultSize = 36;

const DeviceIcon: React.FC<{ device: any; size?: number }> = ({
  device,
  size,
}) => (
  <span className="flex flex-row items-center w-full">
    <img
      className=""
      width={size || defaultSize}
      alt="device-icon"
      height={size || defaultSize}
      src={`/img/devices/${device.device_type}.svg`}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = `/img/devices/default_device.svg`;
      }}
    />
  </span>
);

export default DeviceIcon;
