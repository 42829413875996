import { API } from "aws-amplify";
import { DateTime } from "luxon";

export async function querySales({
  account,
  location,
  period,
  compare,
}: {
  account: string;
  location: string;
  period: string;
  compare?: string;
}) {
  const { start, end, interval } = parseInterval(period);

  const queryStringParameters: {
    location?: string;
    interval: string;
    period: string;
    compare?: string;
  } = {
    interval,
    period: `${start}/${end}`,
  };

  if (location) {
    queryStringParameters.location = location;
  }

  if (compare) {
    const { start, end } = parseInterval(compare);
    queryStringParameters.compare = `${start}/${end}`;
  }

  const { sales } = await API.get("Conduit", `/accounts/${account}/sales`, {
    queryStringParameters,
  });

  return sales;
}

function parseInterval(interval: string) {
  const day = DateTime.fromFormat(interval, "yyyy'-'MM'-'dd");
  if (day.isValid) {
    const start = day.toISODate();
    const end = day.toISODate();
    return { start, end, interval: "hour" };
  }

  const week = DateTime.fromFormat(interval, "kkkk'-W'WW");
  if (week.isValid) {
    const start = week.startOf("week").toISODate();
    const end = week.endOf("week").toISODate();
    return { start, end, interval: "day" };
  }

  // const quarter = DateTime.fromFormat(interval, "yyyy'-Q'q");
  // if (quarter.isValid) {
  //   const start = quarter.startOf("quarter").startOf("week").toISODate();
  //   const end = quarter.endOf("quarter").endOf("week").toISODate();
  //   return { start, end, interval: "week" };
  // }

  throw new Error(`Unknown interval format: ${interval}`);
}
