import React from "react";
import { t } from "@lingui/macro";
import QrPaymentsSettingsComponent from "./QrPaymentsSettingsComponent";

const BuildQrOptions: React.FC<{
  location: any;
  updateLocation: any;
  merchantId: any;
  register: any;
  handleSubmit: any;
  reset: any;
  isSubmitting: any;
  setIsSubmitting: any;
  requestError: any;
  setRequestError: any;
}> = ({
  location,
  updateLocation,
  merchantId,
  register,
  handleSubmit,
  reset,
  isSubmitting,
  setIsSubmitting,
  requestError,
  setRequestError,
}) => {
  return (
    <QrPaymentsSettingsComponent
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      location={location}
      register={register}
      merchantId={merchantId}
      updateLocation={updateLocation}
      setRequestError={setRequestError}
      setIsSubmitting={setIsSubmitting}
      requestError={requestError}
      reset={reset}
    ></QrPaymentsSettingsComponent>
  );
};

export const QrPaymentsTab = {
  name: "QR Payments",
  resellersOnly: false,
  navContent: (active: boolean) => (
    <div className={"flex"}>
      <img
        className=""
        width="50"
        alt="qr"
        src={`/img/payment_options/qr-payments.png`}
      />
      <span className={"ml-2 text-lg my-auto"}>QR Payments</span>
    </div>
  ),
  headerText: t`QR Payments`,
  build: BuildQrOptions,
};

export default React.memo(BuildQrOptions);
