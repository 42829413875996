import { API } from "aws-amplify";
import moment from "moment-timezone";
import DataTable from "../components/DataTable";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import DataContainer from "../components/DataContainer";
import { LogicBadge } from "../components/Badge";
import ResourceHeader from "../components/ResourceHeader";
import ResourceId from "../components/ResourceId";
import Spinner from "../components/Spinner";
import Main from "./containers/Main";
import { t, Trans } from "@lingui/macro";
import { getCurrentLocale } from "../util/i18n";

moment.locale(getCurrentLocale());

type Props = {
  id?: string;
};

function printAmount(amount: number): string {
  const amountString = `$ ${((amount >= 0 ? amount : -amount) / 100).toFixed(
    2
  )}`;
  return amount < 0 ? `(${amountString})` : amountString;
}

const Payout: React.FC<RouteComponentProps<Props>> = (
  props: RouteComponentProps<Props>
) => {
  const { params } = props.match;
  const [payout, setPayout] = useState<any[] | any>(null);
  const history = useHistory();

  useEffect(() => {
    API.get("Conduit", `/stripe/payouts/${params.id}`, {}).then(
      async (payout) => {
        setPayout(payout);
      }
    );
  }, [params]);

  if (!payout) {
    return (
      <Main>
        <div className="flex justify-center p-responsive">
          <Spinner size={20} color="gray"></Spinner>
        </div>
      </Main>
    );
  }

  const summaryDetails = payout.data.reduce(
    (details: any, balanceTransaction: any) => {
      return {
        countCharges:
          details.countCharges +
          (balanceTransaction.type === "payment" ? 1 : 0),
        grossCharges:
          details.grossCharges +
          (balanceTransaction.type === "payment"
            ? balanceTransaction.amount
            : 0),
        feeCharges:
          details.feeCharges +
          (balanceTransaction.type === "payment" ? balanceTransaction.fee : 0),
        countRefunds:
          details.countRefunds +
          (balanceTransaction.type === "payment_refund" ? 1 : 0),
        grossRefunds:
          details.grossRefunds +
          (balanceTransaction.type === "payment_refund"
            ? balanceTransaction.amount
            : 0),
        feeRefunds:
          details.feeRefunds +
          (balanceTransaction.type === "payment_refund"
            ? balanceTransaction.fee
            : 0),
        countAdjustment:
          details.countAdjustment +
          (balanceTransaction.type === "adjustment" ? 1 : 0),
        grossAdjustment:
          details.grossAdjustment +
          (balanceTransaction.type === "adjustment"
            ? balanceTransaction.amount
            : 0),
        feeAdjustment:
          details.feeAdjustment +
          (balanceTransaction.type === "adjustment"
            ? balanceTransaction.fee
            : 0),
        payoutBalance:
          details.payoutBalance +
          (balanceTransaction.type === "payout"
            ? balanceTransaction.amount
            : 0),
      };
    },
    {
      countCharges: 0,
      grossCharges: 0,
      feeCharges: 0,
      countRefunds: 0,
      grossRefunds: 0,
      feeRefunds: 0,
      countAdjustment: 0,
      grossAdjustment: 0,
      feeAdjustment: 0,
      payoutBalance: 0,
    }
  );

  const summaryDetailsData = [
    {
      name: t`Charges`,
      count: summaryDetails.countCharges,
      gross: printAmount(summaryDetails.grossCharges),
      fees: printAmount(-summaryDetails.feeCharges),
      total: printAmount(
        summaryDetails.grossCharges - summaryDetails.feeCharges
      ),
    },
    {
      name: t`Refunds`,
      count: summaryDetails.countRefunds,
      gross: printAmount(summaryDetails.grossRefunds),
      fees: printAmount(-summaryDetails.feeRefunds),
      total: printAmount(
        summaryDetails.grossRefunds - summaryDetails.feeRefunds
      ),
    },
    {
      name: t`Adjustments`,
      count: summaryDetails.countAdjustment,
      gross: printAmount(summaryDetails.grossAdjustment),
      fees: printAmount(-summaryDetails.feeAdjustment),
      total: printAmount(
        summaryDetails.grossAdjustment - summaryDetails.feeAdjustment
      ),
    },
    {
      name: t`Payouts`,
      total: printAmount(-summaryDetails.payoutBalance),
    },
  ];

  const transactions = payout.data.filter(
    (t: any) => t.type === "payment" || t.type === "payment_refund"
  );

  const transactionsData = transactions.map((transaction: any) => ({
    click: () => {
      history.push(
        transaction.type === "payment_refund"
          ? `/transactions/${
              transactions.find(
                (t: any) => t.source.id === transaction.source.charge
              ).source.source_transfer.source_transaction.description
            }`
          : `/transactions/${transaction.source.source_transfer.source_transaction.description}`
      );
    },
    type: (
      <LogicBadge
        value={transaction.type.split("_").join(" ")}
        definition={{
          payment: "green",
          "payment refund": "yellow",
          adjustment: "yellow",
        }}
      />
    ),
    gross: printAmount(transaction.amount),
    fees: printAmount(-transaction.fee),
    total: printAmount(transaction.amount - transaction.fee),
    created: moment(new Date(transaction.created * 1000))
      .utc()
      .format("MMM DD"),
  }));

  return (
    <Main>
      <div className="pb-7 mb-8 border-b">
        <div className="flex flex-row justify-between pb-6">
          <ResourceHeader>
            <Trans>Payout Details</Trans>
          </ResourceHeader>
          <ResourceId>{params.id}</ResourceId>
        </div>
        <DataContainer title="Summary">
          <DataTable
            data={summaryDetailsData}
            noItemsHeader={t`No data`}
            noItemsBody={t`Data is not available`}
            headerClasses="pl-responsive"
            tdClass="px-responsive"
            tdChildClass="py-2"
            disableFlexBetween={true}
            disableFullWidthCells={true}
            noBorderTopOnHeaders={true}
            columns={[
              {
                name: "name",
                label: "",
                type: "string",
              },
              {
                name: "count",
                label: t`Count`,
                type: "number",
              },
              {
                name: "gross",
                label: t`Gross`,
                type: "currency",
                tdClass: "text-right",
              },
              {
                name: "fees",
                label: t`Fees`,
                type: "currency",
                tdClass: "text-right",
              },
              {
                name: "total",
                label: t`Total`,
                type: "currency",
                tdClass: "text-right",
              },
            ]}
            roundCorners={true}
          />
        </DataContainer>
        <br />
        <DataContainer title={t`Transactions`}>
          <DataTable
            data={transactionsData}
            noItemsHeader={t`No data`}
            noItemsBody={t`Data is not available`}
            headerClasses="pl-responsive"
            tdClass="px-responsive"
            tdChildClass="py-2"
            disableFlexBetween={true}
            disableFullWidthCells={true}
            noBorderTopOnHeaders={true}
            columns={[
              {
                name: "type",
                label: t`Type`,
                type: "string",
              },
              {
                name: "gross",
                label: t`Gross`,
                type: "currency",
                tdClass: "text-right",
              },
              {
                name: "fees",
                label: t`Fees`,
                type: "currency",
                tdClass: "text-right",
              },
              {
                name: "total",
                label: t`Total`,
                type: "currency",
                tdClass: "text-right",
              },
              {
                name: "created",
                label: t`Created`,
                type: "datetime",
              },
            ]}
            roundCorners={true}
          />
        </DataContainer>
      </div>
    </Main>
  );
};

export default Payout;
