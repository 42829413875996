import { API } from "aws-amplify";
import { useState } from "react";
import { useForm } from "react-hook-form";
import removeEmptyObjectProps from "../../util/removeEmptyObjectProps";
import { LogicBadge } from "../Badge";
import Modal from "../Modal";
import ModalForm from "../ModalForm";
import { t, Trans } from "@lingui/macro";

const NewDeviceModal: React.FC<{
  locationId: string;
  onCancel: any;
  onSubmitSuccess: any;
  onClickOutside?: any;
  visible: boolean;
  merchantId?: string;
}> = ({
  locationId,
  onCancel,
  onSubmitSuccess,
  onClickOutside,
  visible,
  merchantId,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");

  const onFormSubmit = (formData: any) => {
    setIsSubmitting(true);

    API.post("Conduit", "/devices", {
      headers: merchantId ? { "copper-account": merchantId } : {},
      body: {
        ...removeEmptyObjectProps(formData),
        location: locationId,
      },
    })
      .then(() => {
        onSubmitSuccess();
      })
      .catch((err) => {
        setRequestError(err.response.data.error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onModalCancel = () => {
    onCancel();
    setRequestError("");
    setIsSubmitting(false);
  };

  const formErrorDisplay = (err: any) =>
    err &&
    err.type === "required" && (
      <div className="label-error-sm pl-1.5 label-error -mb-4 md:-mb-3">
        {err.message}
      </div>
    );

  return (
    <Modal onClickOutside={onClickOutside} visible={visible}>
      <ModalForm
        title={t`Register device`}
        isSubmitting={isSubmitting}
        onCancel={onModalCancel}
        handleSubmit={handleSubmit(onFormSubmit)}
        submitError={requestError}
      >
        <div className="flex flex-col">
          <div className="flex flex-col space-y-6 pr-7 -mt-3">
            <label className="text-responsive-base font-medium py-2">
              <Trans>Registration Code</Trans>
            </label>
          </div>
          <div className="w-full">
            <input
              ref={register({ required: t`Registration code required` })}
              id="registration_code"
              name="registration_code"
              className="copper-input"
              placeholder="apple-grape-orange"
            />
            {formErrorDisplay(errors?.registration_code)}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row pr-7">
            <label className="text-responsive-base font-medium py-2">
              <Trans>Device label</Trans>
            </label>
            <div className="mt-1.5 ml-2.5">
              <LogicBadge
                value={"optional"}
                definition={{
                  optional: { color: "#888", background: "#e5e5e5" },
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <input
              ref={register()}
              id="label"
              name="label"
              className="copper-input"
            />
            {formErrorDisplay(errors?.label)}
          </div>
        </div>
      </ModalForm>
    </Modal>
  );
};

export default NewDeviceModal;
