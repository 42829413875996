export const locale: { [key: string]: any } = {
  SG: {
    zipRegex: /^(?!.*[DFIOQU])[0-9]{6}$/,
    currency: "SGD",
  },
  US: {
    zipRegex: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    currency: "USD",
  },
  AU: {
    zipRegex: /^(\d{4})$/,
    currency: "AUD",
  },
  PE: {
    zipRegex: /^([0-9]{5})$/,
    currency: "PEN",
  },
};

export function getCurrency(countryCode: string) {
  return locale[countryCode].currency || "";
}

export function getZipCodeRegex(countryCode: string) {
  return locale[countryCode].zipRegex ?? new RegExp("");
}

export function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;
  if (!browserLocales) {
    return undefined;
  }
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}
