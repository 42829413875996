import React, { useState } from "react";
import Toggle from "../../Toggle";
import TipField from "./TipField";
import { t, Trans } from "@lingui/macro";

const TipSettingsComponent: React.FC<{
  isDisabled?: boolean;
  register: any;
  location?: any;
  setValue: any;
  isHide?: boolean;
  isAustralia: boolean;
  errors: any;
}> = ({
  location,
  setValue,
  register,
  isHide,
  isAustralia,
  errors,
  isDisabled = false,
}) => {
  const { settings } = location;
  const [enabled, setEnabled] = useState(settings?.tips?.enabled ?? false);
  const [tipIncludesTax, setTipIncludesTax] = useState(
    isAustralia || (settings?.tips?.include_tax ?? false)
  );

  return isHide !== true ? (
    <div className="space-y-3">
      <div className="flex items-center h-8">
        <Toggle
          onChange={(value: boolean) => {
            const newValue = !enabled;
            setEnabled(newValue);
            setValue("tips_enabled", newValue);
          }}
          value={enabled}
        />
        <input
          id="tips_enabled"
          name="tips_enabled"
          value={enabled}
          ref={register()}
          type={"hidden"}
        />
        <span className="w-full text-lg pl-4 text-gray-900">
          <Trans>Enable tips</Trans>
        </span>
      </div>
      <div hidden={!enabled} className={"pl-4 space-y-3"}>
        <div>
          {!isAustralia && (
            <div className="flex items-center pt-4">
              <Toggle
                onChange={(value: boolean) => {
                  const newValue = !tipIncludesTax;
                  setTipIncludesTax(newValue);
                  setValue("include_tax", newValue);
                }}
                value={tipIncludesTax}
              />
              <input
                id="include_tax"
                name="include_tax"
                value={isAustralia || tipIncludesTax}
                ref={register()}
                type={"hidden"}
              />
              <span className="w-full text-sm fsont-medium pl-4 text-gray-900">
                <Trans>Include tax & fees</Trans>
              </span>
            </div>
          )}
        </div>

        <TipField
          name="quick_tip4"
          label={t`Maximum`}
          register={register}
          errors={errors.quick_tip4}
          defaultValue={settings?.tips?.quick_tip4 * 100}
        />
        <div className="flex w-full justify-around gap-4">
          <TipField
            name="quick_tip1"
            register={register}
            label={t`Min`}
            extra="w-1/3"
            errors={errors.quick_tip1}
            defaultValue={settings?.tips?.quick_tip1 * 100}
          />
          <TipField
            name="quick_tip2"
            register={register}
            label={t`Med`}
            extra="w-1/3"
            errors={errors.quick_tip2}
            defaultValue={settings?.tips?.quick_tip2 * 100}
          />
          <TipField
            name="quick_tip3"
            register={register}
            label={t`High`}
            extra="w-1/3"
            errors={errors.quick_tip3}
            defaultValue={settings?.tips?.quick_tip3 * 100}
          />
        </div>
      </div>
      {errors &&
        (errors.quick_tip1 ||
          errors.quick_tip2 ||
          errors.quick_tip3 ||
          errors.quick_tip4) && (
          <span className="text-red-700 text-xs p-1 pt-4">
            <Trans>Tips must be less than 30%</Trans>
          </span>
        )}
    </div>
  ) : null;
};

export default React.memo(TipSettingsComponent);
