import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Main from "./containers/Main";
import DevicesRegistered from "./DevicesRegistered";

const Devices: React.FC = () => {
  const [locations, setLocations] = useState<any[] | any>([]);

  useEffect(() => {
    API.get("Conduit", "/locations", {
      queryStringParameters: { limit: 1000 },
    }).then((locations) => {
      setLocations(
        locations.data.map((l: any) => ({ id: l.id, name: l.display_name }))
      );
    });
  }, []);

  return (
    <Main>
      <DevicesRegistered locations={locations} />
    </Main>
  );
};

export default Devices;
