import { t } from "@lingui/macro";

const WifiStrengthDescription = (device: any): string => {
  if (!device) {
    return "";
  }

  let rssi = device.wifi_rssi;
  const inRange = (min: number, max: number): boolean => {
    return rssi >= min && rssi <= max;
  };
  switch (true) {
    case device.status == "offline":
      return t`Offline`;

    case rssi == null || rssi == 0:
      return t`Online`;

    case inRange(-100, -80):
      return t`Poor`;

    case inRange(-79, -70):
      return t`Okay`;

    case inRange(-69, -60):
      return t`Good`;

    case inRange(-59, 0):
      return t`Excellent`;

    default:
      return t`Invalid`;
  }
};

export default WifiStrengthDescription;
