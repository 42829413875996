import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../services/auth-context";
import removeEmptyObjectProps from "../../util/removeEmptyObjectProps";
import LeftLabeledContainer from "../LeftLabeledContainer";
import Modal from "../Modal";
import ModalForm from "../ModalForm";
import NameAddressForm from "../NameAddressForm";
import Toggle from "../Toggle";
import { t, Trans } from "@lingui/macro";
import timezones from "../../json/timezones2.json";
import moment from "moment-timezone";

const TIMES = Array(24)
  .fill(0)
  .map((e, i) => (i + ":00").padStart(5, "0"));
const LocationModal: React.FC<{
  location?: any;
  onCancel: any;
  onSubmitSuccess: any;
  onClickOutside?: any;
  visible: boolean;
  removeBlankFormValues: boolean;
  merchantId?: string;
  devices: any[];
  preselectedCountry?: any;
}> = ({
  location,
  onCancel,
  onSubmitSuccess,
  onClickOutside,
  visible,
  removeBlankFormValues,
  merchantId,
  devices,
  preselectedCountry,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const { user, isReseller } = useAuth();
  const isAustralia =
    user?.accountData?.defaultAddress?.country === "AU" ||
    user?.accountData?.address?.country === "AU";

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");

  const [timezoneItems, setTimezoneItems] = useState<any[]>();
  useEffect(() => {
    const generatedOptions: any[] = [];

    let country = preselectedCountry ?? location?.address?.country ?? "";

    let filtered = timezones;
    if (country === "US") {
      filtered = filtered.filter((t) => t.group === "US (Common)");
    } else if (country === "AU") {
      filtered = filtered.filter((t) => t.group === "Australia");
    } else {
      if (country === "SG") {
        filtered = filtered.filter((t) => t.group === "Asia");
        filtered[0].zones = filtered[0].zones.filter(
          (z) => z.name === "Singapore"
        );
      }
    }
    filtered.forEach((item) => {
      generatedOptions.push({
        value: item.group,
        label: item.group,
        sub: false,
      });

      item.zones.forEach((zone) => {
        const localTime = moment.tz(zone.value).format("HH:mm");
        generatedOptions.push({
          value: zone.value,
          label: `${zone.name} (${localTime})`,
          sub: true,
        });
      });
    });

    setTimezoneItems(generatedOptions);
  }, [preselectedCountry]);

  const onFormSubmit = (formData: any) => {
    setIsSubmitting(true);
    const body = !removeBlankFormValues
      ? formData
      : removeEmptyObjectProps(formData);
    const isEdit = !!location;
    const base = "/locations";
    const endpoint = `${base}${isEdit ? `/${location.id}` : ""}`;
    const headers = merchantId ? { "copper-account": merchantId } : {};
    API.post("Conduit", endpoint, {
      headers,
      body,
    })
      .then((res) => {
        onSubmitSuccess();
      })
      .catch((err) => {
        setRequestError(err?.response?.data?.error?.message || err.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onModalCancel = () => {
    onCancel();
    setRequestError("");
    setIsSubmitting(false);
  };

  return (
    <Modal onClickOutside={onClickOutside} visible={visible}>
      <ModalForm
        title={`${!!location ? t`Edit` : t`New`} ${t`location`}`}
        isSubmitting={isSubmitting}
        onCancel={onModalCancel}
        handleSubmit={handleSubmit(onFormSubmit)}
        submitError={requestError}
      >
        {isAustralia ? (
          <div>
            <input
              ref={register}
              hidden={true}
              id="include_tax"
              name="settings[tips][include_tax]"
              value={"true"}
            />
          </div>
        ) : (
          <div></div>
        )}
        <NameAddressForm
          register={register}
          errors={errors}
          display_name={location?.display_name}
          address={location?.address}
          phone={location?.phone}
          usesDisplayName={true}
          preselectedCountry={preselectedCountry}
          showLivenUID={true}
          livenUID={location?.settings?.verified_liven_group_id}
          showTaxID={true}
          taxID={location?.settings?.tax_id}
          showStatus={true}
          status={location?.status ?? "active"}
          statusChangeWarning={t`This will also change the status for all location devices`}
          statusLockedMessage={
            location && location.can_activate === false
              ? t`Disabled at merchant level`
              : null
          }
        />

        <LeftLabeledContainer name={t`Timezone`}>
          <div>
            <select
              className={"copper-input"}
              defaultValue={location?.timezone ?? ""}
              ref={register()}
              id={"timezone"}
              name={"timezone"}
            >
              <option value={""}>Auto</option>
              {timezoneItems?.map((timezone, i) => {
                return (
                  <>
                    <option
                      value={timezone.value}
                      key={timezone.name}
                      disabled={!timezone.sub}
                    >
                      {timezone.sub
                        ? "\u00A0\u00A0\u00A0\u00A0" + timezone.label
                        : timezone.label}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
        </LeftLabeledContainer>
        <LeftLabeledContainer name={t`Cut-off time`}>
          <div>
            <select
              ref={register({ required: t`State required` })}
              id="cutoff_time"
              name="settings[cutoff_time]"
              className="copper-input float-left w-28"
              disabled={isReseller}
              defaultValue={
                location?.settings.cutoff_time
                  ? location?.settings.cutoff_time
                  : "05:00"
              }
            >
              <option value="" disabled>
                {t`Cut-off Time`}
              </option>
              {TIMES.map((time, i) => (
                <option value={time} key={i}>
                  {time}
                </option>
              ))}
            </select>
            <span className="has-tooltip float-left  ml-2">
              <span className="tooltip rounded shadow-lg z-[2147483647] bg-white p-2 border border-gray-100 text-base mt-6  ">
                <Trans>
                  The cut-off time is used to set when Cashier resets for the
                  next day. Please set time on the hour with using the 24 hour
                  clock.
                </Trans>
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ionicon w-5 text-gray-light"
                fill="currentColor"
                viewBox="0 0 512 512"
              >
                <path d="M256 56C145.72 56 56 145.72 56 256s89.72 200 200 200 200-89.72 200-200S366.28 56 256 56zm0 82a26 26 0 11-26 26 26 26 0 0126-26zm64 226H200v-32h44v-88h-32v-32h64v120h44z" />
              </svg>
            </span>
          </div>
        </LeftLabeledContainer>
      </ModalForm>
    </Modal>
  );
};

export default LocationModal;
