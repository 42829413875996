import { API } from "aws-amplify";
import { schemeTableau10 as schemeCategory } from "d3-scale-chromatic";
import React, { useCallback, useEffect, useState } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useAuth } from "../services/auth-context";
import Main from "./containers/Main";
import { getCurrency } from "../util/locale";
import { renderAmountRounded } from "../components/utils/util";
import { t, Trans } from "@lingui/macro";

export interface ItemLabels {
  [index: string]: {
    category: string;
    item: string;
  };
}

export function categorizeSales(labels: ItemLabels, topItems: any) {
  const numTopCategories = 8;

  let salesPerCategoryMap: { [index: string]: number } = {};

  for (const agg of topItems || []) {
    const category = labels[agg.label]?.category || t`Uncategorized`;
    salesPerCategoryMap[category] =
      (salesPerCategoryMap[category] || 0) + agg.sales;
  }
  const sortedSalesPerCategory: { name: string; value: number }[] =
    Object.entries(salesPerCategoryMap)
      .map(([name, value]) => ({
        name,
        value,
      }))
      .sort((a, b) => (a.value < b.value ? 1 : -1));
  const categorizedData = sortedSalesPerCategory.filter(
    (d) => d.name !== t`Uncategorized`
  );
  const categoryNames = Array.from(new Set(categorizedData.map((d) => d.name)));
  const topCategories = categorizedData.slice(0, numTopCategories);
  const otherCategoriesSum = categorizedData
    .slice(numTopCategories)
    .map((d) => d.value)
    .reduce((a, x) => a + x, 0);
  const otherCategory = { name: t`Other`, value: otherCategoriesSum };
  const uncategorizedCategory = sortedSalesPerCategory.find(
    (d) => d.name === t`Uncategorized`
  )!;
  const categorizedSales = topCategories
    .concat(otherCategory, uncategorizedCategory)
    .filter(Boolean)
    .filter((category) => category.value > 0);
  return { categoryNames, categorizedSales };
}

const Labels: React.FC = () => {
  const { user } = useAuth();
  const accountId = user!.account;
  const [topItems, setTopItems] = useState<any>([]);
  const [labels, setLabels] = useState<ItemLabels>({});

  const currency = getCurrency(user!.accountData.address.country);

  useEffect(() => {
    API.get("Conduit", `/accounts/${user!.account}`, {}).then(
      (account: any) => {
        setLabels(account.mcp_labels || {});
      }
    );

    API.get("Conduit", `/accounts/${user!.account}/stats`, {}).then(
      (stats: any) => {
        setTopItems(stats.top_items);
      }
    );
  }, [user]);

  const { categorizedSales, categoryNames } = categorizeSales(labels, topItems);

  const setLabel = useCallback(
    (
      label: string,
      { category, display_name }: { category?: string; display_name?: string }
    ) => {
      setLabels((labels: any) => {
        let newValue: any = {
          ...labels[label],
          category,
          display_name,
        };

        if (!newValue.category && !newValue.display_name) {
          newValue = "";
        }

        labels = {
          ...labels,
          [label]: newValue,
        };

        API.post("Conduit", `/accounts/${accountId}`, {
          body: {
            mcp_labels: {
              [label]: newValue,
            },
          },
        }).then((account: any) => {
          setLabels(account.mcp_labels);
        });

        return labels;
      });
    },
    [accountId]
  );

  if (!topItems || !labels) {
    return (
      <Main>
        <Trans>Loading</Trans>
      </Main>
    );
  }

  return (
    <Main>
      <div className="w-full h-72 text-base">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              isAnimationActive={false}
              data={categorizedSales}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={(value: any) => `${(value.percent * 100).toFixed()}%`}
              labelLine={true}
            >
              {categorizedSales.map((_, index) => (
                <Cell key={`cell-${index}`} fill={schemeCategory[index]} />
              ))}
            </Pie>
            <Tooltip
              formatter={(value: any, name: any) => [
                renderAmountRounded(value, currency),
                name,
              ]}
              separator=": "
            />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <datalist id="categories">
        {categoryNames.map((category) => (
          <option key={category}>{category}</option>
        ))}
      </datalist>

      <table className="table-auto divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="text-left font-normal text-gray-400 text-lg py-2">
              <Trans>Label on Ticket</Trans>
            </th>
            <th className="text-left font-normal text-gray-400 text-lg">
              <Trans>Item Category</Trans>
            </th>
            <th className="text-left font-normal text-gray-400 text-lg">
              <Trans>Total Sales</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {topItems.map((item: any) => (
            <tr key={item.label}>
              <td className="py-2">{item.label}</td>
              <td>
                <input
                  className="copper-input mt-1 mr-2 w-40"
                  list="categories"
                  onKeyPress={(event: any) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      event.stopPropagation();
                      setLabel(item.label, { category: event.target.value });
                    }
                  }}
                  onBlur={(event: any) =>
                    setLabel(item.label, { category: event.target.value })
                  }
                  onFocus={(event) => {
                    requestAnimationFrame(() => {
                      event.target.setSelectionRange(
                        0,
                        event.target.value.length
                      );
                    });
                  }}
                  defaultValue={labels[item.label]?.category}
                />
              </td>
              <td>{renderAmountRounded(item.sales, currency)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Main>
  );
};

export default Labels;
