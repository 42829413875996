import { API } from "aws-amplify";
import { useState } from "react";
import { useForm } from "react-hook-form";
import removeEmptyObjectProps from "../../util/removeEmptyObjectProps";
import Modal from "../Modal";
import ModalForm from "../ModalForm";
import NameAddressForm from "../NameAddressForm";
import { t } from "@lingui/macro";

const MerchantModal: React.FC<{
  merchant?: any;
  parentAccount?: any;
  onCancel: any;
  onSubmitSuccess: any;
  onClickOutside?: any;
  visible: boolean;
  removeBlankFormValues: boolean;
  sendType?: boolean;
}> = ({
  merchant,
  parentAccount,
  onCancel,
  onSubmitSuccess,
  onClickOutside,
  visible,
  removeBlankFormValues,
  sendType,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");

  const onFormSubmit = (formData: any) => {
    setIsSubmitting(true);
    const body = !removeBlankFormValues
      ? formData
      : removeEmptyObjectProps(formData);
    const isEdit = !!merchant;
    const base = "/accounts";
    const endpoint = `${base}${isEdit ? `/${merchant.id}` : ""}`;
    const accountType = sendType ? { type: "merchant" } : {};
    const parentAccountId = parentAccount
      ? { "copper-account": parentAccount.account ?? parentAccount.id }
      : {};
    API.post("Conduit", endpoint, {
      body: { ...body, ...accountType },
      headers: parentAccountId,
    })
      .then((merchant) => {
        onSubmitSuccess(merchant);
      })
      .catch((err) => {
        setRequestError(err.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onModalCancel = () => {
    onCancel();
    setRequestError("");
    setIsSubmitting(false);
  };

  return (
    <Modal onClickOutside={onClickOutside} visible={visible}>
      <ModalForm
        title={`${!!merchant ? t`Edit` : t`New`} ${t`merchant`}`}
        isSubmitting={isSubmitting}
        onCancel={onModalCancel}
        handleSubmit={handleSubmit(onFormSubmit)}
        submitError={requestError}
      >
        <NameAddressForm
          register={register}
          errors={errors}
          name={merchant?.name}
          email={merchant?.email}
          address={merchant?.address}
          usesDisplayName={false}
          showEmail={true}
          preselectedCountry={
            merchant?.address?.country ??
            parentAccount?.accountData?.address?.country ??
            parentAccount?.address?.country ??
            null
          }
          showStatus={true}
          status={merchant?.status ?? "active"}
          statusChangeWarning={t`This will also change the status for all locations and devices`}
          statusLockedMessage={
            merchant && merchant.can_activate === false
              ? t`Disabled at reseller level`
              : null
          }
        />
      </ModalForm>
    </Modal>
  );
};

export default MerchantModal;
