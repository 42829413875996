import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./services/auth-context";

const PrivateRoute = ({ component, ...rest }: any) => {
  const { isLoggedIn } = useAuth();

  return (
    <Route
      {...rest}
      render={(props: any) =>
        isLoggedIn
          ? React.createElement(component, props)
          : typeof isLoggedIn === "boolean" && (
              <Redirect to={{ pathname: "/login" }} />
            )
      }
    />
  );
};

export default PrivateRoute;
