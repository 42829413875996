import DataTable from "../DataTable";
import PlaceHolderDash from "../PlaceholderDash";
import Spinner from "../Spinner";
import { t } from "@lingui/macro";

const LocationsList: React.FC<{ locations: any; history: any }> = ({
  locations,
  history,
}) => {
  if (!locations) {
    return (
      <div className="flex justify-center p-responsive">
        <Spinner size={20} color="gray"></Spinner>
      </div>
    );
  }
  const mappedLocations = locations.map((location: any) => {
    const isSingapore = location.address.country.toLowerCase() == "sg";
    return {
      name: location.display_name,
      locationId: location.id,
      devices: <PlaceHolderDash />,
      location: isSingapore
        ? "Singapore"
        : `${location.address.city}, ${location.address.state}`,
      click: () => {
        history.push(`/locations/${location.id}`);
      },
    };
  });

  const columns = [
    {
      name: "name",
      label: "",
      type: "string",
    },

    {
      name: "devices",
      label: "",
      type: "string",
    },

    {
      name: "location",
      label: "",
      type: "string",
    },
  ];

  return (
    <DataTable
      data={mappedLocations}
      noItemsHeader={t`No locations registered`}
      noItemsBody={t`Locations must be registered before being visible here.`}
      tdClass="px-responsive py-responsive-xs"
      columns={columns}
      noHeaders={true}
      roundCorners={true}
    />
  );
};

export default LocationsList;
