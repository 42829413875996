import React, { useState, useRef, useEffect } from "react";

interface TabsProps {
  labels: string[];
  onTabSelect: (index: number) => void;
}

export const Tabs: React.FC<TabsProps> = ({ labels, onTabSelect }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [indicatorStyle, setIndicatorStyle] = useState<{
    left: string;
    width: string;
  }>({ left: "0%", width: "0%" });
  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    onTabSelect(index);
    const tabElement = tabsRef.current[index];
    if (tabElement) {
      setIndicatorStyle({
        left: `${tabElement.offsetLeft}px`,
        width: `${tabElement.offsetWidth}px`,
      });
    }
  };

  useEffect(() => {
    handleTabClick(0);
  }, []);

  return (
    <div className="w-full relative">
      <div
        className="absolute h-8 bg-gray-200 rounded-2xl bottom-0 transition-all duration-300 ease-in-out"
        style={{
          ...indicatorStyle,
          backgroundColor: "rgba(218,218,218,0.5)",
        }}
      ></div>
      <div className="flex">
        {labels.map((label, index) => (
          <button
            key={index}
            ref={(el) => (tabsRef.current[index] = el)}
            className="py-1 px-4 focus:outline-none"
            onClick={(event) => {
              event.preventDefault();
              handleTabClick(index);
            }}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};
