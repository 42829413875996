import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { ChecklistDropdown } from "../../ChecklistDropdown";
import { t, Trans } from "@lingui/macro";
import { getCurrentLocale } from "../../../util/i18n";
import {
  ALL,
  arrayToSelectOptions,
  getQueryParam,
  queryDateParam,
  safeCents,
  stringToValueList,
} from "../../utils/util";

registerLocale("es", es);

function pair(key: string, value: string) {
  return { key: key, value: value };
}

const transactionStatusOptions = [
  pair("all", t`All`),
  pair("succeeded", t`Succeeded`),
  pair("declined", t`Declined`),
  pair("failed", t`Failed`),
];

export const paymentTypeOptions = [
  pair("all", t`All`),
  pair("payment", t`Payment`),
  pair("refund", t`Refund`),
  pair("pre-auth", t`Pre-auth`),
];

export const autoRefreshOptions = [
  pair("off", t`Off`),
  pair("show", t`Show`),
  pair("highlight", t`Highlight`),
  pair("notify", t`Notify`),
];

export const paymentMethodOptions = [
  pair("card", t`Card`),
  pair("apple_pay", t`Apple Pay`),
  pair("google_pay", t`Google Pay`),
  pair("grabpay", t`GrabPay`),
  pair("emv", t`EMV`),
  pair("external", t`External`),
  pair("liven", t`Liven`),
  pair("paypal", t`PayPal`),
  pair("vip", t`VIP`),
];
export const resetFilterSettings = {
  refresh: "off",
  sort: "",
  sortDirection: "",
  ticket: "",
  status: ALL,
  type: ALL,
  method: [],
  minAmount: 0,
  maxAmount: 0,

  location: { id: "" },
  startDate: null,
  endDate: null,
  cardNumber: "",
};
export const getQueryFilterSettings = () => ({
  refresh: getQueryParam("refresh") ?? "off",
  sort: getQueryParam("sort") ?? "",
  sortDirection: getQueryParam("direction") ?? "",
  ticket: getQueryParam("ticket") ?? "",
  status: getQueryParam("status") ?? ALL,
  type: getQueryParam("type") ?? ALL,
  method: stringToValueList(getQueryParam("method")),
  minAmount: safeCents(getQueryParam("min_amount") ?? "") / 100 ?? 0,
  maxAmount: safeCents(getQueryParam("max_amount") ?? "") / 100 ?? 0,

  location: { id: getQueryParam("location") ?? "" },
  startDate: queryDateParam("captured_from"),
  endDate: queryDateParam("captured_to"),
  cardNumber: getQueryParam("card") ?? "",
});

const TransactionFilter: React.FC<any> = (props) => {
  const { locations, filterSettings, setFilterSettings } = props;

  const [debounceTimer, setDebounceTimer] = useState<any>();

  const [internalFilterSettings, setInternalFilterSettings] =
    useState(filterSettings);

  useEffect(() => {
    setInternalFilterSettings({
      ...internalFilterSettings,
      minAmount: internalFilterSettings.minAmount / 100,
      maxAmount: internalFilterSettings.maxAmount / 100,
    });
  }, []);

  const updateExternalFilter = (filterSettings: any) => {
    setFilterSettings({
      ...filterSettings,
      minAmount: safeCents(filterSettings.minAmount),
      maxAmount: safeCents(filterSettings.maxAmount),
    });
  };
  const updateFilter = (update: any, debounce: boolean = false) => {
    clearTimeout(debounceTimer);
    const updatedFilter = { ...internalFilterSettings, ...update };
    setInternalFilterSettings(updatedFilter);
    if (!debounce) {
      updateExternalFilter(updatedFilter);
      return;
    }
    setDebounceTimer(
      setTimeout(() => {
        updateExternalFilter(updatedFilter);
      }, 500)
    );
    return () => clearTimeout(debounceTimer);
  };

  if (!locations) {
    return <></>;
  }

  const filterInputContainer = (
    text: string,
    contents: any,
    extra: any = null
  ) => {
    return (
      <div className="text-responsive-xs">
        <div className={"pl-1 pb-1"}>{text}</div>
        <div className={"w-24 flex"}>
          {contents}
          {extra}
        </div>
      </div>
    );
  };

  const paymentMethodDropdown = filterInputContainer(
    t`Payment method`,
    <ChecklistDropdown
      items={paymentMethodOptions}
      selectedItems={internalFilterSettings.method}
      onSelection={(selection) => {
        updateFilter(
          {
            method: selection,
          },
          true
        );
      }}
      noSelectionText={t`All`}
    />
  );

  const dropdown = (
    text: string,
    defaultValue: any,
    filterProperty: string,
    options: any[]
  ) => {
    return filterInputContainer(
      text,
      <select
        className="bg-white copper-input-basic text-gray-500 rounded p-0.5 px-1.5 w-20 h-6"
        value={defaultValue}
        onChange={(e) => {
          let change: any = {};
          change[filterProperty] = e.target.value;
          updateFilter(change);
        }}
      >
        {arrayToSelectOptions(options)}
      </select>
    );
  };
  const clearButton = (
    <div
      className="text-responsive-xs font-light flex cursor-pointer text-copper-purple p-0.5  mr-1 my-4"
      onClick={() => {
        updateFilter(resetFilterSettings);
      }}
    >
      <Trans>Clear</Trans>
    </div>
  );

  const minAmountInput = filterInputContainer(
    t`From amount`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      maxLength={8}
      placeholder="0"
      value={internalFilterSettings.minAmount}
      onChange={(e) => {
        updateFilter(
          {
            minAmount: e.target.value,
          },
          true
        );
      }}
    />
  );

  const maxAmountInput = filterInputContainer(
    t`To amount`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      maxLength={8}
      placeholder="0"
      value={internalFilterSettings.maxAmount}
      onChange={(e) => {
        let value = safeCents(e.target.value);
        if (value == null) {
          value = 0;
        }
        updateFilter(
          {
            maxAmount: e.target.value,
          },
          true
        );
      }}
    />
  );

  const sortDirectionButton = (
    <div
      className={"cursor-pointer"}
      onClick={() => {
        updateFilter({
          sortDirection:
            filterSettings.sortDirection == "desc" ||
            filterSettings.sortDirection == ""
              ? "asc"
              : "desc",
        });
      }}
    >
      <svg
        transform={
          filterSettings.sortDirection == "asc" ? "scale(-1,-1)" : "scale(1,1)"
        }
        width={24}
        height={24}
        fill={"#959595"}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M7.03 13.92H11.03V5L13.04 4.97V13.92H17.03L12.03 18.92Z" />
      </svg>
    </div>
  );

  const sortDropdown = (
    <div className={"pr-2"}>
      {filterInputContainer(
        t`Sort`,
        <select
          className="bg-white copper-input-basic text-gray-500 rounded p-0.5 px-1.5 w-20 h-6"
          value={internalFilterSettings.sort}
          onChange={(e) => {
            updateFilter({
              sort: e.target.value,
            });
          }}
        >
          <option value="created">{t`Date`}</option>
          <option value="amount">{t`Amount`}</option>
        </select>,
        sortDirectionButton
      )}
    </div>
  );

  const locationDropdown = filterInputContainer(
    t`Location`,
    <select
      className="bg-white copper-input-basic text-gray-500 rounded p-0.5 px-1.5 w-20 h-6"
      value={internalFilterSettings.location.id}
      onChange={(e) => {
        updateFilter({
          location: {
            ...internalFilterSettings.location,
            id: e.target.value,
          },
        });
      }}
    >
      <option value="">{t`All`}</option>
      {locations.map((location: any) => (
        <option key={location.id} value={location.id}>
          {location.name}
        </option>
      ))}
    </select>
  );

  const startDateInput = filterInputContainer(
    t`From date`,
    <DatePicker
      selected={internalFilterSettings.startDate}
      onChange={(date: any) => {
        updateFilter({
          startDate: date,
        });
      }}
      customInput={
        <input
          readOnly={true}
          className="copper-input-basic rounded text-gray-500 text-center p-2px pb-1px w-20 h-6"
        />
      }
      locale={getCurrentLocale()}
    />
  );

  const endDateInput = filterInputContainer(
    t`To date`,
    <DatePicker
      selected={internalFilterSettings.endDate}
      onChange={(date: any) =>
        updateFilter({
          endDate: date,
        })
      }
      customInput={
        <input
          readOnly={true}
          className="copper-input-basic rounded text-gray-500 text-center p-2px pb-1px w-20 h-6"
        />
      }
      locale={getCurrentLocale()}
    />
  );
  const ticketInput = filterInputContainer(
    t`Ticket`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      value={internalFilterSettings.ticket}
      onChange={(e) => {
        updateFilter(
          {
            ticket: e.target.value,
          },
          true
        );
      }}
    />
  );

  const cardInput = filterInputContainer(
    t`Card last 4`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      value={internalFilterSettings.cardNumber}
      onChange={(e) => {
        updateFilter(
          {
            cardNumber: e.target.value,
          },
          true
        );
      }}
    />
  );

  const paymentStatusDropdown = dropdown(
    t`Status`,
    internalFilterSettings.status,
    "status",
    transactionStatusOptions
  );
  const paymentTypeDropdown = dropdown(
    t`Payment type`,
    internalFilterSettings.type,
    "type",
    paymentTypeOptions
  );

  const autoRefreshDropdown =
    // = dropdown(
    // t`Show new`,
    // internalFilterSettings.refresh,
    // "refresh",
    // autoRefreshOptions
    // );/**/

    filterInputContainer(
      t`Show new`,
      <select
        className="bg-white copper-input-basic text-gray-500 rounded p-0.5 px-1.5 w-20 h-6"
        value={internalFilterSettings.refresh}
        onChange={(e) => {
          let change: any = {};
          let newValue = e.target.value;
          change["refresh"] = newValue;
          if (filterSettings.refresh === "off" && newValue !== "off") {
            change["sort"] = resetFilterSettings.sort;
            change["startDate"] = resetFilterSettings.startDate;
            change["endDate"] = resetFilterSettings.endDate;
            change["cardNumber"] = resetFilterSettings.cardNumber;
            change["ticket"] = resetFilterSettings.ticket;
          }
          updateFilter(change);
        }}
      >
        {arrayToSelectOptions(autoRefreshOptions)}
      </select>
    );

  const autoRefreshDisabled = filterSettings.refresh === "off";
  return (
    <div className="flex flex-row">
      {autoRefreshDropdown}
      {autoRefreshDisabled && sortDropdown}

      {locationDropdown}
      {paymentTypeDropdown}
      {paymentMethodDropdown}
      {paymentStatusDropdown}

      {autoRefreshDisabled && startDateInput}
      {autoRefreshDisabled && endDateInput}

      {minAmountInput}
      {maxAmountInput}

      {autoRefreshDisabled && cardInput}
      {autoRefreshDisabled && ticketInput}
      {clearButton}
    </div>
  );
};

export default TransactionFilter;
