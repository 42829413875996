import React from "react";

const Spinner: React.FC<{ size: number; color: string }> = ({
  size,
  color,
}) => {
  return (
    <svg
      className="self-stretch"
      viewBox="0 0 100 100"
      width={size}
      height={size}
      style={{ willChange: "transform" }}
    >
      <path
        fill="none"
        stroke={color}
        strokeWidth="6"
        d="M97 50C97 24.043 75.957 3 50 3S3 24.043 3 50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0 50 50"
          to="360 50 50"
          dur="1s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default Spinner;
