const removeEmptyObjectProps: any = (obj: any) =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (value === "") {
      return acc;
    }

    const valueIsObject = typeof value === "object";
    return {
      ...acc,
      [key]: valueIsObject ? removeEmptyObjectProps(value) : value,
    };
  }, {});

export default removeEmptyObjectProps;
