import { API } from "aws-amplify";
import React, { useState } from "react";
import StripeAccountCreatedModal from "./StripeAccountCreatedModal";
import { t, Trans } from "@lingui/macro";
import Toggle from "../../../Toggle";

const StripeConnectedAccountComponent: React.FC<{
  isDisabled?: boolean;
  isConnected?: boolean;
  isReseller: boolean;
  register: any;
  updateLocation: any;
  merchantId: any;
  setIsSubmitting: any;
  setRequestError: any;
  setValue: any;
  openStripeAccountLinkPopup: any;
  location?: any;
}> = ({
  location,
  isConnected,
  isReseller,
  register,
  updateLocation,
  merchantId,
  setIsSubmitting,
  setRequestError,
  setValue,
  openStripeAccountLinkPopup,
  isDisabled = false,
}) => {
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [isGrabpayEnabled, setIsGrabpayEnabled] = useState(
    (location.settings?.stripe_payments?.payment_method_types ?? "")
      .split(",")
      .includes("grabpay") ?? false
  );

  const createAccount = () => {
    setIsSubmitting(true);
    const endpoint = `/stripe/accounts`;
    API.post("Conduit", endpoint, {
      headers: { "copper-account": merchantId },
      body: {
        location: location.id,
        country: location.address.country,
      },
    })
      .then(() => {
        updateLocation();
        setIsAccountCreated(true);
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const stripeAccountCreatedModal = (
    <StripeAccountCreatedModal
      visible={isAccountCreated}
      openStripeAccountLinkPopup={() => {
        openStripeAccountLinkPopup();
        setIsAccountCreated(false);
      }}
    />
  );

  return (
    <div>
      <div>
        <label
          htmlFor="connected_account_id"
          className="block text-base py-1 text-gray-700"
        >
          <Trans>Merchant Connected Account ID</Trans>
        </label>
        {isConnected && (
          <input
            className="copper-input-disabled"
            value={location.settings?.stripe_payments?.connected_account_id}
            readOnly
          />
        )}
        {!isConnected && (
          <input
            ref={register()}
            id="connected-account-id"
            name="connected_account_id"
            disabled={isConnected || isDisabled}
            autoComplete="off"
            className="copper-input p-1"
            placeholder={!isDisabled ? t`Enter an existing account ID` : ""}
            defaultValue={
              location.settings?.stripe_payments?.connected_account_id ?? ""
            }
          />
        )}
        {isDisabled && (
          <div className="text-responsive-sm text-center">
            {isReseller
              ? t`Reseller Platform API Key needs to be connected first`
              : t`Contact your Reseller to set up their Stripe payment settings first`}
          </div>
        )}
      </div>
      {!isDisabled &&
        (isConnected ? (
          <div className="text-base w-full flex">
            <div
              className="cursor-pointer text-copper-purple mr-1 ml-1 my-auto"
              onClick={openStripeAccountLinkPopup}
            >
              <Trans>Complete or edit account information on Stripe</Trans>
            </div>
          </div>
        ) : (
          <div className="text-base w-full flex">
            <Trans>Or</Trans>
            <div
              className="cursor-pointer text-copper-purple mr-1 ml-1 my-auto"
              onClick={createAccount}
            >
              <Trans>create an account to connect</Trans>
            </div>
          </div>
        ))}
      {stripeAccountCreatedModal}
      <div className="my-4">
        <label className="block text-base py-1 text-gray-700">
          <Trans>Additional payment methods</Trans>
        </label>
        <div className="mt-2px">
          <div className="flex items-center">
            <Toggle
              // isDisabled={isDisabled || isConnected}
              onChange={(value: boolean) => {
                const newValue = !isGrabpayEnabled;
                setIsGrabpayEnabled(newValue);
                setValue("is_grabpay_enabled", newValue);
                console.log(newValue);
              }}
              value={isGrabpayEnabled}
            />
            <input
              id="is-grabpay-enabled"
              name="is_grabpay_enabled"
              value={isGrabpayEnabled}
              ref={register()}
              type={"hidden"}
            />
            <span className="text-responsive-sm pt-0.5 pr-2 ml-1">GrabPay</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StripeConnectedAccountComponent);
