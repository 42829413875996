import { API } from "aws-amplify";

export const usersAccount = {
  getUsers: (accountId: any, headers?: any) =>
    API.get("Conduit", `/accounts/${accountId}/users?limit=1000`, { headers }),

  deleteUser: (accountId: any, userId: any) =>
    API.del("Conduit", `/accounts/${accountId}/users/${userId}`, {
      headers: { "copper-account": accountId },
    }),

  reInviteUser: (accountId: any, userId: any, isReseller?: boolean) =>
    API.post("Conduit", `/accounts/${accountId}/users/${userId}/reinvite`, {
      headers: { "copper-account": accountId },
    }),
  newUser: (accountId: any, body: any) =>
    API.post("Conduit", `/accounts/${accountId}/users`, {
      headers: { "copper-account": accountId },
      body,
    }),
};
