import React, { useState } from "react";
export const copyIcon = (
  <svg
    fill="#000000"
    height="20px"
    width="20px"
    version="1.1"
    id="Capa_1"
    viewBox="0 0 330 330"
  >
    <g>
      <path
        fill={"#868686"}
        d="M35,270h45v45c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V75c0-8.284-6.716-15-15-15h-45V15
c0-8.284-6.716-15-15-15H35c-8.284,0-15,6.716-15,15v240C20,263.284,26.716,270,35,270z M280,300H110V90h170V300z M50,30h170v30H95
c-8.284,0-15,6.716-15,15v165H50V30z"
      />
      <path
        fill={"#868686"}
        d="M155,120c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15s-6.716-15-15-15H155z"
      />
      <path
        fill={"#868686"}
        d="M235,180h-80c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15S243.284,180,235,180z"
      />
      <path
        fill={"#868686"}
        d="M235,240h-80c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h80c8.284,0,15-6.716,15-15C250,246.716,243.284,240,235,240z
"
      />
    </g>
  </svg>
);
export const checkIcon = (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#000000" />
  </svg>
);
export const openUrlIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="20px"
    height="20px"
  >
    <path
      fill={"#868686"}
      d="M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z"
    />
  </svg>
);
export const QRUrlImage: React.FC<{
  image?: any;
  url: any;
}> = ({ image, url }) => {
  const [copyButtonIcon, setCopyButtonIcon] = useState(copyIcon);

  return (
    <div className="flex items-center relative">
      <img className="w-full px-10" src={image} />
      <span
        className="absolute right-0 top-10 mr-4 cursor-pointer rounded-xl hover:bg-gray-100 p-3"
        onClick={async () => {
          setCopyButtonIcon(checkIcon);
          await navigator.clipboard.writeText(url);
          setTimeout(() => {
            setCopyButtonIcon(copyIcon);
          }, 2000);
        }}
      >
        {copyButtonIcon}
      </span>
      <span
        className="absolute right-0 top-24 mr-4 cursor-pointer rounded-xl hover:bg-gray-100 p-3"
        onClick={() => {
          window.open(url, "_blank");
        }}
      >
        {openUrlIcon}
      </span>
    </div>
  );
};
