import React, { useState } from "react";
import Toggle from "../../Toggle";
import { t, Trans } from "@lingui/macro";

const NonCashComponent: React.FC<{
  isDisabled?: boolean;
  register: any;
  location?: any;
  setValue: any;
  isHide?: boolean;
  isAustralia: boolean;
}> = ({
  location,
  setValue,
  register,
  isHide,
  isAustralia,
  isDisabled = false,
}) => {
  const { rate, enabled, before_tax } = location.settings.adjustments;

  const [toggleEnabled, setToggleEnabled] = useState(enabled);
  const [beforeTax, setBeforeTax] = useState(before_tax);

  return isHide !== true ? (
    <div>
      <label className="text-lg">
        <Trans>NCA settings</Trans>
      </label>
      <div className="flex h-10 items-center pt-4">
        <Toggle
          onChange={() => {
            const newValue = !toggleEnabled;
            setToggleEnabled(newValue);
            setValue("adjustment_enabled", newValue);
          }}
          value={toggleEnabled}
          isDisabled={isDisabled}
        />
        <span className="w-full text-sm fsont-medium pl-4 text-gray-900">
          <Trans>Enable Non-Cash Adjustment</Trans>
          {toggleEnabled && t` of:`}
        </span>
        <input
          id="adjustment-enabled"
          name="adjustment_enabled"
          value={toggleEnabled}
          ref={register()}
          type={"hidden"}
        />
        <div
          className={
            !toggleEnabled ? "hidden" : "flex items-center align-middle"
          }
        >
          <input
            id="adjustment-rate"
            name="adjustment_rate"
            ref={register()}
            className="copper-input w-16 mr-2"
            defaultValue={(rate * 100).toFixed(1)}
          />
          %
        </div>
      </div>
      <div
        className={
          !toggleEnabled || isAustralia ? "hidden" : "flex items-center"
        }
      >
        <input
          type="radio"
          id="adjustment-before-tax-true"
          name="adjustment_before_tax"
          value="true"
          onChange={() => setBeforeTax(true)}
          ref={register()}
          checked={beforeTax}
        />{" "}
        <span className="text-sm fsont-medium pl-4 text-gray-900">
          <Trans>Calculate NCA before tax and fees</Trans>
        </span>
      </div>
      <div
        className={
          !toggleEnabled || isAustralia ? "hidden" : "flex items-center"
        }
      >
        <input
          type="radio"
          id="adjustment-before-tax-false"
          name="adjustment_before_tax"
          value="false"
          onChange={() => setBeforeTax(false)}
          ref={register()}
          checked={isAustralia || !beforeTax}
        />{" "}
        <span className="text-sm fsont-medium pl-4 text-gray-900">
          <Trans>Calculate NCA after tip is added</Trans>
        </span>
      </div>
    </div>
  ) : null;
};

export default React.memo(NonCashComponent);
