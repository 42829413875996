import React from "react";
import { t } from "@lingui/macro";

function getTranslatedValue(value: string) {
  let trans_value = "";
  switch (value) {
    case "succeeded":
      trans_value = t`succeeded`;
      break;
    case "pre-authorized":
      trans_value = t`pre-authorized`;
      break;
    case "refunded":
      trans_value = t`refunded`;
      break;
    case "partial refund":
      trans_value = t`partial refund`;
      break;
    case "voided":
      trans_value = t`voided`;
      break;
    case "partial void":
      trans_value = t`partial void`;
      break;
    case "declined":
      trans_value = t`declined`;
      break;
    case "failed":
      trans_value = t`failed`;
      break;
    case "paid":
      trans_value = t`paid`;
      break;
    case "unpaid":
      trans_value = t`unpaid`;
      break;
    case "pending":
      trans_value = t`pending`;
      break;
    case "in_transit":
      trans_value = t`in_transit`;
      break;
    case "canceled":
      trans_value = t`canceled`;
      break;
    case "payment":
      trans_value = t`payment`;
      break;
    case "payment refund":
      trans_value = t`payment refund`;
      break;
    case "adjustment":
      trans_value = t`adjustment`;
      break;
  }

  return trans_value;
}

const Badge: React.FC<any> = ({ color, children, css }) => {
  const baseClass =
    "capitalize px-2 inline-flex text-xs leading-5 font-semibold rounded-md";
  const _color = `bg-${color}-100 text-${color}-800`;
  return <span className={`${baseClass} ${_color} ${css}`}>{children}</span>;
};

export const LogicBadge: React.FC<any> = ({ definition, value, full, css }) => {
  const baseClass = `capitalize justify-center ${
    full ? "w-full" : ""
  } px-3 py-0.5 inline-flex text-12px leading-5 font-normal rounded-md`;
  const color = definition[value]?.color || "black";
  const backgroundColor = definition[value]?.background || definition[value];

  const style = {
    backgroundColor: backgroundColor,
    color: color,
  };

  let _color = `bg-${backgroundColor}-100 text-${color}-800`;
  return (
    <span
      className={`${baseClass} ${_color} ${css} font-medium`}
      style={definition[value]?.color && style}
    >
      {getTranslatedValue(value)}
    </span>
  );
};

export default Badge;
