import { i18n } from "@lingui/core";
import { en, es } from "make-plural/plurals";
import { messages as messagesEn } from "../locales/en/messages";
import { messages as messagesEs } from "../locales/es/messages";
import { getBrowserLocales } from "./locale";

const supportedLanguages = ["en", "es"];
const defaultLanguage = "en";

export function getCurrentLocale() {
  const locales = getBrowserLocales({ languageCodeOnly: true });
  const browserLocale = (locales && locales[0]) ?? defaultLanguage;
  return supportedLanguages.includes(browserLocale)
    ? browserLocale
    : defaultLanguage;
}

export function loadLocale() {
  i18n.load({
    en: messagesEn,
    es: messagesEs,
  });

  i18n.loadLocaleData({
    en: { plurals: en },
    es: { plurals: es },
  });

  const locale = getCurrentLocale();
  i18n.activate(locale);
}
