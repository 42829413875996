import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import useClickOutside from "../../utils/useClickOutside";
import InBetweenSettings from "./dateRanges/InBetweenSettings";

import { Trans } from "@lingui/macro";

export const dateRangeOption: any = {
  inTheLast: "In the last",
  isEqualTo: "Is equal to",
  inBetween: "In between",
  isAfter: "Is after",
  isOnOrAfter: "Is on or after",
  isBefore: "Is before",
  isBeforeOrOn: "Is before or on",
};

export const defaultFilterSettings = {
  location: {
    enabled: false,
    id: "",
  },
  dateRange: {
    enabled: false,
    type: dateRangeOption.inBetween,
    startDate: new Date(),
    endDate: new Date(),
    amount: 6,
    period: "days",
  },
  cardNumber: {
    enabled: false,
    number: "",
  },
  onlyPreAuth: {
    enabled: false,
  },
};

const PayputFilter: React.FC<any> = (props) => {
  const {
    isShowingFilters,
    setIsShowingFilters,
    locations,
    filterSettings,
    setFilterSettings,
    setFiltering,
  } = props;

  const modalRef = useClickOutside(() => {
    if (!isShowingFilters) {
      return;
    }
    setTimeout(() => setIsShowingFilters(false), 150);
  });

  if (!isShowingFilters || !locations) {
    return <></>;
  }

  const header = (
    <div className="rounded-t flex justify-between border-b bg-gray-50  p-1.5">
      <div className="text-responsive-base font-medium my-auto">
        <Trans>Filters</Trans>
      </div>
      <div className="text-responsive-xs font-light flex">
        <div
          className="cursor-pointer text-copper-purple p-0.5  mr-1 my-auto"
          onClick={() => {
            setFiltering(true);
            setFilterSettings(defaultFilterSettings);
          }}
        >
          <Trans>Clear</Trans>
        </div>
        <div
          className="cursor-pointer px-2 py-0.5 rounded text-white bg-copper-purple my-auto hover:bg-copper-purple-hover"
          onClick={() => setFiltering(true)}
        >
          <Trans>Apply</Trans>
        </div>
      </div>
    </div>
  );

  const dateRangeCheckbox = (
    <div className="flex p-2">
      <input
        id="enable-filter-date-range"
        type="checkbox"
        className="my-auto mr-1"
        checked={filterSettings.dateRange.enabled}
        onChange={() =>
          setFilterSettings({
            ...filterSettings,
            dateRange: {
              ...filterSettings.dateRange,
              enabled: !filterSettings.dateRange.enabled,
            },
          })
        }
      />
      <div className="text-responsive-xs font-medium mt-0.5 opacity-70">
        <label htmlFor="enable-filter-date-range">
          <Trans>Date Range</Trans>
        </label>
      </div>
    </div>
  );

  return (
    <div
      ref={modalRef}
      className="shadow-lg absolute flex flex-col bg-white right-9 mt-1 top-36 w-56 border rounded-md z-50"
    >
      {header}
      {dateRangeCheckbox}
      <DateRangeSection {...props} />
    </div>
  );
};

export const DateRangeSection: React.FC<any> = (props) => {
  return (
    <div className="bg-gray-50 text-responsive-xs border-t border-b px-2 py-2.5">
      <div className="flex justify-center mt-2">
        <DateRangeTypeSettings {...props} />
      </div>
    </div>
  );
};

const DateRangeTypeSettings: React.FC<any> = (props) => {
  return <InBetweenSettings {...props} />;
};

export default PayputFilter;
