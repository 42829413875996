const isSupported = () =>
  "Notification" in window &&
  "serviceWorker" in navigator &&
  "PushManager" in window;
export const notificationsSupported = typeof Notification !== "undefined";
export const notificationsAllowed =
  notificationsSupported && Notification.permission === "granted";

export const requestNotificationPermissionIfNeeded = () => {
  if (!isSupported()) {
    return;
  }
  if (notificationsSupported && notificationsAllowed) {
    return;
  }
  navigator.serviceWorker.register("");
  Notification.requestPermission().then(function (permission) {
    console.log(permission);
  });
};

export const showNotification = (
  title: string,
  body: string,
  audioFile?: string
) => {
  if (!isSupported()) {
    return;
  }
  // try {
  //   if (audioFile) {
  //     let sound: HTMLAudioElement;
  //     sound = new Audio();
  //     sound.src = audioFile;
  //     sound.load();
  //     sound.play();
  //   }
  // } catch (e) {
  //   console.log(e);
  // }
  try {
    const notification = new Notification(title, { body });
    notification.onclick = () => {
      notification.close();
      window.parent.focus();
    };
  } catch (e) {
    Notification.requestPermission().then((result) => {
      if (result === "granted") {
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification(title, { body });
        });
      }
    });
  }
};
