import { API } from "aws-amplify";
import React, { useState } from "react";
import Button from "../../../Button";
import Spinner from "../../../Spinner";
import { t, Trans } from "@lingui/macro";
import BuildBluefinOptions from "./BluefinSettings";
import QRCode from "qrcode";
import mergeImages from "merge-images";
import { isLocalDevEnv } from "../../../utils/util";
import Toggle from "../../../Toggle";
import ConsumerQRComponent from "../ConsumerQRComponent";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../DeviceModal";
import { Tabs } from "../../../Tabs";
import removeEmptyObjectProps from "../../../../util/removeEmptyObjectProps";
import MerchantQRComponent from "../MerchantQRComponent";
const labels = ["Consumer", "Merchant"];

const QrPaymentsSettingsComponent: React.FC<{
  location: any;
  updateLocation: any;
  merchantId: any;
  register: any;
  handleSubmit: any;
  reset: any;
  isSubmitting: any;
  setIsSubmitting: any;
  requestError: any;
  setRequestError: any;
}> = ({ location, updateLocation, merchantId, register, setIsSubmitting }) => {
  const [gateway, setGateway] = useState(
    location?.settings?.qr_payments?.gateway
  );
  const [error, setError] = useState(
    !gateway
      ? "No payment gateway selected. Please go to Payment Settings and configure Copper Payments"
      : null
  );
  const saveSettings = (body: any) => {
    setError("");

    const endpoint = `/locations/${location.id}`;

    API.post("Conduit", endpoint, {
      headers: { "copper-account": merchantId },
      body: body,
    })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        console.log(err);
        setError(
          err.response?.data?.error?.message ??
            "An unknown error has occurred. Please try again or contact Copper support"
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const [tab, setTab] = useState(0);
  return (
    <div className="h-full">
      <div className="flex flex-col justify-between h-full">
        <div className="space-y-3">
          <Tabs
            labels={labels}
            onTabSelect={(index) => {
              setTab(index);
            }}
          ></Tabs>
          {tab == 0 && (
            <div className="pt-4">
              <ConsumerQRComponent
                saveSettings={saveSettings}
                location={location}
              ></ConsumerQRComponent>
            </div>
          )}
          {tab == 1 && (
            <div className="pt-4">
              <MerchantQRComponent
                location={location}
                setError={setError}
              ></MerchantQRComponent>
            </div>
          )}
        </div>
        <div className="flex justify-between">
          {!!error ? (
            <span className="label-error my-auto">{error}</span>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(QrPaymentsSettingsComponent);
