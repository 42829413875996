import React, { useEffect, useState } from "react";
import Toggle from "../../Toggle";
import { Trans } from "@lingui/macro";
import QRCode from "qrcode";
import { isLocalDevEnv } from "../../utils/util";
import Button from "../../Button";
import { API } from "aws-amplify";
import Spinner from "../../Spinner";
import { QRUrlImage } from "./QRUrlImage";

const randomPin = () => {
  return (Math.floor(Math.random() * 9000) + 1000).toString();
};
const MerchantQRComponent: React.FC<{
  location?: any;
  setError: any;
}> = ({ location, setError }) => {
  const [gateway, setGateway] = useState(
    location?.settings?.qr_payments?.gateway
  );

  const [busy, setBusy] = useState(true);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [enabled, setEnabled] = useState(gateway && apiKey != null);
  const [showRenewWarning, setShowRenewWarning] = useState(false);
  const [pin, setPin] = useState<string>(randomPin());
  const [pinChanged, setPinChanged] = useState<boolean>(false);
  const paymentURL =
    `https://${isLocalDevEnv ? "staging." : ""}cop.pe/r/` +
    location.id +
    "?sk=" +
    apiKey;

  const [image, setImage] = useState("");
  const updateError = (err: any) => {
    if (!err) {
      setError("");
    }
    setError(
      err.response?.data?.error?.message ??
        "An unknown error has occurred. Please try again or contact Copper support"
    );
  };
  const clearError = () => {
    setError("");
  };

  useEffect(() => {
    generateQRCode();
  }, [paymentURL]);

  const createApiKey = () => {
    let newPin = randomPin();
    setPin(newPin);
    setBusy(true);
    clearError();
    API.post("Conduit", "/apikey", {
      body: {
        location: location.id,
        scope: 1,
        pin: newPin,
      },
    })

      .then((response) => {
        setBusy(false);
        setEnabled(!!response.key);
        if (apiKey) {
          setShowRenewWarning(true);
        }
        setApiKey(response.key);
        setPin(response.pin);
        setPinChanged(false);
      })
      .catch((err) => {
        console.log(err);
        updateError(err);
      });
  };
  const getApiKey = () => {
    setBusy(true);
    clearError();

    API.get("Conduit", "/apikey", {
      queryStringParameters: { location: location.id, scope: 1 },
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
      },
    })

      .then((key) => {
        setBusy(false);
        setEnabled(!!key.key);
        setApiKey(key.key);
        if (!key.pin) {
          let newPin = randomPin();
          setPin(newPin);
          updateKey(key.key, newPin);
        } else {
          setPin(key.pin);
        }
        return key;
      })
      .catch((err) => {
        //updateError(err);
        setEnabled(false);
        setApiKey(null);
        setBusy(false);
        console.log(err);
      });
  };

  const updateKey = (key: string, pin: string) => {
    if (!pin || !key) {
      return;
    }
    setBusy(true);
    clearError();
    API.post("Conduit", "/apikey/" + key, {
      body: {
        pin: pin,
        location: location.id,
      },
    })
      .then((response) => {
        setBusy(false);
        setEnabled(!!response.key);
        setApiKey(response.key);
        setPin(response.pin);
        setPinChanged(false);
      })
      .catch((err) => {
        console.log(err);
        updateError(err);
      });
  };
  const revokeApiKey = () => {
    setBusy(true);
    clearError();
    API.post("Conduit", "/apikey/revoke", {
      body: {
        location: location.id,
        scope: 1,
      },
    })
      .then((response) => {
        setBusy(false);
        setEnabled(!!response.key);
        setApiKey(response.key);
        setPin("");
      })
      .catch((err) => {
        console.log(err);
        updateError(err);
      });
  };
  const showMerchantPrintableTutorial = () => {
    window.open(location.id + "/qr");
  };

  const openMerchantCheckout = () => {
    window.open(paymentURL, "_blank");
  };

  const generateQRCode = async () => {
    try {
      const url = await QRCode.toDataURL(paymentURL);
      setImage(url);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getApiKey();
  }, []);

  return (
    <div className="space-y-3">
      <div>
        <div className="flex items-center h-8">
          <Toggle
            isDisabled={busy || !gateway}
            onChange={(value: boolean) => {
              setEnabled(value);
              if (!value) {
                revokeApiKey();
              } else {
                createApiKey();
              }
            }}
            value={enabled}
          />
          <span className="w-full text-sm pl-4 text-gray-900">
            <Trans>
              Enable merchant entering amount and viewing transactions
            </Trans>
          </span>
        </div>
      </div>

      {busy ? (
        <div className={"w-full h-52 flex"}>
          <span className={"m-auto w-10"}>
            <Spinner size={30} color={"#ccc"}></Spinner>
          </span>
        </div>
      ) : (
        enabled && (
          <div>
            {apiKey && (
              <div>
                <div className={"flex items-center h-10 space-x-2"}>
                  <label
                    htmlFor="manager_pin"
                    className="block text-base py-1 text-gray-700"
                  >
                    <Trans>Manager refund PIN</Trans>
                  </label>
                  <input
                    onChange={(e: any) => {
                      setPin(e.target.value);
                      setPinChanged(true);
                    }}
                    id="manager_pin"
                    name="manager_pin"
                    autoComplete="off"
                    className={"copper-input w-40"}
                    value={pin}
                    placeholder="PIN Code"
                  />
                  <Button
                    disabled={!pinChanged || pin.length < 4}
                    onClick={() => updateKey(apiKey, pin)}
                    className={"border-none"}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
                {image && (
                  <div>
                    <QRUrlImage url={paymentURL} image={image}></QRUrlImage>
                  </div>
                )}
              </div>
            )}
            <div className={"flex"}>
              <div className={"pt-2"} hidden={!enabled || busy}>
                <Button
                  icon="ion-printer"
                  onClick={showMerchantPrintableTutorial}
                  className={"border-none"}
                >
                  <Trans>Merchant instructions</Trans>
                </Button>
                <Button
                  icon="ion-refresh"
                  onClick={createApiKey}
                  className={"border-none"}
                >
                  <Trans>Renew access key</Trans>
                </Button>
              </div>
            </div>
            {enabled && showRenewWarning && (
              <span className={"text-sm text-red-500"}>
                Your access key has been renewed, previously printed QR codes
                will not work. Please print the QR code again
              </span>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default React.memo(MerchantQRComponent);
