import { FunctionComponent } from "react";
import MainHeader from "../../components/MainHeader/";
import Sidebar from "../../components/Sidebar";

const FixedMain: FunctionComponent = ({ children }) => {
  return (
    <div className="h-screen flex overflow-hidden bg-gray-background">
      <Sidebar />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <MainHeader />
        <div className="pl-6 font-sans h-screen bg-white ">{children}</div>
      </div>
    </div>
  );
};

export default FixedMain;
