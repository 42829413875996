import { API } from "aws-amplify";
import React, { FunctionComponent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../logo.svg";
import { useAuth } from "../services/auth-context";
import { t } from "@lingui/macro";

interface MenuItemType {
  exact?: boolean;
  to: string;
  name: string;
  isHide?: boolean | undefined | false;
}

const Sidebar: FunctionComponent = () => {
  const [isStripeEnabled, setIsStripeEnabled] = useState(false);
  const { isReseller, isAdmin, role, user } = useAuth();

  const MenuItem = (props: MenuItemType) =>
    !props.isHide ? (
      <NavLink
        exact={props.exact}
        to={props.to}
        className="text-gray-light text-base hover:bg-sgray-100 hover:text-gray-900 group flex items-center px-4 py-3 pl-6 font-medium"
        activeClassName="bg-white text-gray-active "
      >
        {props.name}
      </NavLink>
    ) : null;

  const isMerchantAccount = user?.account?.startsWith("mch") ?? false;
  const enablePlus = isMerchantAccount && role === "owner";

  useEffect(() => {
    API.get("Conduit", "/locations", {
      queryStringParameters: { limit: 100 },
    }).then((locations) => {
      if (locations.data) {
        setIsStripeEnabled(
          locations.data.some(
            (l: any) => l.settings.qr_payments.gateway === "stripe"
          )
        );
      }
    });
  }, []);

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1 border-gray-light border-r border-opacity-40	 bg-gray-background">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="h-6 w-32 m-auto mt-0.5"
                src={logo}
                alt="Workflow"
              />
            </div>
            <nav className="mt-11 flex-1 px-2 bg-gray-50 space-y-1">
              <MenuItem to={"/"} name={t`Home`} exact />
              <MenuItem to={"/plus"} name={t`Plus`} isHide={!enablePlus} />
              <MenuItem to={"/labels"} name={t`Labels`} isHide={!enablePlus} />

              <MenuItem
                to={"/tickets"}
                name={t`Tickets`}
                isHide={isReseller || isAdmin}
              />

              <MenuItem
                to={"/transactions"}
                name={t`Transactions`}
                isHide={isReseller || isAdmin}
              />

              <MenuItem
                to={"/payouts"}
                name={t`Payouts`}
                isHide={
                  isReseller ||
                  isAdmin ||
                  role === "employee" ||
                  !isStripeEnabled
                }
              />
              <MenuItem
                to={"/merchants"}
                name={t`Merchants`}
                isHide={!isReseller || isAdmin}
              />
              <MenuItem
                to={"/resellers"}
                name={t`Resellers`}
                isHide={!isAdmin}
              />
              <MenuItem
                to={"/devices"}
                name={t`Devices`}
                isHide={
                  !(
                    isMerchantAccount &&
                    (role === "owner" || role === "employee")
                  )
                }
              />
              <MenuItem
                to={"/locations"}
                name={t`Locations`}
                isHide={isReseller || isAdmin || role === "employee"}
              />
              <MenuItem
                to={"/settings"}
                name={t`Settings`}
                isHide={role !== "owner"}
              />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
