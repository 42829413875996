import React from "react";
import Modal from "../../../Modal";
import { Trans } from "@lingui/macro";

const StripeAccountCreatedModal: React.FC<{
  visible: boolean;
  openStripeAccountLinkPopup: any;
}> = ({ visible, openStripeAccountLinkPopup }) => {
  return (
    <Modal onClickOutside={() => {}} visible={visible}>
      <div style={{ height: "10rem" }}>
        <div className="space-y-6">
          <div className="false pb-5 -mb-6">
            <div className="text-responsive-lg font-medium">
              <Trans>Important</Trans>
            </div>
          </div>
          <div className="space-y-6 bg-form -mx-4 p-4">
            <div className="flex flex-col space-y-6">
              <span>
                <Trans>Please finish account creation on Stripe.</Trans>
              </span>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="w-full flex justify-start"></div>
            <button
              type="button"
              onClick={openStripeAccountLinkPopup}
              className="copper-button-confirm false ml-2 flex justify-center h-10"
            >
              <Trans>Continue</Trans>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(StripeAccountCreatedModal);
