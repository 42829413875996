import React, { FunctionComponent, useEffect } from "react";
import { useAuth } from "../../services/auth-context";
import Main from "../containers/Main";
import { Trans } from "@lingui/macro";

const LogOut: FunctionComponent = () => {
  const { signOut } = useAuth();
  useEffect(() => signOut(), [signOut]);

  return (
    <Main>
      <div>
        <Trans>Logouts...</Trans>
      </div>
    </Main>
  );
};

export default LogOut;
