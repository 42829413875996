import { API } from "aws-amplify";
import React from "react";
import Button from "../../../Button";
import Spinner from "../../../Spinner";
import StripeConnectedAccountComponent from "./StripeConnectedAccountComponent";
import StripeResellerComponent from "./StripeResellerComponent";
import { t, Trans } from "@lingui/macro";

const { REACT_APP_API_ENDPOINT } = process.env;

const BuildStripeOptions: React.FC<{
  location: any;
  updateLocation: any;
  merchantId: any;
  register: any;
  handleSubmit: any;
  reset: any;
  isSubmitting: any;
  setIsSubmitting: any;
  requestError: any;
  setRequestError: any;
  isReseller: boolean;
  setValue: any;
}> = ({
  location,
  updateLocation,
  merchantId,
  register,
  handleSubmit,
  reset,
  isSubmitting,
  setIsSubmitting,
  requestError,
  setRequestError,
  isReseller,
  setValue,
}) => {
  const porcentageToNumeric = (number: any) =>
    number && (Number.parseFloat(number) / 100).toFixed(4);

  const currencyToCents = (number: any) =>
    number && (Number.parseFloat(number) * 100).toFixed(2);

  const isConnected = !(
    location.settings?.stripe_payments?.connected_account_id === null ||
    (isReseller && location.settings?.stripe_payments?.api_secret_key === null)
  );

  const popupUrl = `${REACT_APP_API_ENDPOINT}/stripe/accountlink?location=${location.id}`;
  const openStripeAccountLinkPopup = () => {
    const popup = window.open(
      `${popupUrl}&type=${
        isConnected ? "account_update" : "account_onboarding"
      }`,
      window.name
    );
    window.addEventListener("message", (event) => {
      console.log("event", event);
      updateLocation();
      if (
        REACT_APP_API_ENDPOINT?.includes(event?.origin) &&
        event?.data?.location === location.id
      ) {
        popup?.close();
      }
    });
  };

  const SetLocation = (data: any) => {
    setIsSubmitting(true);
    const endpoint = `/locations/${location.id}`;

    let postBody = {
      headers: { "copper-account": merchantId },
      body: {
        settings: {
          stripe_payments: data,
        },
      },
    } as any;
    return API.post("Conduit", endpoint, postBody);
  };

  const disconnectFromStripe = () => {
    SetLocation({
      connected_account_id: "",
      api_secret_key: isReseller
        ? ""
        : location.settings?.stripe_payments?.api_secret_key,
      publishable_key: isReseller
        ? ""
        : location.settings?.stripe_payments?.publishable_key,
    })
      .then(() => {
        updateLocation();
        reset({
          connected_account_id: "",
          api_secret_key: isReseller
            ? ""
            : location.settings?.stripe_payments?.api_secret_key,
          publishable_key: isReseller
            ? ""
            : location.settings?.stripe_payments?.publishable_key,
        });
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const connectToStripe = (formData: any) => {
    const {
      connected_account_id,
      api_secret_key,
      publishable_key,
      is_grabpay_enabled,
    } = formData;

    let paymentMethodTypes: string[] = [];
    if (is_grabpay_enabled === "true") {
      paymentMethodTypes.push("grabpay");
    }

    SetLocation({
      connected_account_id,
      api_secret_key,
      publishable_key,
      payment_method_types: paymentMethodTypes.toString(),
    })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const saveFeeSettings = (formData: any) => {
    const { fee_percent_rate, fee_fixed_rate } = formData;
    SetLocation({
      fee_percent_rate: porcentageToNumeric(fee_percent_rate),
      fee_fixed_rate: currencyToCents(fee_fixed_rate),
    })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        console.log(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const disconnectButton = (
    <Button onClick={disconnectFromStripe}>
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-5">
            <Spinner size={20} color="black"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>
          <Trans>Disconnect</Trans>
        </span>
      </>
    </Button>
  );
  const connectButton = (
    <button
      type={"submit"}
      className={`copper-button-confirm ${false && "text-transparent"}`}
    >
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-3">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>
          <Trans>Connect</Trans>
        </span>
      </>
    </button>
  );
  const saveButton = (
    <button
      disabled={!isConnected}
      type={"submit"}
      className={`copper-button-confirm${
        !isConnected ? "-disabled" : ""
      } ml-1 ${false && "text-transparent"}`}
    >
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>
          <Trans>Save</Trans>
        </span>
      </>
    </button>
  );
  const saveFeeButton = (
    <button
      type={"submit"}
      className={`copper-button-confirm ${false && "text-transparent"}`}
    >
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>
          <Trans>Save fees</Trans>
        </span>
      </>
    </button>
  );

  return (
    <div className="flex flex-col justify-between h-full">
      <form onSubmit={handleSubmit(connectToStripe)}>
        <div className="flex flex-col justify-between">
          <div className="space-y-3">
            <div className="text-base text-center w-full">
              <Trans>Collect your information</Trans>
              <br />
              <Trans>from</Trans>{" "}
              <a
                className="text-copper-purple"
                href="https://dashboard.stripe.com/apikeys"
              >
                <Trans>Stripe.</Trans>
              </a>
            </div>
            {isReseller && (
              <StripeResellerComponent
                isConnected={isConnected}
                isReseller={isReseller}
                location={location}
                register={register}
                setValue={setValue}
              />
            )}
            <StripeConnectedAccountComponent
              isDisabled={Boolean(
                !location.settings?.stripe_payments?.api_secret_key
              )}
              isConnected={isConnected}
              isReseller={isReseller}
              register={register}
              merchantId={merchantId}
              updateLocation={updateLocation}
              setRequestError={setRequestError}
              setIsSubmitting={setIsSubmitting}
              setValue={setValue}
              openStripeAccountLinkPopup={openStripeAccountLinkPopup}
              location={location}
            />
            <div className="flex justify-end">
              {!!requestError ? (
                <span className="label-error my-auto">{requestError}</span>
              ) : (
                <div />
              )}
              {isConnected ? disconnectButton : connectButton}
              {saveButton}
            </div>
          </div>
        </div>
      </form>
      {isReseller && (
        <form onSubmit={handleSubmit(saveFeeSettings)}>
          <div className="flex flex-col justify-between">
            <div className="space-y-3 flex justify-between items-center">
              <div className="flex h-10 items-center pt-4">
                <span className="block text-base py-1 text-gray-700 mr-1">
                  <Trans>Pay-at-the-table fee</Trans>
                </span>
                <div className="flex items-center align-middle p-2px">
                  <input
                    id="fee-percent-rate"
                    name="fee_percent_rate"
                    ref={register()}
                    className="copper-input w-12 p-1 mr-1"
                    defaultValue={(
                      location.settings?.stripe_payments?.fee_percent_rate * 100
                    ).toFixed(2)}
                  />
                  %
                </div>
                <span className="text-sm fsont-medium p-12px text-gray-900">
                  +
                </span>
                <div className="flex items-center align-middle p-2px">
                  <span className="text-sm fsont-medium text-gray-900 mr-1">
                    $
                  </span>
                  <input
                    id="fee-fixed-rate"
                    name="fee_fixed_rate"
                    ref={register()}
                    className="copper-input w-12 p-1"
                    defaultValue={(
                      location.settings?.stripe_payments?.fee_fixed_rate / 100
                    ).toFixed(2)}
                  />
                </div>
              </div>
              <div className="flex justify-end">{saveFeeButton}</div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export const StripeTab = {
  name: "Stripe",
  resellersOnly: false,
  navContent: (active: boolean) => (
    <>
      <img
        className={!active ? "image-grayscale" : ""}
        width="112"
        alt="stripe-logo"
        src={`/img/payment_options/stripe-active.svg`}
      />
    </>
  ),
  headerText: t`Connect with Stripe`,
  build: BuildStripeOptions,
};

export default React.memo(BuildStripeOptions);
