import React from "react";
import { useAuth } from "../../services/auth-context";
import UserButton from "./UserButton";
import { t, Trans } from "@lingui/macro";
import SearchBox from "./SearchBox";

const Header: React.FC = () => {
  const { resetUser, isOverrideUser, isReseller, user, isAdmin } = useAuth();

  const bg = isOverrideUser ? "bg-yellow-200" : "bg-white";

  return (
    user && (
      <div
        className={`${bg} flex items-center border-gray-light border-b border-opacity-40 z-10 shadow`}
      >
        <div>
          <SearchBox></SearchBox>
        </div>

        {isOverrideUser && (
          <>
            <div className="pl-8 text-base">
              <Trans>Viewing as</Trans> {isReseller ? t`Reseller` : t`Merchant`}
            </div>
            <div
              className="px-8 text-base underline cursor-pointer"
              onClick={() => resetUser()}
            >
              {`Exit ${isReseller ? `Reseller` : `Merchant`} view`}
            </div>
          </>
        )}
        <UserButton name={user.attributes.name} email={user.attributes.email} />
      </div>
    )
  );
};

export default Header;
