import React from "react";
import getIcon, { methodType } from "./img";

interface paymentProps {
  type: methodType;
  last4: string;
}

const PaymentField: React.FC<paymentProps> = (props) => (
  <div>
    <img
      className="inline-block h-6 w-6 -mt-1"
      alt="icon-payment"
      src={getIcon(props.type)}
    />
    {props.last4 && (
      <>
        <span className={"pl-1"}>••••</span>
        <span>{props.last4}</span>
      </>
    )}
  </div>
);

export default PaymentField;
