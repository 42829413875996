export const getTransactionStatus = (transaction: any) => {
  if (!transaction) return;

  let status = transaction.status;

  if (status === "succeeded" && transaction.refunded_amount > 0) {
    status = transaction.refunded ? "refunded" : "partial refund";
  }
  if (transaction.declined) {
    status = "declined";
  }
  if (transaction.captured === null) {
    if (status === "succeeded") {
      status = "pre-authorized";
    } else if (status === "refunded") {
      status = "voided";
    } else if (status === "partial refund") {
      status = "partial void";
    }
  }

  return status;
};
