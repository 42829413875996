import { API } from "aws-amplify";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DataTable from "../components/DataTable";
import MainHeader from "../components/MainHeader";
import Spinner from "../components/Spinner";
import objectToQueryString from "../util/objectToQueryString";
import CenteredErrorLabel from "../components/CenteredErrorLabel";
import FixedMain from "./containers/FixedMain";
import TicketFilter, {
  getTicketQueryFilterSettings,
  resetFilterSettings,
} from "../components/filters/tickets/TicketFilter";
import { ALL } from "../components/utils/util";
import { t, Trans } from "@lingui/macro";
import { getCurrentLocale } from "../util/i18n";
import Ticket from "./Ticket";

const dateFormat = "YYYY-MM-DD";
const pageItemCount = 20;
const loadMoreCount = 20;
const currentYear = moment().year();
moment.locale(getCurrentLocale());

const Tickets: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState("");

  const [tickets, setTickets] = useState<any[] | any>(null);
  const [locations, setLocations] = useState<any[] | any>([]);
  const [filterLocationId, setFilterLocationId] = useState("");
  const [filterSettings, setFilterSettings] = useState(
    getTicketQueryFilterSettings
  );

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    API.get("Conduit", "/locations", {
      queryStringParameters: { limit: 100 },
    }).then((locations) => {
      if (locations.data) {
        setLocations(
          locations.data.map((location: any) => ({
            id: location.id,
            name: location.display_name,
          }))
        );
      }
    });
  }, []);
  const loadTickets = (
    limit: number,
    offset: number,
    consumer: (data: any) => void
  ) => {
    setLoading(true);
    setError("");
    let parameters = filterSettingsToRequestParameters(filterSettings);
    window.history.replaceState("", "", `?${objectToQueryString(parameters)}`);
    API.get("Conduit", "/tickets2", {
      queryStringParameters: { ...parameters, offset: offset, limit: limit },
    })
      .then(({ data: recordsList }) => {
        setHasMore(recordsList.length == limit);
        recordsList.forEach((t: any) => {
          if (!t.created) {
            t.created = t.printed ?? new Date().toISOString();
          }
        });
        consumer(recordsList);
      })
      .catch((err) => {
        setError(err.response?.data?.error?.message || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadTickets(pageItemCount, 0, (data) => {
      setTickets(data);
    });
  }, [filterSettings]);

  const getTickets = () => {
    return tickets.map((ticket: any) => ({
      url: `/tickets/${ticket.id}`,
      reference: <div className="capitalize w-24">{ticket.reference}</div>,
      amount: (
        <div className="flex flex-row -mr-4">
          <span className="w-14 text-right">
            {"$" + (ticket.total / 100).toFixed(2)}
          </span>
          <span className="uppercase text-center text-gray-400 w-14 pr-2">
            {ticket.currency}
          </span>
          <div className="w-24">
            {ticket.paid && (
              <span
                className={
                  "capitalize justify-center  px-3 py-0.5 inline-flex text-12px leading-5 font-normal rounded-md bg-green-100 text-black-800 font-medium"
                }
              >
                <Trans>Paid</Trans>
              </span>
            )}
          </div>
        </div>
      ),
      location: (
        <div className="w-1/2">
          {locations.length > 0 &&
            locations.find(
              (location: { id: any; name: string }) =>
                location.id === ticket.location
            )?.name}
        </div>
      ),
      label: <span className="capitalize">{ticket.name}</span>,
      server: <span className="capitalize">{ticket.server}</span>,

      date: ticket.created
        ? ticket.created.includes(currentYear)
          ? moment(ticket.created).format("MMM DD, h:mm A")
          : moment(ticket.created).format("YYYY, MMM DD, h:mm A")
        : "Invalid date",
      transactions: (
        <div className="capitalize text-center w-full">
          {ticket.transaction_count > 0 ? ticket.transaction_count : "-"}
        </div>
      ),
    }));
  };

  const componentState = {
    locations,
    filterLocationId,
    setFilterLocationId,
    filterSettings,
    setFilterSettings,
  };
  const bottomScrollRef = useRef<HTMLDivElement | null>(null);

  return (
    <FixedMain>
      <div>
        <div className="p-4 flex justify-between">
          <MainHeader>
            <Trans>Tickets</Trans>
          </MainHeader>
        </div>
        <div className={"w-full h-12"}>
          <TicketFilter {...componentState}></TicketFilter>
        </div>
        <div
          className={"overflow-auto"}
          style={{ height: "calc(100vh - 200px)" }}
        >
          {!!error && <CenteredErrorLabel message={error} />}
          {!!tickets ? (
            <DataTable
              data={getTickets()}
              noItemsHeader={t`No tickets yet`}
              noItemsBody={t`When a ticket is printed, it will show here.`}
              headerClasses="pl-4"
              tdClass="px-4 py-2"
              noBorderTopOnHeaders={true}
              disableFullWidthCells={true}
              disableFlexBetween={true}
              footer={
                hasMore && (
                  <div
                    className={"w-full flex items-center justify-center p-4"}
                  >
                    <div
                      ref={bottomScrollRef}
                      className="grid  place-items-center w-36 h-8 cursor-pointer p-4 py-0.5 text-base rounded text-white bg-copper-purple my-auto hover:bg-copper-purple-hover"
                      onClick={() => {
                        if (loading) {
                          return;
                        }

                        loadTickets(
                          loadMoreCount,
                          tickets?.length ?? 0,
                          (data) => {
                            setTickets(tickets.concat(data));
                          }
                        );
                      }}
                    >
                      <div>
                        {loading && !error ? (
                          <Spinner size={20} color="white"></Spinner>
                        ) : (
                          t`Load more...`
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
              columns={[
                {
                  name: "reference",
                  label: "#",
                  type: "string",
                  headerClass: "uppercase w-12",
                  tdClass: "",
                },
                {
                  name: "amount",
                  label: t`Amount`,
                  type: "currency",
                  headerClass: "uppercase w-14",
                  tdClass: "",
                },
                {
                  name: "location",
                  label: t`Location`,
                  type: "string",
                  headerClass: "uppercase",
                  tdClass: "w-1/2",
                },
                {
                  name: "label",
                  label: t`Label`,
                  type: "string",
                  headerClass: "uppercase",
                  tdClass: "w-1/2",
                },
                {
                  name: "server",
                  label: t`Server`,
                  type: "string",
                  headerClass: "uppercase w-44",
                },
                {
                  name: "date",
                  label: t`Date`,
                  type: "string",
                  headerClass: "uppercase w-44",
                },
                {
                  name: "transactions",
                  label: t`Transactions`,
                  type: "string",
                  headerClass: "uppercase w-44",
                },
              ]}
            />
          ) : (
            !error && (
              <div className="flex justify-center p-responsive">
                <Spinner size={20} color="gray"></Spinner>
              </div>
            )
          )}
        </div>
      </div>
    </FixedMain>
  );
};

const filterSettingsToRequestParameters = (filterSettings: any) => {
  let requestParameters = {};
  if (filterSettings.sort) {
    requestParameters = {
      ...requestParameters,
      sort: filterSettings.sort ?? resetFilterSettings.sort,
    };
  }
  if (filterSettings.sortDirection) {
    requestParameters = {
      ...requestParameters,
      direction: filterSettings.sortDirection,
    };
  }
  if (filterSettings.location.id) {
    requestParameters = {
      ...requestParameters,
      location: filterSettings.location.id,
    };
  }

  if (filterSettings.startDate) {
    requestParameters = {
      ...requestParameters,
      from_date: moment(filterSettings.startDate).format(dateFormat),
    };
  }
  if (filterSettings.endDate) {
    requestParameters = {
      ...requestParameters,
      to_date: moment(filterSettings.endDate).format(dateFormat),
    };
  }

  if (filterSettings.minAmount) {
    requestParameters = {
      ...requestParameters,
      min_amount: filterSettings.minAmount,
    };
  }
  if (filterSettings.maxAmount) {
    requestParameters = {
      ...requestParameters,
      max_amount: filterSettings.maxAmount,
    };
  }

  if (filterSettings.reference) {
    requestParameters = {
      ...requestParameters,
      reference: filterSettings.reference,
    };
  }

  if (filterSettings.server) {
    requestParameters = {
      ...requestParameters,
      server: filterSettings.server,
    };
  }

  if (filterSettings.label) {
    requestParameters = {
      ...requestParameters,
      label: filterSettings.label,
    };
  }

  if (filterSettings.txns != ALL) {
    requestParameters = {
      ...requestParameters,
      txns: filterSettings.txns,
    };
  }
  if (filterSettings.paid != ALL) {
    requestParameters = {
      ...requestParameters,
      paid: filterSettings.paid,
    };
  }
  return requestParameters;
};

export default Tickets;
