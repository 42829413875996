import React, { FunctionComponent, useEffect, useState } from "react";
import { t } from "@lingui/macro";
import { API } from "aws-amplify";
import useClickOutside from "../utils/useClickOutside";
import Spinner from "../Spinner";
import { useAuth } from "../../services/auth-context";

const minSearchLength = 3;
const historySize = 5;
type SearchResult = {
  resultType: string;
  id: string;
  name?: string;
  parent?: string;
  gparent?: string;
};
const getRecent = (account: string) => {
  let key = "recent_searches_" + account;
  try {
    return (JSON.parse(localStorage[key]) as SearchResult[]) ?? [];
  } catch (e) {
    localStorage[key] = JSON.stringify([]);
    return [];
  }
};
const SearchBox: FunctionComponent = () => {
  const { isReseller, user, isAdmin } = useAuth();
  const [recent, setRecent] = useState<SearchResult[]>(
    user ? getRecent(user?.account) : []
  );
  const addRecent = (item: SearchResult) => {
    let r = recent;
    const existing = r.indexOf(item);
    if (existing > -1) {
      r.splice(existing, 1);
    } else if (r.length >= historySize) {
      r.splice(r.length - 1, 1);
    }
    r.unshift(item);
    localStorage.recent = JSON.stringify(r);
    setRecent(r);
  };
  const clearRecent = () => {
    localStorage.recent = JSON.stringify([]);
    setRecent([]);
  };

  const [expanded, setExpanded] = useState(false);
  const clickOutsideRef = useClickOutside(() => {
    if (!expanded) {
      return;
    }
    setTimeout(() => setExpanded(false), 150);
  });
  const [debounceTimer, setDebounceTimer] = useState<any>();

  const [keyword, setKeyword] = useState("");
  const validKeyword = keyword.length >= minSearchLength;
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState<SearchResult[]>([]);
  useEffect(() => {
    if (keyword && validKeyword) {
      setSearching(true);
      API.get("Conduit", "/global/search", {
        queryStringParameters: { keyword: keyword },
      })
        .then((result) => {
          setSearching(false);
          const items = result && result.data;
          setExpanded(true);
          setResults(items ?? []);
        })
        .catch((e) => {
          setSearching(false);
          console.log(e);
        });
    } else {
      setResults([]);
    }
  }, [keyword]);

  const doSearch = (keyword: string) => {
    clearTimeout(debounceTimer);
    setDebounceTimer(
      setTimeout(() => {
        setKeyword(keyword);
      }, 500)
    );
    return () => clearTimeout(debounceTimer);
  };
  const getUrlParent = (item: any) => {
    if ((isReseller || isAdmin) && item.resultType === "location") {
      return `merchants/${item.parent}/locations`;
    }
    switch (item.resultType) {
      case "cashier":
      case "cord":
      case "location":
        return "locations";
      case "merchant":
        return "merchants";
      case "reseller":
        return "resellers";
      default:
        return "invalid";
    }
  };
  const getSectionHeader = (type: string) => {
    switch (type) {
      case "cashier":
        return "Cashier";
      case "cord":
        return "Cords";
      case "location":
        return "Locations";
      case "merchant":
        return "Merchants";
      case "reseller":
        return "Resellers";
      default:
        return "Other";
    }
  };
  const section = (
    filtered: any[],
    headerTitle?: string,
    isRecentSection: boolean = false
  ) => {
    if (!filtered || filtered.length == 0) {
      return null;
    }

    const generatePath = (item: any) => {
      if (item.resultType === "cashier" || item.resultType === "cord") {
        if (!item.parent) return `/devices?selected=${item.id}`;
        return `/merchants/${item.gparent}/locations/${item.parent}?selected=${item.id}`;
      }
      return `/${getUrlParent(item)}/${item.id}`;
    };
    const header = headerTitle ?? getSectionHeader(filtered[0].resultType);
    return (
      <>
        <li key={header} className={"flex text-sm font-bold p-1"}>
          {header}
          {isRecentSection && (
            <span
              onClick={clearRecent}
              className={
                "text-sm font-normal ml-auto  flex-row justify-end text-blue-400 pl-24 cursor-pointer"
              }
            >
              Clear
            </span>
          )}
        </li>
        {filtered.map((item: SearchResult) => (
          <a
            href={generatePath(item)}
            className={"cursor-pointer"}
            onMouseDown={() => {
              addRecent(item);
            }}
          >
            <li
              key={item.id}
              className={
                "p-2 pl-3 flex flex-row text-sm cursor-pointer hover:bg-white hover:shadow-sm rounded-md hover:border-black"
              }
            >
              {/*{appendType && `${item.resultType}: `}*/}
              {item.name ?? item.id}
            </li>
          </a>
        ))}
      </>
    );
  };

  const sections = [
    results.filter((r) => r.resultType === "cashier"),
    results.filter((r) => r.resultType === "cord"),
    results.filter((r) => r.resultType === "location"),
    results.filter((r) => r.resultType === "merchant"),
    results.filter((r) => r.resultType === "reseller"),
  ].sort((a, b) => b.length - a.length);
  return (
    <div className="pl-4 flex-1 flex">
      <div ref={clickOutsideRef}>
        {expanded && (
          <div
            className={
              "pt-10 w-52 h-auto p-2 absolute bg-gray-100 shadow-xl mt-1 -ml-1 rounded-md border border-slate-800"
            }
          >
            {keyword.length < minSearchLength && recent.length > 0 && (
              <ul>{section(recent, "Recent", true)}</ul>
            )}

            {recent.length == 0 && results.length == 0 ? (
              <ul>
                <li className={"text-sm pl-2 pr-16"} key={"e"}>
                  {validKeyword && !searching
                    ? "No results"
                    : "Search everywhere"}
                </li>
              </ul>
            ) : (
              <ul>{sections.map((s) => section(s))}</ul>
            )}
          </div>
        )}
      </div>
      <div className="relative p-2 w-30 text-gray-400 focus-within:text-gray-600">
        <div
          className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-1"
          aria-hidden="true"
        >
          {searching ? (
            <div className={"pl-1 pt-1"}>
              <Spinner size={20} color={"#ccc"}></Spinner>
            </div>
          ) : (
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
        <input
          onFocus={() => {
            setExpanded(true);
          }}
          onClick={() => {
            setExpanded(true);
          }}
          autoComplete={"off"}
          onChange={(e) => {
            doSearch(e.target.value);
          }}
          className="block bg-transparent w-48 h-full pl-8 pr-2 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
          placeholder={t`Search`}
          type="search"
        />
      </div>
    </div>
  );
};

export default SearchBox;
