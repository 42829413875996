import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import services from "./providers";
import reportWebVitals from "./reportWebVitals";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { loadLocale } from "./util/i18n";

loadLocale();

services();
ReactDOM.render(
  <I18nProvider i18n={i18n}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </I18nProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
