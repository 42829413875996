import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Button";
import DeviceIcon from "../DeviceIcon";
import Modal from "../Modal";
import ModalForm from "../ModalForm";
import Spinner from "../Spinner";
import ConfirmModal from "./ConfirmModal";
import WiFiStrengthDescription from "../WiFiStrengthDescription";
import Toggle from "../Toggle";
import ConnectivityGraph from "../ConnectivityGraph";
import { RadioGroup } from "@headlessui/react";
import { t, Trans } from "@lingui/macro";
import { useAuth } from "../../services/auth-context";

const timeRanges = [
  { name: "24h", value: 24 },
  { name: "12h", value: 12 },
  { name: "6h", value: 6 },
  { name: "3h", value: 3 },
  { name: "1h", value: 1 },
];

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const DeviceModal: React.FC<{
  device: any;
  onCancel: any;
  onSubmitSuccess: any;
  onClickOutside?: any;
  visible: boolean;
  onRemove: any;
  onEdited: any;
  merchantId?: string;
  location?: any;
}> = ({
  device,
  onCancel,
  onSubmitSuccess,
  onRemove,
  onEdited,
  onClickOutside,
  visible,
  merchantId,
  location,
}) => {
  const { isOwner, isAdmin, isReseller } = useAuth();

  const showLoggingOptions =
    (isAdmin || isReseller || isOwner) && device?.device_type === "copper_Cord";

  const [selectedLogLevel, setSelectedLogLevel] = useState(
    device?.log_level ?? 0
  );
  if (device && device.log_level != selectedLogLevel) {
    setSelectedLogLevel(device.log_level);
  }
  const [logsBusy, setLogsBusy] = useState(false);
  const { register, handleSubmit } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");
  const [inEditMode, setInEditMode] = useState(false);
  const [isShowingRemoveConfirm, setIsShowingRemoveConfirm] = useState(false);
  const [isShowingRebootConfirm, setIsShowingRebootConfirm] = useState(false);
  const [isSubmittingConfirmation, setIsSubmittingConfirmation] =
    useState(false);

  const [selectedTimeRange, setSelectedTimeRange] = useState(timeRanges[0]);
  const [deviceEnabled, setDeviceEnabled] = useState(false);

  const [deviceEx, setDeviceEx] = useState<any>(null);

  const shouldReloadDevice = () => {
    if (!visible || device == null) {
      return false;
    }
    if (deviceEx == null) {
      return true;
    }
    return device.id != deviceEx.id;
  };
  const reloadDevice = () => {
    API.get(
      "Conduit",
      `/devices/${device.id}?include[0]=metrics&include[1]=last_reboot`,
      {
        headers: { "copper-account": merchantId },
      }
    ).then((reloaded) => {
      setDeviceEx(reloaded);
    });
  };

  useEffect(() => {
    if (shouldReloadDevice()) {
      reloadDevice();
    }
  });

  if (deviceEnabled != device?.enabled) {
    setDeviceEnabled(device?.enabled);
  }

  const deviceLabel = device?.label || device?.id.split("_")[1];
  const avoidCancelWhenRemoveConfirming = () => {
    if (isShowingRemoveConfirm || isShowingRebootConfirm) {
      return;
    }
    onClickOutside();
  };

  const onFormSubmit = ({ label }: any) => {
    if (device.label === label) {
      setInEditMode(false);
      return;
    }
    setIsSubmitting(true);
    const deviceId = device.id;
    API.post("Conduit", `/devices/${deviceId}`, {
      headers: merchantId ? { "copper-account": merchantId } : {},
      body: {
        label: label.trim(),
      },
    })
      .then((updatedDevice) => {
        console.log("/devices/", "then");
        device.label = updatedDevice.label;
        onSubmitSuccess();
      })
      .catch((err) => {
        console.log("/devices/", "res", err);
        setRequestError(err.message);
      })
      .finally(() => {
        console.log("/devices/", "finally");
        setIsSubmitting(false);
        setInEditMode(false);
      });
  };

  const onDeviceEnabled = (enabled: boolean) => {
    const deviceId = device.id;
    API.post("Conduit", `/devices/${deviceId}`, {
      headers: merchantId ? { "copper-account": merchantId } : {},
      body: {
        enabled: enabled,
      },
    })
      .then((updatedDevice) => {
        device.enabled = updatedDevice.enabled;
        setDeviceEnabled(updatedDevice.enabled);
        onEdited();
      })
      .catch((err) => {
        console.log("/devices/", "res", err);
        setRequestError(err.message);
      });
  };

  const onModalCancel = () => {
    onCancel();
    setRequestError("");
    setIsSubmitting(false);
  };

  const onRemoveClicked = () => {
    setIsShowingRemoveConfirm(true);
  };

  const onCancelClicked = () => {
    setInEditMode(false);
    onModalCancel();
  };

  const onConfirmRemove = (e: any) => {
    e.preventDefault();
    setIsSubmittingConfirmation(true);
    API.del("Conduit", `/devices/${device.id}`, {
      headers: merchantId ? { "copper-account": merchantId } : {},
    })
      .then((res) => {
        if (onRemove) {
          onRemove();
          onCancel();
        }
      })
      .catch((err) => {
        setRequestError(err.response.data.error.message);
      })
      .finally(() => {
        setIsShowingRemoveConfirm(false);
        setIsSubmittingConfirmation(false);
        onCancelClicked();
      });
  };
  const onRemoveCancelClicked = () => {
    setIsShowingRemoveConfirm(false);
  };

  const onConfirmReboot = (e: any) => {
    e.preventDefault();
    API.post("Conduit", `/devices/${device.id}/restart`, {
      headers: merchantId ? { "copper-account": merchantId } : {},
    })
      .then((res) => {})
      .catch((err) => {
        setRequestError(err.response.data.error.message);
      })
      .finally(() => {
        setIsShowingRebootConfirm(false);
        onCancelClicked();
      });
  };

  return (
    <>
      <Modal
        onClickOutside={avoidCancelWhenRemoveConfirming}
        visible={visible}
        maxWidthClass="max-w-2xl"
        backgroundColorClass="bg-form"
      >
        <ModalForm
          title={
            <div className="flex">
              <div className="flex text-responsive-2xl font-medium pr-2">
                <div className="w-min-32px w-8 my-auto">
                  {getDeviceIcon(device)}
                </div>
                {inEditMode ? (
                  <input
                    ref={register()}
                    id="label"
                    name="label"
                    className="copper-input -mb-2px h-9 -mt-0.5"
                    placeholder={t`Device Label`}
                    defaultValue={deviceLabel}
                  />
                ) : (
                  <div>{deviceLabel}</div>
                )}
              </div>
            </div>
          }
          isSubmitting={isSubmitting}
          onCancel={onCancelClicked}
          cancelButtonText={t`Close`}
          footerBtns={[
            {
              label: t`Reboot`,
              onClick: () => setIsShowingRebootConfirm(true),
            },
          ]}
          handleSubmit={handleSubmit(onFormSubmit)}
          submitError={requestError}
          hideSaveButton={true}
          controls={
            !inEditMode ? (
              <div className="space-x-2 -mb-1">
                <Button
                  onClick={() => setInEditMode(true)}
                  icon="ion-edit"
                  iconSize={18}
                >
                  <Trans>Edit</Trans>
                </Button>
                <Button
                  onClick={onRemoveClicked}
                  icon="ion-trash-b"
                  iconSize={20}
                >
                  <Trans>Remove</Trans>
                </Button>
              </div>
            ) : (
              <div className="flex space-x-2 -mb-1">
                <Button onClick={() => setInEditMode(false)}>
                  <Trans>Cancel</Trans>
                </Button>

                <button
                  type="submit"
                  className={`copper-button-confirm ${
                    isSubmitting && "text-transparent"
                  } ml-2 flex justify-center bg-color-red h-10`}
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <div className="absolute flex align-center justify-center mx-auto -my-0.5 px-1 md:my-0 md:px-1.5">
                      <Spinner size={20} color="white"></Spinner>
                    </div>
                  )}
                  <Trans>Save</Trans>
                </button>
              </div>
            )
          }
          backgroundColorClass="bg-white"
        >
          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Enabled</Trans>
              </div>
            </div>
            <span className="copper-label w-full">
              {location && location.status === "disabled" ? (
                "Disabled at location level"
              ) : (
                <Toggle
                  value={deviceEnabled}
                  onChange={() => {
                    const newValue = !deviceEnabled;
                    setDeviceEnabled(newValue);
                    onDeviceEnabled(newValue);
                  }}
                />
              )}
            </span>
          </div>

          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Device ID</Trans>
              </div>
            </div>
            <span className="copper-label w-full">{device?.id}</span>
          </div>

          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Serial number</Trans>
              </div>
            </div>
            <span className="copper-label w-full">{device?.serial_number}</span>
          </div>

          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Model</Trans>
              </div>
            </div>
            <span className="copper-label w-full">{device?.device_type}</span>
          </div>

          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Firmware version</Trans>
              </div>
            </div>
            <span className="copper-label w-full">
              {getFirmwareVersion(device)}
            </span>
          </div>

          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Last seen</Trans>
              </div>
            </div>
            <span className="copper-label w-full">
              {moment(device?.last_seen).fromNow()}
            </span>
          </div>

          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Last reported Wi-Fi strength</Trans>
              </div>
            </div>
            <span className="copper-label w-full">
              {WiFiStrengthDescription(device)}
            </span>
          </div>

          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Last reboot</Trans>
              </div>
            </div>
            <span className="copper-label w-full">
              {deviceEx?.last_reboot
                ? moment(deviceEx?.last_reboot).fromNow()
                : t`Unknown`}
            </span>
          </div>

          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div
                className={`text-gray-500 font-medium my-auto ${
                  device?.restart_required ? "text-red-700 font-bold" : ""
                }`}
              >
                <Trans>Reboot required</Trans>
              </div>
            </div>
            <span
              className={`copper-label w-full ${
                device?.restart_required ? "text-red-700 font-bold" : ""
              }`}
            >
              {device?.restart_required ? t`Yes` : t`No`}
            </span>
          </div>
          <div
            className={showLoggingOptions ? "flex justify-evenly" : "hidden"}
          >
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Logging</Trans>
              </div>
            </div>
            <span className="copper-label w-full">
              <select
                className={"border rounded-md px-3 py-1 float-left mr-2"}
                value={selectedLogLevel}
                onChange={(e) => {
                  setRequestError("");
                  setLogsBusy(true);
                  let value = e.target.value;
                  device.log_level = value;
                  setSelectedLogLevel(value);
                  setIsSubmitting(true);
                  const deviceId = device.id;
                  API.post("Conduit", `/devices/${deviceId}`, {
                    headers: merchantId ? { "copper-account": merchantId } : {},
                    body: {
                      log_level: value,
                    },
                  })
                    .then((updatedDevice) => {
                      console.log("/devices/", "then");
                      setIsSubmitting(true);
                      setSelectedLogLevel(updatedDevice.log_level);
                    })
                    .catch((err) => {
                      console.log("/devices/", "res", err);
                      setRequestError(err.message);
                    })
                    .finally(() => {
                      setIsSubmitting(false);
                      setLogsBusy(false);
                    });
                }}
              >
                <option value={0}>Off</option>
                <option value={1}>Error</option>
                <option value={2}>Warn</option>
                <option value={3}>Debug</option>
                <option value={4}>Info</option>
                <option value={5}>Trace</option>
              </select>
              {selectedLogLevel > 3 && (
                <span className="has-tooltip float-left mx-2 mt-1">
                  <span className="tooltip rounded shadow-lg z-[2147483647] bg-white p-2 border border-gray-100 text-base mt-6  ">
                    <Trans>
                      This log level could delay printing during business hours.
                      Use it for debugging severe issues only
                    </Trans>
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon w-5 text-gray-light"
                    fill="red"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" />
                  </svg>
                </span>
              )}
              {logsBusy ? (
                <span className={"float-left mx-2"}>
                  <Spinner size={20} color={"#ccc"}></Spinner>
                </span>
              ) : (
                <span className={"float-left cursor-pointer mx-2"}>
                  <svg
                    onClick={() => {
                      setLogsBusy(true);
                      let w = window.open("about:blank");
                      if (w != null) {
                        w.document.title = "Exporting logs..";
                      }

                      API.get("Conduit", `/devices/${device.id}/logs`, {
                        headers: { "copper-account": merchantId },
                      })
                        .then((result) => {
                          setRequestError("");
                          setLogsBusy(false);
                          if (result && result.url && w != null) {
                            w.location.replace(result.url);
                            setTimeout(() => {
                              w!!.close();
                            }, 2000);
                          }
                        })
                        .catch((e) => {
                          w?.close();
                          console.log(e);
                          setRequestError(e);
                          setLogsBusy(false);
                        });
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25 25"
                    width={30}
                    height={30}
                  >
                    <title>Download logs</title>
                    <path
                      fill="#aaa"
                      d="M6.5 20Q4.22 20 2.61 18.43 1 16.85 1 14.58 1 12.63 2.17 11.1 3.35 9.57 5.25 9.15 5.83 7.13 7.39 5.75 8.95 4.38 11 4.08V12.15L9.4 10.6L8 12L12 16L16 12L14.6 10.6L13 12.15V4.08Q15.58 4.43 17.29 6.39 19 8.35 19 11 20.73 11.2 21.86 12.5 23 13.78 23 15.5 23 17.38 21.69 18.69 20.38 20 18.5 20Z"
                    />
                  </svg>
                </span>
              )}
            </span>
          </div>
          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Network address</Trans>
              </div>
            </div>
            <span className="copper-label w-full">
              {`${device?.ip_address} ${device?.mac_address ?? ""}`}
            </span>
          </div>
          <div className="flex justify-evenly">
            <div className="w-full my-auto">
              <div className="text-gray-500 font-medium my-auto">
                <Trans>Connectivity</Trans>
              </div>
            </div>
            <span className="copper-label w-full">
              <div className="inline-flex " role="group">
                <RadioGroup
                  value={selectedTimeRange}
                  onChange={setSelectedTimeRange}
                  className="mt-2"
                >
                  <div className="grid grid-cols-3 gap-3 sm:grid-cols-5">
                    {timeRanges.map((timeRange) => (
                      <RadioGroup.Option
                        key={timeRange.name}
                        value={timeRange}
                        className={({ active, checked }) =>
                          classNames(
                            checked
                              ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                              : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                            "border rounded-lg py-2 px-2 flex items-center justify-center text-sm font-medium sm:flex-1"
                          )
                        }
                      >
                        <RadioGroup.Label as="span">
                          {timeRange.name}
                        </RadioGroup.Label>
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
            </span>
          </div>

          <div className="flex justify-evenly">
            <ConnectivityGraph
              device={shouldReloadDevice() ? null : deviceEx}
              range={selectedTimeRange.value}
            ></ConnectivityGraph>
          </div>
        </ModalForm>
      </Modal>
      <ConfirmModal
        onClickOutside={onRemoveCancelClicked}
        visible={isShowingRemoveConfirm}
        title={t`Remove device`}
        onConfirmClicked={onConfirmRemove}
        onCancelClicked={onRemoveCancelClicked}
        bodyText={t`This will remove device ${deviceLabel} from this location. Are you sure?`}
        buttonText={t`Remove device`}
        isSubmitting={isSubmittingConfirmation}
      />
      <ConfirmModal
        onClickOutside={() => setIsShowingRebootConfirm(false)}
        visible={isShowingRebootConfirm}
        title={t`Reboot device`}
        onConfirmClicked={onConfirmReboot}
        onCancelClicked={() => setIsShowingRebootConfirm(false)}
        bodyText={t`This will reboot device ${deviceLabel}. Are you sure?`}
        buttonText={t`Reboot device`}
        isSubmitting={isSubmittingConfirmation}
      />
    </>
  );
};

const getDeviceIcon: React.FC<any> = (device) => <DeviceIcon device={device} />;
const getFirmwareVersion = (device: any) => {
  if (!device) {
    return "";
  }

  return device.device_sw_version?.split("+")[0] ?? "";
};

export default DeviceModal;
