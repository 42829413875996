import React from "react";
import { t } from "@lingui/macro";

const TipField = ({
  register,
  name,
  label,
  defaultValue,
  extra = "",
  errors,
}: any) => (
  <div
    className={`${extra} h-12 border-gray-300 rounded-md	 border flex justify-center items-center`}
  >
    <label
      htmlFor="name"
      className=" text-base mr-3 justify-evenly  text-gray-700"
    >
      {label}
    </label>
    <div
      className={`border-b ${
        errors ? "border-red-700" : "border-gray-300"
      } focus-within:border-indigo-600`}
    >
      <input
        id={`${name}-field`}
        ref={register({
          max: { value: 30, message: t`Value must be less than 30%` },
        })}
        type="text"
        name={name}
        defaultValue={defaultValue}
        className={`${
          errors ? "border-red-700" : ""
        } w-10 text-base border-0 border-b pl-2 pr-2  border-transparent bg-white focus:border-indigo-600 focus:ring-0`}
      />
    </div>
    <span className="ml-2">%</span>
  </div>
);

export default React.memo(TipField);
