import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Button from "../components/Button";
import DataContainer from "../components/DataContainer";
import DataTable from "../components/DataTable";
import DetailsSection from "../components/DetailsSection";
import UsersList from "../components/lists/UsersList";
import MainHeader from "../components/MainHeader";
import MerchantModal from "../components/modals/MerchantModal";
import NewUserModal from "../components/modals/NewUserModal";
import ResellerModal from "../components/modals/ResellerModal";
import PlaceHolderDash from "../components/PlaceholderDash";
import ResourceHeader from "../components/ResourceHeader";
import ResourceId from "../components/ResourceId";
import Spinner from "../components/Spinner";
import { useAuth } from "../services/auth-context";
import * as Conduit from "../services/Conduit";
import { addressToString } from "../util/addressToString";
import Main from "./containers/Main";
import { t, Trans } from "@lingui/macro";
import { statusName } from "../components/utils/util";
import Merchants from "./Merchants";

type Props = {
  id?: string;
};

const Merchant: React.FC<RouteComponentProps<Props>> = (
  props: RouteComponentProps<Props>
) => {
  const { params } = props.match;
  const { overrideUser, isAdmin } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [isCreatingLocation, setIsCreatingMerchant] = useState(false);
  const [ShowingNewUserModal, setShowingNewUserModal] = useState(false);
  const [merchant, setMerchant] = useState<any[] | any>(null);
  const [merchants, setMerchants] = useState<any[] | any>(null);
  const [users, setUsers] = useState<any>(null);
  const history = useHistory();

  const getUsers = () => {
    if (!merchant) return;
    Conduit.usersAccount.getUsers(merchant.id).then((usr) => {
      setUsers(usr.data);
    });
  };

  const deleteUser = (userId: string) => {
    if (!merchant) return;
    Conduit.usersAccount.deleteUser(merchant.id, userId).then(() => {
      getUsers();
    });
  };
  const reInviteUser = (userId: string) => {
    if (!merchant) return;
    Conduit.usersAccount.reInviteUser(merchant.id, userId, true);
  };

  const getMerchants = () =>
    merchants.map((merchant: any) => ({
      click: () => {
        history.push(`/merchants/${merchant.id}`);
      },
      name: merchant.name,
      devices: <PlaceHolderDash />,
      address: addressToString(merchant.address),
    }));

  const updateMerchants = (merchant: any) => {
    API.get("Conduit", `/accounts`, {
      headers: { "copper-account": merchant?.id },
      queryStringParameters: { limit: 100, type: "merchant" },
    }).then((merchants) => setMerchants(merchants.data));
  };

  useEffect(() => {
    API.get("Conduit", `/accounts/${params.id}`, { type: "merchant" }).then(
      (merchant) => {
        setMerchant(merchant);
        updateMerchants(merchant);
      }
    );
  }, [params]);

  useEffect(() => {
    getUsers();
  }, [merchant]);

  if (!merchant) {
    return (
      <Main>
        <div className="flex justify-center p-responsive">
          <Spinner size={20} color="gray"></Spinner>
        </div>
      </Main>
    );
  }

  const headerSection = (
    <div className="border rounded-md p-responsive">
      <div className="flex flex-col justify-between mb-6 md:flex-row">
        <div className="w-full flex justify-content">
          <ResourceHeader>
            <Trans>Reseller</Trans>
          </ResourceHeader>
          {isAdmin && (
            <button
              className="mx-5 text-gray-400 font-medium text-12px  underline"
              onClick={() => overrideUser(merchant.id)}
            >
              <Trans>Reseller View</Trans>
            </button>
          )}
        </div>

        <ResourceId>{merchant.id}</ResourceId>
      </div>
      <div className="flex flex-row justify-between">
        <MainHeader>
          {merchant.name}
          <div className={"text-sm"}>{statusName(merchant.status)}</div>
        </MainHeader>
      </div>
    </div>
  );

  const detailsSection = (
    <DetailsSection
      nameDescription={t`Reseller Name`}
      name={merchant.name}
      address={merchant.address}
      onEditClicked={() => setIsEditing(true)}
    />
  );
  const merchantSection = (
    <DataContainer title={""} hideTitle={true}>
      <Merchants dataTableOnly={true} overrideAccount={merchant}></Merchants>
    </DataContainer>
  );

  const onModalCancel = () => {
    setIsEditing(false);
    setIsCreatingMerchant(false);
    setShowingNewUserModal(false);
  };
  const onEditMerchantSubmit = () => {
    setIsEditing(false);
    API.get("Conduit", `/accounts/${params.id}`, { type: "merchant" }).then(
      (merchant) => {
        setMerchant(merchant);
      }
    );
  };
  const editResllerModal = (
    <ResellerModal
      account={merchant}
      visible={isEditing}
      onSubmitSuccess={onEditMerchantSubmit}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      removeBlankFormValues={!isEditing}
    />
  );

  const onNewMerchantSubmit = () => {
    setIsCreatingMerchant(false);
    updateMerchants(merchant);
  };

  const newMerchantModal = (
    <MerchantModal
      visible={isCreatingLocation}
      onSubmitSuccess={onNewMerchantSubmit}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      removeBlankFormValues={true}
      sendType={true}
      parentAccount={merchant}
    />
  );

  const userSection = (
    <div>
      <DataContainer
        title={t`Users`}
        controls={
          <Button
            onClick={() => {
              setShowingNewUserModal(true);
            }}
            icon="ion-android-add"
          >
            <Trans>New</Trans>
          </Button>
        }
        removeInnerPadding={true}
      >
        <UsersList
          account={merchant}
          users={users}
          onDeleteUser={deleteUser}
          onReInviteUser={reInviteUser}
        />
        <NewUserModal
          merchantId={merchant.id}
          visible={ShowingNewUserModal}
          onSubmitSuccess={() => {
            getUsers();
            setShowingNewUserModal(false);
          }}
          onCancel={() => onModalCancel()}
          onClickOutside={() => onModalCancel()}
          hideRoleLocation={true}
        />
      </DataContainer>
    </div>
  );

  return (
    <Main>
      <div className="space-y-4 md:space-y-8">
        {[headerSection, detailsSection, merchantSection, userSection]}
      </div>
      {editResllerModal}
      {newMerchantModal}
    </Main>
  );
};

export default Merchant;
