import React, { useState } from "react";
import Toggle from "../../Toggle";
import { t, Trans } from "@lingui/macro";

const PreAuthComponent: React.FC<{
  isDisabled?: boolean;
  register: any;
  location?: any;
  setValue: any;
  isHide?: boolean;
}> = ({ location, setValue, register, isHide, isDisabled = false }) => {
  const { enabled, amount, consent_text } = location.settings.pre_auth;

  const [toggleEnabled, setToggleEnabled] = useState(enabled);

  return isHide !== true ? (
    <div>
      <div className="flex h-10 items-center">
        <Toggle
          onChange={() => {
            const newValue = !toggleEnabled;
            setToggleEnabled(newValue);
            setValue("pre_auth_enabled", newValue);
          }}
          value={toggleEnabled}
          isDisabled={isDisabled}
        />
        <span className="w-full text-lg pl-4 text-gray-900">
          <Trans>Enable pre-authorization</Trans>
        </span>
        <input
          id="pre_auth-enabled"
          name="pre_auth_enabled"
          value={toggleEnabled}
          ref={register()}
          type={"hidden"}
        />
        <div
          className={
            !toggleEnabled ? "hidden" : "pl-4 flex items-center align-middle"
          }
        >
          <input
            id="pre_auth-amount"
            name="pre_auth_amount"
            ref={register()}
            className="copper-input w-16"
            defaultValue={(amount / 100).toFixed(2)}
          />
        </div>
      </div>
      <div
        className={
          !toggleEnabled ? "hidden" : "pl-4 flex h-10 items-center pt-2"
        }
      >
        <span className="text-base">
          <Trans>Pre-authorization display message</Trans>
        </span>
      </div>
      <div className={!toggleEnabled ? "hidden" : "pl-4  flex items-center"}>
        <textarea
          cols={50}
          id="pre_auth-consent-text"
          name="pre_auth_consent_text"
          className={`copper-input text-responsive-xs max-h-96`}
          defaultValue={
            consent_text
              ? consent_text
              : t`* The tab will be closed with the actual spending.The remaining amount will go back to your card.\r\n* If the tab remains open after business hours, it will be closed using the card on file, with a 15% gratuity added.`
          }
          ref={register()}
        />
      </div>
    </div>
  ) : null;
};

export default React.memo(PreAuthComponent);
