import { API } from "aws-amplify";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { LogicBadge } from "../components/Badge";
import Button from "../components/Button";
import RefundModal from "../components/modals/RefundModal";
import getIcon from "../components/paymentField/img";
import ResourceHeader from "../components/ResourceHeader";
import ResourceId from "../components/ResourceId";
import Spinner from "../components/Spinner";
import TitledDetailRows from "../components/TitledDetailRows";
import UntitledDetailColumns from "../components/UntitledDetailColumns";
import { useAuth } from "../services/auth-context";
import { getTransactionStatus } from "../util/copperLogic";
import Main from "./containers/Main";
import { t, Trans } from "@lingui/macro";
import { positiveOrZero } from "../components/utils/util";

type Props = {
  id?: string;
};

function getSaleAmount(transaction: any) {
  const result =
    (transaction.amount -
      transaction.tip_amount -
      (transaction.adjustment_amount || 0) -
      (transaction.donation_amount || 0)) /
    100;
  return positiveOrZero(result);
}

const Transaction: React.FC<RouteComponentProps<Props>> = (
  props: RouteComponentProps<Props>
) => {
  const { params } = props.match;
  const [transaction, setTransaction] = useState<any[] | any>(null);
  const [ticket, setTicket] = useState<any[] | any>(null);
  const [location, setLocation] = useState<any[] | any>(null);
  const [isShowingRefundModal, setIsShowingRefundModal] = useState(false);
  const { role } = useAuth();
  const canRefund = role === "owner" || role === "manager";

  useEffect(() => {
    API.get("Conduit", `/transactions/${params.id}`, {}).then(
      async (transaction) => {
        await Promise.all([
          API.get("Conduit", `/locations/${transaction.location}`, {}).then(
            setLocation
          ),
          API.get("Conduit", `/tickets/${transaction.ticket}`, {}).then(
            setTicket
          ),
        ]);

        setTransaction(transaction);
      }
    );
  }, [params]);

  const onModalSubmit = () => {
    setIsShowingRefundModal(false);
    API.get("Conduit", `/transactions/${params.id}`, {}).then((transaction) => {
      setTransaction(transaction);
    });
  };
  const onModalCancel = () => {
    setIsShowingRefundModal(false);
  };

  if (!transaction) {
    return (
      <Main>
        <div className="flex justify-center p-responsive">
          <Spinner size={20} color="gray"></Spinner>
        </div>
      </Main>
    );
  }

  const PaymentMethod =
    transaction.payment_method?.type === "liven"
      ? [{ name: t`Payment Type`, value: t`Liven` }]
      : // ? transaction.payment_method.card
      //   ? [
      //       { name: t`Payment Type`, value: t`Liven Card` },
      //       {
      //         name: t`Card brand`,
      //         value: transaction.payment_method.card.brand,
      //       },
      //       { name: t`Last 4`, value: transaction.payment_method.card.last4 },
      //     ]
      //   : [{ name: t`Payment Type`, value: t`Liven foodollars` }]
      transaction.payment_method?.type === "grabpay"
      ? [{ name: t`Payment Type`, value: "GrabPay" }]
      : transaction.payment_method?.type === "vip"
      ? [
          { name: t`Payment Type`, value: "VIP Preferred" },
          {
            name: t`Bank Name`,
            value: `${transaction.payment_method?.bank_account?.bank_name}`,
          },
          {
            name: t`Account Number`,
            value: `•••• ${transaction.payment_method?.bank_account?.account_number.replace(
              /\*/g,
              ""
            )}`,
          },
        ]
      : [
          {
            name: t`Card name`,
            value:
              transaction.customer?.name
                ?.split("/")
                .reverse()
                .join(" ")
                .trim()
                .toLowerCase() || t`Unknown`,
          },
          {
            name: t`Payment method`,
            value: (
              <>
                <img
                  className="inline-block h-8 w-8 -mt-1 mr-2"
                  alt="icon-payment"
                  src={getIcon(
                    transaction.payment_method?.type === "vip"
                      ? "vip"
                      : transaction.payment_method?.card?.brand
                  )}
                />
                <span>
                  {`•••• ${
                    transaction.payment_method?.card?.last4 ||
                    transaction.payment_method?.bank_account?.account_number.replace(
                      /\*/g,
                      ""
                    )
                  }`}
                </span>
              </>
            ),
          },
        ];

  return (
    <Main>
      <div className="pb-7 mb-8 border-b">
        <div className="flex flex-row justify-between pb-6">
          <ResourceHeader>
            <Trans>Transaction</Trans>
          </ResourceHeader>
          <ResourceId>{transaction.id}</ResourceId>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row text-responsive-3xl my-auto">
            <span className="font-medium mr-3">
              ${(transaction.amount / 100).toFixed(2)}
            </span>{" "}
            <span className="mr-3 text-gray-400 uppercase">
              {transaction.currency}
            </span>
            <div className="flex my-auto">
              <LogicBadge
                value={getTransactionStatus(transaction)}
                definition={{
                  succeeded: "green",
                  "pre-authorized": "green",
                  refunded: "yellow",
                  "partial refund": "yellow",
                  voided: "yellow",
                  "partial void": "yellow",
                  declined: "red",
                  failed: "red",
                }}
              />
            </div>
          </div>
          {canRefund && (
            <Button
              onClick={() => setIsShowingRefundModal(true)}
              disabled={
                transaction.status === "failed" ||
                transaction.refunded ||
                transaction.payment_method?.type === "vip"
              }
              icon="ion-arrow-return-left"
            >
              {transaction.refunded
                ? transaction.captured === null
                  ? t`Voided`
                  : t`Refunded`
                : transaction.payment_method?.type === "vip"
                ? t`Non Refundable`
                : transaction.captured === null
                ? t`Void`
                : t`Refund`}
            </Button>
          )}
        </div>
      </div>
      <UntitledDetailColumns
        details={[
          {
            name: t`Date`,
            value: DateTime.fromISO(transaction.created).toLocaleString(
              DateTime.DATETIME_MED
            ),
          },
          {
            name: t`Location`,
            value: location.display_name,
          },
          {
            name: t`Ticket ref. number`,
            value: (
              <Link
                className="underline hover:text-gray-active"
                to={`/tickets/${ticket.id}`}
              >
                {ticket.reference}
              </Link>
            ),
          },
          {
            name: t`Customer receipt`,
            value: (
              <a
                className="flex items-center underline hover:text-gray-active"
                href={transaction.receipt_url}
                target="_blank"
              >
                <svg
                  className="mr-2"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.6667 12H2.33333V10.4H11.6667V12ZM11.6667 8.8H2.33333V7.2H11.6667V8.8ZM11.6667 5.6H2.33333V4H11.6667V5.6ZM0 16L1.16667 14.8L2.33333 16L3.5 14.8L4.66667 16L5.83333 14.8L7 16L8.16667 14.8L9.33333 16L10.5 14.8L11.6667 16L12.8333 14.8L14 16V0L12.8333 1.2L11.6667 0L10.5 1.2L9.33333 0L8.16667 1.2L7 0L5.83333 1.2L4.66667 0L3.5 1.2L2.33333 0L1.16667 1.2L0 0V16Z"
                    fill="#2E3B4E"
                  />
                </svg>
                <Trans>View Receipt</Trans>
              </a>
            ),
          },
        ]}
      />
      <TitledDetailRows
        className="pt-16"
        title={t`Payment details`}
        details={[
          {
            name: t`Sale`,
            value: `$${getSaleAmount(transaction).toFixed(2)}`,
            isHide: transaction.amount == 0,
          },
          {
            name: t`Tip`,
            value: `$${(transaction.tip_amount / 100).toFixed(2)}`,
            isHide: transaction.amount == 0,
          },
          {
            name: t`Donation`,
            value: `$${(transaction.donation_amount / 100).toFixed(2)}`,
            isHide:
              !(transaction.donation_amount > 0) || transaction.amount == 0,
          },
          {
            name: t`Adjustment`,
            value: `$${(transaction.adjustment_amount / 100).toFixed(2)}`,
            isHide:
              !(transaction.adjustment_amount > 0) || transaction.amount == 0,
          },
          {
            name: t`Fee`,
            value: `($${(transaction.fee_amount / 100).toFixed(2)})`,
            isHide: !(transaction.fee_amount > 0) || transaction.amount == 0,
          },
          {
            name: "Refund",
            value: `($${(transaction.refunded_amount / 100).toFixed(2)})`,
            isHide: !(transaction.refunded_amount > 0),
          },

          { name: t`Status`, value: getTransactionStatus(transaction) },
        ]}
      />
      <TitledDetailRows
        className="pt-16"
        title={t`Payment method`}
        details={PaymentMethod}
      />
      <TitledDetailRows
        className="pt-16"
        title={t`Gateway Response`}
        details={[
          { name: t`Approval code`, value: transaction.approval_code || "N/A" },
        ]}
      />
      <RefundModal
        transactionId={transaction.id}
        refundableAmount={transaction.amount - transaction.refunded_amount}
        visible={isShowingRefundModal}
        onCancel={onModalCancel}
        onSubmitSuccess={onModalSubmit}
        onClickOutside={onModalCancel}
        merchantId={transaction.account}
        currency={transaction.currency}
      ></RefundModal>
    </Main>
  );
};

export default Transaction;
