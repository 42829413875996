import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import AuthBackdrop from "../../components/auth/AuthBackdrop";
import Spinner from "../../components/Spinner";
import { useAuth } from "../../services/auth-context";
import { t, Trans } from "@lingui/macro";

const LoginScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit } = useForm({ reValidateMode: "onChange" });
  const { signIn, isLoggedIn } = useAuth();
  const [serverError, setServerError] = useState<any>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();

  if (isLoading) {
    setIsLoading(false);

    return (
      <AuthBackdrop>
        <div className="flex justify-center p-responsive">
          <Spinner size={20} color="gray"></Spinner>
        </div>
      </AuthBackdrop>
    );
  }

  const SignInSubmit = async (formData: any) => {
    setIsSubmitted(true);
    setServerError({});
    const { email, password } = formData;
    signIn(email, password, () => history.push("reset?action=newpassword"))
      .catch((error: Error) => {
        setServerError(error);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  if (isLoggedIn) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <AuthBackdrop>
      <>
        <h2 className="auth-title">
          <Trans>Sign in to your account</Trans>
        </h2>

        <div className="p-3"></div>

        <form className="space-y-6" onSubmit={handleSubmit(SignInSubmit)}>
          <div className="pb-2">
            <label htmlFor="email" className="auth-label">
              <Trans>Email</Trans>
            </label>
            <div className="mt-1">
              <input
                ref={register({
                  required: t`You must specify an user Email`,
                })}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="copper-input"
                tabIndex={1}
              />
            </div>
          </div>

          <div className="pb-0.5">
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="auth-label">
                <Trans>Password</Trans>
              </label>

              <div className="text-sm">
                <a href="/reset" className="auth-label-link">
                  <Trans>Forgot your password?</Trans>
                </a>
              </div>
            </div>

            <div className="mt-1">
              <input
                ref={register({
                  required: t`You must specify an user Email`,
                })}
                id="password"
                name="password"
                disabled={isSubmitted}
                type="password"
                autoComplete="current-password"
                required
                className="copper-input"
                tabIndex={2}
              />
            </div>
          </div>

          {!!serverError.message && (
            <p className="label-error">{serverError.message}</p>
          )}

          <div className="flex items-center pt-0.5">
            <input
              ref={register}
              disabled={isSubmitted}
              id="remember_me"
              name="remember_me"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label htmlFor="remember_me" className="auth-label pl-3">
              <Trans>Stay signed in for a week</Trans>
            </label>
          </div>

          <div className="pb-3">
            <button
              tabIndex={3}
              type="submit"
              disabled={isSubmitted}
              className="copper-button-confirm-full h-10"
            >
              {isSubmitted ? (
                <div className="flex align-center justify-center">
                  <Spinner size={16} color="white"></Spinner>
                </div>
              ) : (
                t`Continue`
              )}
            </button>
          </div>
        </form>
      </>
    </AuthBackdrop>
  );
};

export default LoginScreen;
