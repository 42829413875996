import React from "react";

const CenteredErrorLabel: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="flex justify-center p-responsive">
      <span className="label-error">{message}</span>
    </div>
  );
};

export default CenteredErrorLabel;
