import { API } from "aws-amplify";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../components/DataTable";
import MainHeader from "../components/MainHeader";
import Spinner from "../components/Spinner";
import objectToQueryString from "../util/objectToQueryString";
import CenteredErrorLabel from "../components/CenteredErrorLabel";
import FixedMain from "./containers/FixedMain";
import { t, Trans } from "@lingui/macro";
import { resetFilterSettings } from "../components/filters/merchants/MerchantFilter";
import { valueListToString } from "../components/utils/util";
import Button from "../components/Button";
import MerchantFilter, {
  getMerchantQueryFilterSettings,
} from "../components/filters/merchants/MerchantFilter";
import MerchantModal from "../components/modals/MerchantModal";
import { useAuth } from "../services/auth-context";

const pageItemCount = 10;
const loadMoreCount = 10;

const slash = "     ";

const Merchants: React.FC<any> = (props) => {
  const { dataTableOnly, overrideAccount } = props;
  const history = useHistory();
  const [showingNewModal, setShowingNewModal] = useState(false);
  const { user } = useAuth();

  const onModalSubmitSuccess = (newMerchant: any) => {
    setShowingNewModal(false);
    history.push(`/merchants/${newMerchant.id}`);
  };

  const onModalCancel = () => {
    setShowingNewModal(false);
  };
  const [error, setError] = useState("");

  const [merchants, setMerchants] = useState<any[] | any>(null);
  const [filterLocationId, setFilterLocationId] = useState("");
  const [filterSettings, setFilterSettings] = useState(
    getMerchantQueryFilterSettings()
  );

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadMerchants = (
    limit: number,
    offset: number,
    consumer: (data: any) => void
  ) => {
    setLoading(true);
    setError("");
    let parameters = filterSettingsToRequestParameters(filterSettings);
    window.history.replaceState("", "", `?${objectToQueryString(parameters)}`);
    const headers = overrideAccount && {
      "copper-account": overrideAccount.id,
    };
    API.get("Conduit", "/merchants", {
      headers: headers,
      queryStringParameters: { ...parameters, offset: offset, limit: limit },
    })
      .then(({ data: recordsList }) => {
        let filtered = recordsList;
        if (filterSettings.location) {
          filtered = filtered.filter((r: any) => {
            return r.locations > 0;
          });
        }
        setHasMore(filtered.length == limit);
        consumer(filtered);
      })
      .catch((err) => {
        setError(err.response?.data?.error?.message || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadMerchants(pageItemCount, 0, (data) => {
      setMerchants(data);
    });
  }, [filterSettings]);

  const statusClassName = (status: string) => {
    switch (status) {
      case "active":
        return " ";
      case "inactive":
        return " text-yellow-600";
      case "disabled":
        return " text-red-600";
    }
  };
  const countRow = (
    total: number,
    active: number,
    inactive: number,
    disabled: number
  ) => {
    return (
      <div>
        {/*<span>{total}</span>*/}
        {active > 0 && <span className={"text-green-600"}>{active} a</span>}

        {inactive > 0 && (
          <span className={"text-yellow-600"}>
            {slash}
            {inactive} i
          </span>
        )}
        {disabled > 0 && (
          <span className={"text-red-600"}>
            {slash}
            {disabled} d
          </span>
        )}
      </div>
    );
  };
  const getMerchants = () => {
    console.log(merchants);
    return merchants.map((merchant: any) => {
      const isSingapore = merchant.country.toLowerCase() == "sg";
      return {
        url: `/merchants/${merchant.id}`,
        name: (
          <div
            className={
              "capitalize w-24 text-left" + statusClassName(merchant.status)
            }
          >
            {merchant.name}
          </div>
        ),
        where: (
          <div className="capitalize w-10 text-left">
            {isSingapore ? "Singapore" : `${merchant.city}, ${merchant.state}`}
          </div>
        ),

        locations: (
          <div className="capitalize w-10">
            {countRow(
              merchant.locations,
              merchant.activeLocations,
              merchant.inactiveLocations,
              merchant.disabledLocations
            )}
          </div>
        ),
        cords: (
          <div className="capitalize w-10">
            {countRow(0, merchant.activeCords, 0, merchant.inactiveCords)}
          </div>
        ),
        devices: (
          <div className="capitalize w-10">
            {countRow(0, merchant.activeNotCords, 0, merchant.inactiveNotCords)}
          </div>
        ),
      };
    });
  };

  const componentState = {
    loading,
    filterLocationId,
    setFilterLocationId,
    filterSettings,
    setFilterSettings,
  };
  const bottomScrollRef = useRef<HTMLDivElement | null>(null);

  let contents = (
    <div>
      <div className="p-4 flex justify-between">
        <MainHeader>
          <Trans>Merchants</Trans>
        </MainHeader>
        <Button onClick={() => setShowingNewModal(true)} icon="ion-android-add">
          <Trans>New</Trans>
        </Button>{" "}
      </div>
      <MerchantModal
        visible={showingNewModal}
        onCancel={onModalCancel}
        onSubmitSuccess={onModalSubmitSuccess}
        onClickOutside={onModalCancel}
        removeBlankFormValues={true}
        parentAccount={overrideAccount || user}
        sendType={true}
      />
      <div className={"w-full h-12"}>
        <MerchantFilter {...componentState}></MerchantFilter>
      </div>
      <div
        className={"overflow-auto"}
        style={{ height: dataTableOnly ? "" : "calc(100vh - 200px)" }}
      >
        {!!error && <CenteredErrorLabel message={error} />}
        {!!merchants ? (
          <DataTable
            data={getMerchants()}
            noItemsHeader={t`No merchants found`}
            noItemsBody={t`Click New to add a new merchant`}
            headerClasses="pl-4"
            tdClass="px-4 py-2"
            // enableHalfWidthCells={true}
            noBorderTopOnHeaders={true}
            disableFullWidthCells={true}
            disableFlexBetween={true}
            footer={
              hasMore && (
                <div className={"w-full flex items-center justify-center p-4"}>
                  <div
                    ref={bottomScrollRef}
                    className="grid  place-items-center w-36 h-8 cursor-pointer p-4 py-0.5 text-base rounded text-white bg-copper-purple my-auto hover:bg-copper-purple-hover"
                    onClick={() => {
                      if (loading) {
                        return;
                      }

                      loadMerchants(
                        loadMoreCount,
                        merchants?.length ?? 0,
                        (data) => {
                          setMerchants(merchants.concat(data));
                        }
                      );
                    }}
                  >
                    <div>
                      {loading && !error ? (
                        <Spinner size={20} color="white"></Spinner>
                      ) : (
                        t`Load more...`
                      )}
                    </div>
                  </div>
                </div>
              )
            }
            columns={[
              {
                name: "name",
                label: t`Name`,
                type: "string",
                headerClass: "uppercase w-24",
                tdClass: "",
              },
              {
                name: "where",
                label: t`Where`,
                type: "string",
                headerClass: "uppercase w-8",
                tdClass: "uppercase w-8",
              },
              {
                name: "locations",
                label: t`Locations`,
                type: "string",
                headerClass: "uppercase w-12",
                tdClass: "",
              },
              {
                name: "cords",
                label: t`Cords`,
                type: "string",
                headerClass: "uppercase w-12",
                tdClass: "",
              },
              {
                name: "devices",
                label: t`Devices`,
                type: "string",
                headerClass: "uppercase w-12",
                tdClass: "",
              },
            ]}
          />
        ) : (
          !error && (
            <div className="flex justify-center p-responsive">
              <Spinner size={20} color="gray"></Spinner>
            </div>
          )
        )}
      </div>
    </div>
  );
  if (dataTableOnly) {
    return contents;
  }
  return <FixedMain>{contents}</FixedMain>;
};

const filterSettingsToRequestParameters = (filterSettings: any) => {
  let requestParameters = {};
  if (filterSettings.sort) {
    requestParameters = {
      ...requestParameters,
      sort: filterSettings.sort ?? resetFilterSettings.sort,
    };
  }
  if (filterSettings.sortDirection) {
    requestParameters = {
      ...requestParameters,
      direction: filterSettings.sortDirection,
    };
  }

  const status = valueListToString(filterSettings.status);
  if (status) {
    requestParameters = {
      ...requestParameters,
      status: status,
    };
  }

  if (filterSettings.name) {
    requestParameters = {
      ...requestParameters,
      name: filterSettings.name,
    };
  }

  if (filterSettings.location) {
    requestParameters = {
      ...requestParameters,
      location: filterSettings.location,
    };
  }

  return requestParameters;
};

export default Merchants;
