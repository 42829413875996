import { API } from "aws-amplify";
import moment from "moment";
import "moment/min/locales";
import React, { useEffect, useRef, useState } from "react";
import { LogicBadge } from "../components/Badge";
import DataTable from "../components/DataTable";
import TransactionFilter, {
  getQueryFilterSettings,
  resetFilterSettings,
} from "../components/filters/transactions/TransactionFilter";
import MainHeader from "../components/MainHeader";
import PaymentField from "../components/paymentField";
import Spinner from "../components/Spinner";
import { useAuth } from "../services/auth-context";
import { getTransactionStatus } from "../util/copperLogic";
import objectToQueryString from "../util/objectToQueryString";
import CenteredErrorLabel from "../components/CenteredErrorLabel";
import FixedMain from "./containers/FixedMain";
import { ALL, valueListToString } from "../components/utils/util";
import { t, Trans } from "@lingui/macro";
import { getCurrentLocale } from "../util/i18n";
import Button from "../components/Button";
import ExportTransactionsModal from "../components/modals/ExportTransactionsModal";
import {
  requestNotificationPermissionIfNeeded,
  showNotification,
} from "../components/NotificationManager";
import Modal from "../components/Modal";

const dateFormat = "YYYY-MM-DD";
const maxExportDuration = 93;
const pageItemCount = 50;
const loadMoreCount = 20;
const currentYear = moment().year();
moment.locale(getCurrentLocale());

let currency = "";
const Transactions: React.FC = () => {
  const [error, setError] = useState("");

  const [transactions, setTransactions] = useState<any[] | any>(null);
  const transactionsRef = useRef(transactions);
  transactionsRef.current = transactions;
  const [locations, setLocations] = useState<any[] | any>([]);

  const [filterLocationId, setFilterLocationId] = useState("");
  const [filterSettings, setFilterSettings] = useState(getQueryFilterSettings);

  const refreshIntervalMillis = () => {
    switch (filterSettings.refresh) {
      case "show":
        return 10000;
      case "highlight":
        return 5000;
      case "notify":
        return 2000;
      default:
      case "off":
        return 0;
    }
  };
  const shouldHighlightNew =
    filterSettings.refresh === "highlight" ||
    filterSettings.refresh === "notify";
  const shouldNotifyNew = filterSettings.refresh === "notify";

  const { user, isOverrideUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const canExport = () => {
    if (filterSettings.startDate == null || filterSettings.endDate == null) {
      return false;
    }

    const diff = moment(filterSettings.endDate).diff(
      moment(filterSettings.startDate)
    );
    if (isNaN(diff)) {
      return false;
    }
    const days = moment.duration(diff).asDays();
    return days <= maxExportDuration;
  };

  useEffect(() => {
    API.get("Conduit", "/locations", {
      queryStringParameters: { limit: 100 },
    }).then((locations) => {
      if (locations.data) {
        if (!currency && locations.data.length > 0) {
          currency = locations.data[0].currency.toUpperCase();
        }
        setLocations(
          locations.data.map((location: any) => ({
            id: location.id,
            name: location.display_name,
          }))
        );
      }
    });
  }, []);
  const loadTransactions = (
    limit: number,
    offset: number,
    consumer: (data: any) => void,
    after?: string
  ) => {
    setLoading(true);
    setError("");
    let parameters = filterSettingsToRequestParameters(filterSettings);

    window.history.replaceState("", "", `?${objectToQueryString(parameters)}`);
    if (after) {
      parameters = {
        ...parameters,
        after: after,
      };
    }
    API.get("Conduit", "/transactions", {
      queryStringParameters: { ...parameters, offset: offset, limit: limit },
    })
      .then(({ data: recordsList }) => {
        setHasMore(recordsList.length == limit);
        consumer(recordsList);
      })
      .catch((err) => {
        setError(err.response?.data?.error?.message || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadTransactions(pageItemCount, 0, (data) => {
      setTransactions(data);
    });
  }, [filterSettings]);

  const [latestTransaction, setLatestTransaction] = useState<{
    title: string;
    body: string;
    success: boolean;
  } | null>(null);

  useEffect(() => {
    if (!latestTransaction) {
      return;
    }
    showNotification(
      latestTransaction.title,
      latestTransaction.body,
      "transaction-notification.mp3"
    );

    setTimeout(() => {
      setLatestTransaction(null);
    }, 15000);
  }, [latestTransaction]);

  const refresh = () => {
    const latest = transactionsRef.current
      .map((t: any) => t.created)
      .sort((a: string, b: string) => a < b)[0];

    if (!latest) {
      loadTransactions(pageItemCount, 0, (data) => {
        setTransactions(data);
      });
      return;
    }

    loadTransactions(
      pageItemCount,
      0,
      (data) => {
        if (data.length == 0) {
          return;
        }
        let newTransactions = transactionsRef.current;
        newTransactions.forEach((t: any) => (t.isNew = false));
        data.forEach((newTransaction: any) => {
          if (transactions.some((t: any) => t.id === newTransaction.id)) {
            console.log("Duplicate found, this should not happen");
          } else {
            newTransaction.isNew = true;
            newTransactions.unshift(newTransaction);
            if (shouldNotifyNew) {
              const location = locations.filter(
                (l: any) => l.id === newTransaction.location
              )[0];
              const amount = (newTransaction.amount / 100).toFixed(2);
              const success = newTransaction.status === "succeeded";

              const title = success
                ? `New ${currency} ${amount} transaction at ${location.name}`
                : `Failed transaction at ${location.name}. Please retry`;
              const body = moment(newTransaction.created).format(
                "MMM DD, h:mm A"
              );
              setLatestTransaction({ title, body, success });
              setTimeout(() => {
                try {
                  const msg = new SpeechSynthesisUtterance(title);
                  msg.rate = 0.9;
                  speechSynthesis.speak(msg);
                } catch (e) {
                  console.log(e);
                }
              }, 1000);
            }
          }
        });
        setTransactions(newTransactions);
      },
      latest
    );
  };

  useEffect(() => {
    if (shouldNotifyNew) {
      requestNotificationPermissionIfNeeded();
    }
    if (refreshIntervalMillis() <= 0) {
      return;
    }
    if (!filterSettings.refresh) {
      return;
    }
    const interval = setInterval(refresh, refreshIntervalMillis());
    return () => clearInterval(interval);
  }, [refreshIntervalMillis(), filterSettings]);

  const getTransactions = () => {
    return transactions.map((transaction: any) => {
      return {
        url: `/transactions/${transaction.id}`,
        highlight: shouldHighlightNew && transaction.isNew,
        amount: (
          <div className={"flex flex-row -mr-4"}>
            <span className={"w-14 text-right"}>
              {"$" + (transaction.amount / 100).toFixed(2)}
            </span>
            <span className="uppercase text-center text-gray-400 w-14 pr-2">
              {transaction.currency}
            </span>
            <div className="w-24">
              <LogicBadge
                value={getTransactionStatus(transaction)}
                full
                definition={{
                  succeeded: "green",
                  "pre-authorized": "green",
                  refunded: "yellow",
                  "partial refund": "yellow",
                  voided: "yellow",
                  "partial void": "yellow",
                  declined: "red",
                  failed: "red",
                }}
              />
            </div>
          </div>
        ),
        location: (
          <div className="w-1/2">
            {locations.length > 0 &&
              locations.find(
                (location: { id: any; name: string }) =>
                  location.id === transaction.location
              )?.name}
          </div>
        ),
        customer: (
          <span className="capitalize">
            {(!!transaction?.customer?.name &&
              transaction.customer.name
                .split("/")
                .reverse()
                .join(" ")
                .trim()
                .toLowerCase()) ||
              t`Unknown`}
          </span>
        ),
        paymentMethod: (
          <PaymentField
            type={
              transaction.payment_method?.type === "vip"
                ? "vip"
                : transaction.payment_method?.type === "liven"
                ? "liven"
                : transaction.payment_method?.type === "grabpay"
                ? "grabpay"
                : transaction.payment_method?.card?.brand
            }
            last4={
              transaction.payment_method?.type === "liven"
                ? ""
                : transaction.payment_method?.type === "grabpay"
                ? ""
                : transaction.payment_method?.card?.last4 ||
                  transaction.payment_method?.bank_account?.account_number.replace(
                    /\*/g,
                    ""
                  )
            }
          />
        ),
        date: transaction.created.includes(currentYear)
          ? moment(transaction.created).format("MMM DD, h:mm A")
          : moment(transaction.created).format("YYYY, MMM DD, h:mm A"),
      };
    });
  };

  const componentState = {
    locations,
    filterLocationId,
    setFilterLocationId,
    filterSettings,
    setFilterSettings,
  };
  const bottomScrollRef = useRef<HTMLDivElement | null>(null);

  const [showExportModal, setShowExportModal] = useState(false);
  return (
    <FixedMain>
      <div>
        <ExportTransactionsModal
          onClickOutside={() => {
            setShowExportModal(false);
          }}
          visible={showExportModal}
          canExport={canExport()}
          currentParams={filterSettingsToRequestParameters(filterSettings)}
        ></ExportTransactionsModal>

        <Modal
          onClickOutside={() => {
            setLatestTransaction(null);
          }}
          visible={latestTransaction != null}
        >
          <div
            className={`w-30 rounded-md ${
              latestTransaction?.success ? "bg-green-50" : "bg-red-50"
            }`}
          >
            <div className={"text-sm text-gray-500 p-3"}>
              {latestTransaction?.body}
            </div>
            <div className={"flex justify-center w-100"}>
              <img
                width={200}
                src={
                  latestTransaction?.success
                    ? "img/success.svg"
                    : "img/failure.svg"
                }
              ></img>
            </div>
            <div className={"text-xl p-5 text-center"}>
              {latestTransaction?.title}
            </div>
          </div>
        </Modal>
        <div className="p-4 flex justify-between">
          <MainHeader>
            <Trans>Transactions</Trans>
            <Button
              className={"ml-10 py-0"}
              onClick={() => {
                setShowExportModal(true);
              }}
            >
              <Trans>Export</Trans>
            </Button>
          </MainHeader>
        </div>
        <div className={"w-full h-12"}>
          <TransactionFilter {...componentState}></TransactionFilter>
        </div>
        <div
          className={"overflow-auto"}
          style={{ height: "calc(100vh - 200px)" }}
        >
          {!!error && <CenteredErrorLabel message={error} />}
          {!!transactions ? (
            <DataTable
              data={getTransactions()}
              noItemsHeader={t`No transactions yet`}
              noItemsBody={t`When a transaction is made, it will show here.`}
              headerClasses="pl-4"
              tdClass="px-4 py-2"
              noBorderTopOnHeaders={true}
              disableFullWidthCells={true}
              disableFlexBetween={true}
              footer={
                hasMore && (
                  <div
                    className={"w-full flex items-center justify-center p-4"}
                  >
                    <div
                      ref={bottomScrollRef}
                      className="grid  place-items-center w-36 h-8 cursor-pointer p-4 py-0.5 text-base rounded text-white bg-copper-purple my-auto hover:bg-copper-purple-hover"
                      onClick={() => {
                        if (loading) {
                          return;
                        }

                        loadTransactions(
                          loadMoreCount,
                          transactions?.length ?? 0,
                          (data) => {
                            setTransactions(transactions.concat(data));
                          }
                        );
                      }}
                    >
                      <div>
                        {loading && !error ? (
                          <Spinner size={20} color="white"></Spinner>
                        ) : (
                          t`Load more..`
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
              columns={[
                {
                  name: "amount",
                  label: t`Amount`,
                  type: "currency",
                  headerClass: "uppercase w-12",
                  tdClass: "",
                },
                {
                  name: "location",
                  label: t`Location`,
                  type: "string",
                  headerClass: "uppercase",
                  tdClass: "w-1/2",
                },
                {
                  name: "customer",
                  label: t`Customer`,
                  type: "string",
                  headerClass: "uppercase",
                  tdClass: "w-1/2",
                },
                {
                  name: "paymentMethod",
                  label: t`Payment Method`,
                  type: "component",
                  headerClass: "uppercase w-44",
                },
                {
                  name: "date",
                  label: t`Date`,
                  type: "datetime",
                  headerClass: "uppercase w-12",
                },
              ]}
            />
          ) : (
            !error && (
              <div className="flex justify-center p-responsive">
                <Spinner size={20} color="gray"></Spinner>
              </div>
            )
          )}
        </div>
      </div>
    </FixedMain>
  );
};

const filterSettingsToRequestParameters = (filterSettings: any) => {
  let requestParameters = {};
  if (filterSettings.sort) {
    requestParameters = {
      ...requestParameters,
      sort: filterSettings.sort ?? resetFilterSettings.sort,
    };
  }
  if (filterSettings.sortDirection) {
    requestParameters = {
      ...requestParameters,
      direction: filterSettings.sortDirection,
    };
  }
  if (filterSettings.location.id) {
    requestParameters = {
      ...requestParameters,
      location: filterSettings.location.id,
    };
  }

  if (filterSettings.startDate) {
    requestParameters = {
      ...requestParameters,
      captured_from: moment(filterSettings.startDate).format(dateFormat),
    };
  }
  if (filterSettings.endDate) {
    requestParameters = {
      ...requestParameters,
      captured_to: moment(filterSettings.endDate).format(dateFormat),
    };
  }

  if (filterSettings.minAmount) {
    requestParameters = {
      ...requestParameters,
      min_amount: filterSettings.minAmount,
    };
  }
  if (filterSettings.maxAmount) {
    requestParameters = {
      ...requestParameters,
      max_amount: filterSettings.maxAmount,
    };
  }

  if (filterSettings.cardNumber) {
    requestParameters = {
      ...requestParameters,
      card: filterSettings.cardNumber,
    };
  }

  if (filterSettings.ticket) {
    requestParameters = {
      ...requestParameters,
      ticket: filterSettings.ticket,
    };
  }

  if (filterSettings.type != ALL) {
    requestParameters = {
      ...requestParameters,
      type: filterSettings.type,
    };
  }
  const method = valueListToString(filterSettings.method);
  if (method) {
    requestParameters = {
      ...requestParameters,
      method: method,
    };
  }

  if (filterSettings.status != ALL) {
    requestParameters = {
      ...requestParameters,
      status: filterSettings.status,
    };
  }
  return requestParameters;
};

export default Transactions;
