import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { Trans } from "@lingui/macro";
import { getCurrentLocale } from "../../../../util/i18n";

registerLocale("es", es);

const InBetweenSettings: React.FC<any> = ({
  filterSettings,
  setFilterSettings,
}) => {
  return (
    <>
      <DatePicker
        selected={filterSettings.dateRange.startDate}
        onChange={(date: any) =>
          setFilterSettings({
            ...filterSettings,
            dateRange: {
              ...filterSettings.dateRange,
              startDate: date,
            },
          })
        }
        customInput={
          <input className="copper-input-basic rounded text-gray-500 text-center p-2px pb-1px w-20" />
        }
        locale={getCurrentLocale()}
      />
      <div className="my-auto pl-1 pr-1">
        <Trans>and</Trans>
      </div>
      <DatePicker
        selected={filterSettings.dateRange.endDate}
        onChange={(date: any) =>
          setFilterSettings({
            ...filterSettings,
            dateRange: {
              ...filterSettings.dateRange,
              endDate: date,
            },
          })
        }
        customInput={
          <input className="copper-input-basic rounded text-gray-500 text-center p-2px pb-1px w-20" />
        }
        locale={getCurrentLocale()}
      />
    </>
  );
};

export default InBetweenSettings;
