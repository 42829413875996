const defaultSize = 36;
const getStrengthIcon = (device: any): string => {
  if (!device) {
    return "";
  }

  let rssi = device.wifi_rssi;
  let value = 0;

  const inRange = (min: number, max: number): boolean => {
    return rssi >= min && rssi <= max;
  };

  switch (true) {
    case device.status == "offline":
      value = 0;
      break;

    case rssi == null || rssi == 0:
      value = 9;
      break;

    case inRange(-100, -80):
      value = 1;
      break;

    case inRange(-79, -70):
      value = 2;
      break;

    case inRange(-69, -60):
      value = 3;
      break;

    case inRange(-59, 0):
      value = 4;
      break;
  }

  return `wifi-strength-${value}`;
};

const WiFiStrengthIcon: React.FC<{ device: any }> = ({ device }) => (
  <span className="flex flex-row items-center w-full">
    <img
      className=""
      width={defaultSize}
      alt="wifi-icon"
      height={defaultSize}
      src={`/img/devices/wifi/${getStrengthIcon(device)}.svg`}
    />
  </span>
);

export default WiFiStrengthIcon;
