import React from "react";
import { separate } from "../util/arrayHelper";

const UntitledDetailColumns: React.FC<{
  className?: string;
  details: Array<{
    name: string;
    value: any;
  }>;
}> = ({ className, details }) => {
  return (
    <div className={className}>
      <div className="flex flex-row space-x-8">
        {separate(
          details.map(({ name, value }, i) => (
            <DetailRow name={name} value={value} key={i} />
          )),
          separator
        )}
      </div>
    </div>
  );
};

const separator = <div className="border-l mx-2"></div>;

const DetailRow: React.FC<{
  name: string;
  value: any;
}> = ({ name, value }) => {
  return (
    <div className="flex flex-col">
      <DetailName name={name} /> <DetailValue value={value} />
    </div>
  );
};

const DetailName: React.FC<{
  name: string;
}> = ({ name }) => {
  return <span className="opacity-50 pb-7">{name}</span>;
};

const DetailValue: React.FC<{
  value: string;
}> = ({ value }) => {
  return <span className="capitalize">{value}</span>;
};

export default UntitledDetailColumns;
