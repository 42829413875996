import React, { useEffect, useState } from "react";
import countrystate from "../json/countrystate.json";
import FormInput from "./FormInput";
import FormInputError from "./FormInputError";
import { getCurrency, getZipCodeRegex } from "../util/locale";
import { useAuth } from "../services/auth-context";
import LeftLabeledContainer from "./LeftLabeledContainer";
import { t } from "@lingui/macro";

const AddressFields: React.FC<{
  label?: any;
  containerWidthClass?: any;
  register: any;
  errors: any;
  defaultAddress: any;
  defaultPhone?: string;
  hook?: any;
  setHook?: any;
  preselectedCountry?: any;
}> = ({
  label,
  containerWidthClass,
  register,
  errors,
  defaultAddress,
  defaultPhone,
  hook,
  setHook,
  preselectedCountry,
}) => {
  const { user } = useAuth();

  const [selectedCountry, setSelectedCountry] = useState(
    preselectedCountry ??
      defaultAddress?.country ??
      user?.accountData?.defaultAddress?.country ??
      "US"
  );
  const [stateOptions, setStateOptions] = useState([
    { name: "", abbreviation: "" },
  ]);

  const isSingapore = selectedCountry?.toLowerCase() === "sg";

  const [selectedState, setSelectedState] = useState(
    defaultAddress?.state || ""
  );
  const zipFormat = getZipCodeRegex(selectedCountry);

  useEffect(() => {
    if (!selectedCountry) {
      return;
    }
    const selectedCountryData = countrystate.countries.find(
      (country: any) => country.country.abbreviation === selectedCountry
    );
    if (selectedCountryData) {
      setStateOptions(selectedCountryData.states);
    }
  }, [selectedCountry]);

  const updateHook = (e: any, name: string) => {
    if (!setHook) {
      return;
    }
    setHook({
      ...hook,
      [name]: e.target.value,
    });
  };

  return (
    <>
      <LeftLabeledContainer
        name={label ?? t`Address`}
        widthClass={containerWidthClass ?? "w-24"}
      >
        <div>
          <select
            ref={register({ required: t`Country required` })}
            id="address[country]"
            name="address[country]"
            className="copper-input"
            value={selectedCountry}
            onChange={(e) => {
              updateHook(e, "country");
              const selectedCountry = e.target.value;
              setSelectedCountry(selectedCountry);
              const selectedCountryData = countrystate.countries.find(
                (country: any) =>
                  country.country.abbreviation === selectedCountry
              );
              if (selectedCountryData) {
                setStateOptions(selectedCountryData.states);
              }
            }}
          >
            {countrystate.countries.map(({ country }, i) => (
              <option
                value={country.abbreviation}
                key={i}
                disabled={
                  preselectedCountry != null &&
                  country.abbreviation != preselectedCountry
                }
              >
                {country.name}
              </option>
            ))}
          </select>
          <FormInputError error={errors?.address?.country?.message} />
        </div>

        <FormInput
          formRegisterRef={register({ required: t`Address required` })}
          id="address[line1]"
          placeholder={t`Business Address Line 1`}
          defaultValue={defaultAddress?.line1 ?? ""}
          error={errors?.address?.line1}
          onChange={(e: any) => updateHook(e, "line1")}
        />
        <FormInput
          formRegisterRef={register()}
          id="address[line2]"
          placeholder={t`Business Address Line 2`}
          defaultValue={defaultAddress?.line2 ?? ""}
          onChange={(e: any) => updateHook(e, "line2")}
        />
        <FormInput
          formRegisterRef={register({ required: t`City required` })}
          id="address[city]"
          placeholder={t`City`}
          defaultValue={isSingapore ? "Singapore" : defaultAddress?.city ?? ""}
          error={errors?.address?.city}
          hidden={isSingapore}
          onChange={(e: any) => updateHook(e, "city")}
        />
        <div hidden={isSingapore}>
          <select
            ref={register({ required: t`State required` })}
            id="address[state]"
            name="address[state]"
            className="copper-input"
            value={isSingapore ? "Singapore" : selectedState}
            onChange={(e) => {
              updateHook(e, "state");
              setSelectedState(e.target.value);
            }}
            disabled={stateOptions.length < 2}
          >
            <option value="" disabled>
              {t`Province/State`}
            </option>
            {stateOptions.map((state, i) => (
              <option value={state.abbreviation} key={i}>
                {state.name}
              </option>
            ))}
          </select>
          <FormInputError error={errors?.address?.state?.message} />
        </div>
        <FormInput
          formRegisterRef={register({
            pattern: {
              value: zipFormat,
              message: t`Incorrect Zip code format`,
            },
            required: t`Zip required`,
          })}
          id="address[postal_code]"
          placeholder={t`Postal/Zip code`}
          defaultValue={defaultAddress?.postal_code ?? ""}
          error={errors?.address?.postal_code}
          onChange={(e: any) => updateHook(e, "postal_code")}
        />
      </LeftLabeledContainer>
      <LeftLabeledContainer
        name={t`Phone`}
        widthClass={containerWidthClass ?? "w-24"}
      >
        <FormInput
          formRegisterRef={register()}
          id="phone"
          placeholder={t`Phone number`}
          defaultValue={defaultPhone ?? ""}
          onChange={(e: any) => updateHook(e, "phone")}
        />
      </LeftLabeledContainer>
      <LeftLabeledContainer
        name={t`Currency`}
        widthClass={containerWidthClass ?? "w-24"}
      >
        <input
          className="copper-input"
          disabled={true}
          placeholder={t`Currency`}
          value={getCurrency(selectedCountry)}
        ></input>
      </LeftLabeledContainer>
    </>
  );
};

export default AddressFields;
