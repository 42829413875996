import React from "react";
import Button from "./Button";
import Spinner from "./Spinner";
import { t, Trans } from "@lingui/macro";

const ModalForm: React.FC<{
  children: JSX.Element | Array<JSX.Element>;
  title?: JSX.Element | Array<JSX.Element> | string;
  isSubmitting: boolean;
  onCancel: any;
  handleSubmit: any;
  submitError: any;
  controls?: JSX.Element | Array<JSX.Element> | string;
  hideSaveButton?: boolean;
  backgroundColorClass?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  buttonClass?: string;
  isSuccess?: boolean;
  footerBtns?: Array<any>;
}> = ({
  children,
  title,
  isSubmitting,
  onCancel,
  handleSubmit,
  submitError,
  hideSaveButton,
  controls,
  backgroundColorClass,
  cancelButtonText,
  confirmButtonText,
  buttonClass,
  isSuccess,
  footerBtns,
}) => {
  return (
    <div className="flex flex-col">
      <form className="space-y-6" onSubmit={handleSubmit}>
        {(title || controls) && (
          <div className={`${!!controls && "flex justify-between"} pb-5 -mb-6`}>
            <div className="text-responsive-lg font-medium">{title}</div>
            {!!controls && <div>{controls}</div>}
          </div>
        )}
        <fieldset disabled={isSubmitting}>
          <div
            className={`space-y-6 ${
              !!backgroundColorClass ? backgroundColorClass : "bg-form"
            } -mx-4 p-4`}
          >
            <div className="flex flex-col space-y-6">{children}</div>
          </div>
        </fieldset>
        <div className="flex justify-end">
          {isSuccess ? (
            <div className="w-full flex justify-start">
              <span className="label-success my-auto">
                <Trans>Success!</Trans>
              </span>
            </div>
          ) : (
            <div className="w-full flex justify-start">
              {!!submitError && (
                <span className="label-error my-auto">{submitError}</span>
              )}
            </div>
          )}
          {footerBtns?.map((b, i) => (
            <Button key={i} className="mr-1" onClick={b.onClick}>
              {b.label}
            </Button>
          ))}
          <Button onClick={onCancel} disabled={isSubmitting}>
            {!!cancelButtonText ? cancelButtonText : t`Cancel`}
          </Button>
          {!hideSaveButton && (
            <button
              type="submit"
              className={`${
                !!buttonClass ? buttonClass : "copper-button-confirm"
              } ${
                isSubmitting && "text-transparent"
              } ml-2 flex justify-center bg-color-red h-10`}
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <div className="absolute flex align-center justify-center mx-auto -my-0.5 px-1 md:my-0 md:mt-1 md:px-1.5">
                  <Spinner size={16} color="white"></Spinner>
                </div>
              )}
              {!!confirmButtonText ? confirmButtonText : t`Save`}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ModalForm;
