import Amplify, { Auth } from "aws-amplify";
const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_API_ENDPOINT,
  REACT_APP_IMAGES_BUCKET_NAME,
  REACT_APP_AWS_IDENTITY_POOL_ID,
} = process.env;

const initAws = () => {
  Amplify.configure({
    Auth: {
      region: REACT_APP_COGNITO_REGION,
      userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
      identityPoolId: REACT_APP_AWS_IDENTITY_POOL_ID,
    },
    API: {
      endpoints: [
        {
          name: "Conduit",
          endpoint: REACT_APP_API_ENDPOINT,
          custom_header: async () => {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            let headers: { [index: string]: string } = {
              authorization: `Bearer ${token}`,
            };
            const { copperAccountOverride } = localStorage;
            if (copperAccountOverride && !("copper-account" in headers)) {
              headers["copper-account"] = copperAccountOverride;
            }
            return headers;
          },
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: REACT_APP_IMAGES_BUCKET_NAME,
        region: "us-west-2",
      },
    },
  });
};

export default initAws;
