import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Conduit from "../../services/Conduit";
import removeEmptyObjectProps from "../../util/removeEmptyObjectProps";
import Modal from "../Modal";
import ModalForm from "../ModalForm";
import { t, Trans } from "@lingui/macro";

const NewUserModal: React.FC<{
  onCancel: any;
  onSubmitSuccess?: any;
  onClickOutside?: any;
  visible: boolean;
  merchantId?: string;
  hideRoleLocation?: boolean;
}> = ({
  onCancel,
  onSubmitSuccess,
  onClickOutside,
  visible,
  merchantId,
  hideRoleLocation,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");
  const [locations, setLocations] = useState<any[] | any>([]);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    API.get("Conduit", "/locations", {
      headers: { "copper-account": merchantId },
      queryStringParameters: { limit: 100 },
    }).then((locations: any) => {
      if (locations.data) {
        setLocations(
          locations.data.map((l: any) => ({ id: l.id, name: l.display_name }))
        );
      }
    });
  }, []);

  const onFormSubmit = (formData: any) => {
    setIsSubmitting(true);

    if (isOwner || hideRoleLocation) {
      formData.role = "";
      formData.location = "";
    }

    Conduit.usersAccount
      .newUser(merchantId, { ...removeEmptyObjectProps(formData) })
      .then(() => {
        onSubmitSuccess();
      })
      .catch((err) => {
        setRequestError(err?.response?.data?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onModalCancel = () => {
    onCancel();
    setRequestError("");
    setIsSubmitting(false);
  };

  const formErrorDisplay = (err: any) =>
    err &&
    err.type === "required" && (
      <div className="label-error-sm pl-1.5 label-error -mb-4 md:-mb-3">
        {err.message}
      </div>
    );
  return (
    <Modal onClickOutside={onClickOutside} visible={visible}>
      <ModalForm
        title={t`New User`}
        isSubmitting={isSubmitting}
        onCancel={onModalCancel}
        handleSubmit={handleSubmit(onFormSubmit)}
        submitError={requestError}
        confirmButtonText={t`Send Invite`}
        buttonClass="copper-button-confirm w-48"
      >
        <div className="flex flex-col">
          <div className="flex flex-col space-y-6 pr-7 -mt-3">
            <label className="text-responsive-base font-medium py-2">
              <Trans>Name</Trans>
            </label>
          </div>
          <div className="w-full">
            <input
              ref={register({ required: t`Name is required` })}
              id="name"
              name="name"
              className="copper-input"
              placeholder=""
            />
            {formErrorDisplay(errors?.name)}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row pr-7">
            <label className="text-responsive-base font-medium py-2">
              <Trans>Email</Trans>
            </label>
          </div>
          <div className="w-full">
            <input
              ref={register({ required: t`Email is required` })}
              id="email"
              name="email"
              className="copper-input"
            />
            {formErrorDisplay(errors?.email)}
          </div>
        </div>
        {hideRoleLocation ? (
          <></>
        ) : (
          <div className="flex flex-col">
            <div className="flex flex-row pr-7">
              <label className="text-responsive-base font-medium py-2">
                <Trans>Role</Trans>
              </label>
            </div>
            <div className="w-full">
              <select
                ref={register}
                id="role"
                name="role"
                className="copper-input"
                onChange={(e) => setIsOwner(e.target.value == "owner")}
              >
                <option value="employee">{t`Employee`}</option>
                <option value="manager">{t`Manager`}</option>
                <option value="owner">{t`Owner`}</option>
              </select>
            </div>
          </div>
        )}

        {isOwner || hideRoleLocation ? (
          <></>
        ) : (
          <div className={"flex flex-col"}>
            <div className="flex flex-row pr-7">
              <label className="text-responsive-base font-medium py-2">
                <Trans>Location</Trans>
              </label>
            </div>
            <div className="w-full">
              <select
                ref={register}
                id="location"
                name="location"
                className="copper-input"
              >
                {locations.map((location: any) => (
                  <option value={location.id} key={location.id}>
                    {location.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </ModalForm>
    </Modal>
  );
};

export default NewUserModal;
