import React, { useState } from "react";
import Toggle from "../../Toggle";
import { Trans } from "@lingui/macro";

const SplitSettingsComponent: React.FC<{
  isDisabled?: boolean;
  register: any;
  location?: any;
  setValue: any;
  isHide?: boolean;
}> = ({ location, setValue, register, isHide, isDisabled = false }) => {
  const { settings } = location;

  const [splitsEnabled, setSplitsEnabled] = useState(
    settings?.splits?.enabled ?? false
  );

  return isHide !== true ? (
    <div className="space-y-3">
      <div>
        <div className="flex items-center h-8">
          <Toggle
            onChange={(value: boolean) => {
              const newValue = !splitsEnabled;
              setSplitsEnabled(newValue);
              setValue("splits_enabled", newValue);
            }}
            value={splitsEnabled}
          />
          <input
            id="splits_enabled"
            name="splits_enabled"
            value={splitsEnabled}
            ref={register()}
            type={"hidden"}
          />
          <span className="w-full text-lg pl-4 text-gray-900">
            <Trans>Enable split payments</Trans>
          </span>
          {splitsEnabled && (
            <div className="flex items-center align-middle">
              <span className="text-sm w-20 fsont-medium text-gray-900">
                <Trans>Max splits:</Trans>
              </span>
              <input
                ref={register()}
                id="max_splits"
                name="max_splits"
                className="w-12 copper-input"
                defaultValue={settings.splits?.max ?? 5}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default React.memo(SplitSettingsComponent);
