import { API } from "aws-amplify";
import React, { useState, useCallback, useEffect } from "react";
import { t, Trans } from "@lingui/macro";

function maskKey(key: string) {
  let sectionLength = key.length / 3;
  if (sectionLength > 12) {
    sectionLength = 12;
  }
  if (sectionLength < 4) {
    sectionLength = 4;
  }

  return key.slice(0, sectionLength) + "[...]" + key.slice(-sectionLength);
}
const StripeResellerComponent: React.FC<{
  isConnected?: boolean;
  isReseller: boolean;
  register: any;
  setValue: any;
  location?: any;
}> = ({ location, isConnected, isReseller, setValue, register }) => {
  const [secretKeys, setSecretKeys] = useState<any>(null);
  const [publishableKeys, setPublishableKeys] = useState<any>(null);
  const [selectedSecretKey, setSelectedSecretKey] = useState(
    location.settings?.stripe_payments?.api_secret_key ?? ""
  );
  const [selectedPublishableKey, setSelectedPublishableKey] = useState(
    location.settings?.stripe_payments?.publishable_key ?? ""
  );

  const fetchKeys = useCallback(() => {
    const endpoint = `/stripe/keys`;
    API.get("Conduit", endpoint, {}).then((kys) => {
      setSecretKeys(Array.from(new Set(kys[0])));
      setPublishableKeys(Array.from(new Set(kys[1])));
    });
  }, []);

  useEffect(() => fetchKeys(), [fetchKeys]);
  return (
    <div>
      <div>
        <label className="block text-base py-1 text-gray-700">
          <Trans>Reseller Platform Secret Key</Trans>
        </label>
        <input
          id="api-secret-key"
          name="api_secret_key"
          value={selectedSecretKey}
          ref={register()}
          type={"hidden"}
        />
        {secretKeys?.length > 0 && (
          <div className="flex items-center pt-2">
            <input
              type="radio"
              id="api_secret_key-existing"
              name="api_secret_key_selector"
              value="checkout"
              defaultChecked={selectedSecretKey}
              disabled={isConnected}
            />
            <span className="text-sm fsont-medium pl-4 text-gray-900 w-32">
              <Trans>Existing key</Trans>
            </span>
            <select
              className="copper-input-dropdown p-1"
              disabled={isConnected}
              value={selectedSecretKey}
              onChange={(e) => {
                setValue("api_secret_key", e.target.value);
                setSelectedSecretKey(e.target.value);
              }}
            >
              <option value="">[{t`Select a key`}]</option>
              {secretKeys &&
                secretKeys.map((key: any) => (
                  <option key={key} value={key}>
                    {maskKey(key)}
                  </option>
                ))}
            </select>
          </div>
        )}
        <div className="flex items-center pt-1">
          <input
            type="radio"
            id="api_secret_key-new"
            name="api_secret_key_selector"
            value="redirect"
            disabled={isConnected}
            defaultChecked={!selectedSecretKey}
          />
          <span className="text-sm fsont-medium pl-4 text-gray-900 w-32">
            <Trans>New key</Trans>
          </span>
          <input
            className="copper-input p-1"
            disabled={isConnected}
            onChange={(e) => {
              setValue("api_secret_key", e.target.value);
              setSelectedSecretKey(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="my-4">
        <label className="block text-base py-1 text-gray-700">
          <Trans>Reseller Platform Publishable Key</Trans>
        </label>
        <input
          id="publishable-key"
          name="publishable_key"
          value={selectedPublishableKey}
          ref={register()}
          type={"hidden"}
        />
        {publishableKeys?.length > 0 && (
          <div className="flex items-center pt-2">
            <input
              type="radio"
              id="publishable_key-existing"
              name="publishable_key_selector"
              value="checkout"
              defaultChecked={selectedPublishableKey}
              disabled={isConnected}
            />
            <span className="text-sm fsont-medium pl-4 text-gray-900 w-32">
              <Trans>Existing key</Trans>
            </span>
            <select
              className="copper-input-dropdown p-1"
              disabled={isConnected}
              value={selectedPublishableKey}
              onChange={(e) => {
                setValue("publishable_key", e.target.value);
                setSelectedPublishableKey(e.target.value);
              }}
            >
              <option value="">[{t`Select a key`}]</option>
              {publishableKeys &&
                publishableKeys.map((key: any) => (
                  <option key={key} value={key}>
                    {maskKey(key)}
                  </option>
                ))}
            </select>
          </div>
        )}
        <div className="flex items-center pt-1">
          <input
            type="radio"
            id="publishable_key-new"
            name="publishable_key_selector"
            value="redirect"
            disabled={isConnected}
            defaultChecked={!selectedPublishableKey}
          />
          <span className="text-sm fsont-medium pl-4 text-gray-900 w-32">
            <Trans>New key</Trans>
          </span>
          <input
            className="copper-input p-1"
            disabled={isConnected}
            onChange={(e) => {
              setValue("publishable_key", e.target.value);
              setSelectedPublishableKey(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(StripeResellerComponent);
