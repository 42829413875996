import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Button from "../components/Button";
import CenteredErrorLabel from "../components/CenteredErrorLabel";
import DataContainer from "../components/DataContainer";
import DetailsSection from "../components/DetailsSection";
import DeviceList from "../components/lists/DeviceList";
import MainHeader from "../components/MainHeader";
import BrandingLocationModal from "../components/modals/BrandingLocationModal";
import LocationModal from "../components/modals/LocationModal";
import NewDeviceModal from "../components/modals/NewDeviceModal";
import PaymentSettingsModal from "../components/modals/PaymentSettings";
import ResourceHeader from "../components/ResourceHeader";
import ResourceId from "../components/ResourceId";
import Spinner from "../components/Spinner";
import { useAuth } from "../services/auth-context";
import Main from "./containers/Main";
import { CordSettingsModal } from "../components/modals/PaymentSettings/CordSettingsModal";
import { t, Trans } from "@lingui/macro";
import { statusName } from "../components/utils/util";
import { Breadcrumbs } from "../components/Breadcrumbs";
import NomnieModal from "../components/modals/NomnieModal";

type Props = {
  id?: string;
  merchantId?: string;
};

const Location: React.FC<RouteComponentProps<Props>> = (
  props: RouteComponentProps<Props>
) => {
  const { params } = props.match;
  const { user, isAdmin } = useAuth();
  const accountId = user!.account;
  const locationId = params!.id!;

  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [isCreatingDevice, setIsCreatingDevice] = useState(false);
  const [location, setLocation] = useState<any[] | any>(null);
  const [devices, setDevices] = useState<any[] | any>([]);
  const [showingQRSettings, setShowingQRSettings] = useState(false);
  const [showingNomnieSettings, setShowingNomnieSettings] = useState(false);
  const [showingPaymentSettings, setShowingPaymentSettings] = useState(false);
  const [showingBrandingLocation, setShowingBrandingLocation] = useState(false);
  const [devicesError] = useState("");

  const merchantId = params.merchantId || location?.account || accountId;

  useEffect(() => {
    getLocation(locationId, merchantId, setLocation);
    getDevices(locationId, merchantId, setDevices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (!location) {
    return (
      <Main>
        <div className="flex justify-center p-responsive">
          <Spinner size={20} color="gray"></Spinner>
        </div>
      </Main>
    );
  }

  const onModalCancel = () => {
    setIsEditingLocation(false);
    setIsCreatingDevice(false);
    setShowingPaymentSettings(false);
    setShowingBrandingLocation(false);
    setShowingQRSettings(false);
    setShowingNomnieSettings(false);
  };
  const onEditLocationSubmit = () => {
    setIsEditingLocation(false);
    getLocation(locationId, merchantId, setLocation);
    getDevices(locationId, merchantId, setDevices);
  };
  const editLocationModal = (
    <LocationModal
      location={location}
      visible={isEditingLocation}
      onSubmitSuccess={onEditLocationSubmit}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      removeBlankFormValues={!isEditingLocation}
      merchantId={merchantId}
      devices={devices}
      preselectedCountry={location?.address?.country}
    />
  );

  const updateDeviceList = () => {
    getDevices(locationId, merchantId, setDevices);
  };
  const onNewDeviceSubmit = () => {
    setIsCreatingDevice(false);
    updateDeviceList();
  };
  const newDeviceModal = (
    <NewDeviceModal
      locationId={location.id}
      visible={isCreatingDevice}
      onSubmitSuccess={onNewDeviceSubmit}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      merchantId={merchantId}
    />
  );

  const paymentSettingsModal = (
    <PaymentSettingsModal
      visible={showingPaymentSettings}
      location={location}
      merchantId={merchantId}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      updateLocation={() => getLocation(locationId, merchantId, setLocation)}
    ></PaymentSettingsModal>
  );

  const cordSettingsModal = (
    <CordSettingsModal
      visible={showingQRSettings}
      location={location}
      devices={devices}
      merchantId={merchantId}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      updateLocation={() => getLocation(locationId, merchantId, setLocation)}
    ></CordSettingsModal>
  );

  const nomnieModal = (
    <NomnieModal
      location={location}
      devices={devices}
      merchantId={merchantId}
      onCancel={onModalCancel}
      visible={showingNomnieSettings}
    ></NomnieModal>
  );

  const brandingLocationModal = (
    <BrandingLocationModal
      visible={showingBrandingLocation}
      onCancel={onModalCancel}
      onClickOutside={onModalCancel}
      onSubmitSuccess={() => setShowingBrandingLocation(false)}
      merchantId={merchantId}
      location={location}
      updateLocation={() => getLocation(locationId, merchantId, setLocation)}
    />
  );

  return (
    <Main>
      <Breadcrumbs
        reseller={isAdmin && location.reseller}
        merchant={location.account}
        self={location.display_name}
      ></Breadcrumbs>
      <div className="space-y-4 md:space-y-8">
        {buildHeader(
          location,
          setShowingPaymentSettings,
          setShowingBrandingLocation,
          setShowingQRSettings,
          setShowingNomnieSettings
        )}
        {buildDetails(location, setIsEditingLocation)}
        <div>
          <DataContainer
            title={t`Devices`}
            titleClass="text-responsive-lg my-auto"
            controls={
              <Button
                onClick={() => setIsCreatingDevice(true)}
                icon="ion-android-add"
              >
                <Trans>New</Trans>
              </Button>
            }
            removeInnerPadding={true}
          >
            {!!devicesError ? (
              <CenteredErrorLabel message={devicesError} />
            ) : (
              <DeviceList
                noItemsHeader={t`No devices registered`}
                noItemsBody={t`Devices must be registered to this location before being visible here.`}
                devices={devices}
                onDeviceEdited={updateDeviceList}
                onDeviceRemoved={updateDeviceList}
                merchantId={merchantId}
                locations={[location]}
              />
            )}
          </DataContainer>
        </div>
      </div>
      {editLocationModal}
      {newDeviceModal}
      {paymentSettingsModal}
      {cordSettingsModal}
      {nomnieModal}
      {brandingLocationModal}
    </Main>
  );
};

const buildHeader = (
  location: any,
  setShowingPaymentSettings: any,
  setShowingBrandingLocation: any,
  setShowingQRSettings: any,
  setShowingNomnieSettings: any
) => (
  <div className="border rounded-md p-responsive">
    <div className="flex justify-between mb-6 flex-row">
      <ResourceHeader>
        <Trans>Location</Trans>
      </ResourceHeader>
      <ResourceId>{location.id}</ResourceId>
    </div>
    <div className="flex flex-row justify-between">
      <MainHeader>{location.display_name}</MainHeader>
      <div className="flex flex-row justify-between gap-4">
        <Button
          icon="ion-ribbon-b"
          onClick={() => setShowingBrandingLocation(true)}
        >
          <Trans>Branding</Trans>
        </Button>
        <Button onClick={() => setShowingPaymentSettings(true)} icon="ion-card">
          <Trans>Payment Settings</Trans>
        </Button>
        <Button icon="ion-settings" onClick={() => setShowingQRSettings(true)}>
          <Trans>Cord Settings</Trans>
        </Button>
        <Button
          icon="ion-settings"
          onClick={() => setShowingNomnieSettings(true)}
        >
          <Trans>Nomnie Settings</Trans>
        </Button>
      </div>
    </div>
    <div className={"text-sm"}>{statusName(location.status)}</div>
  </div>
);

const buildDetails = (location: any, setIsEditingLocation: any) => (
  <DetailsSection
    nameDescription={t`Location Name`}
    name={location.display_name}
    address={location.address}
    onEditClicked={() => setIsEditingLocation(true)}
  />
);

const getLocation = (
  locationId: string,
  merchantId: string,
  setLocation: Function
) =>
  API.get("Conduit", `/locations/${locationId}`, {
    headers: { "copper-account": merchantId },
  }).then((location) => setLocation(location));

const getDevices = (
  locationId: string,
  merchantId: string,
  setDevices: Function
) =>
  API.get("Conduit", `/devices?location=${locationId}&include[]=wifi_rssi`, {
    headers: { "copper-account": merchantId },
    queryStringParameters: { limit: 100 },
  }).then((devices) => setDevices(devices.data));

export default Location;
