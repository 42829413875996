import React, { useState } from "react";
import Toggle from "../../Toggle";
import LeftLabeledContainer from "../../LeftLabeledContainer";
import { t, Trans } from "@lingui/macro";
import { checkIcon, copyIcon, openUrlIcon } from "./QRUrlImage";
import { isLocalDevEnv } from "../../utils/util";

const CordSettingsComponent: React.FC<{
  isDisabled?: boolean;
  register: any;
  location?: any;
  devices: any[];
  setValue: any;
  isHide?: boolean;
}> = ({
  location,
  devices,
  setValue,
  register,
  isHide,
  isDisabled = false,
}) => {
  const { settings } = location;
  const { qr_payments, qr_promotions, nomnie } = settings;
  const [autoReboot, setAutoReboot] = useState(
    location?.settings?.auto_reboot ?? false
  );
  const [preferredPrinter, setPreferredPrinter] = useState(
    location?.settings?.preferred_printer
  );
  const [togglePaymentsEnabled, setTogglePaymentsEnabled] = useState(
    qr_payments?.enabled
  );

  const [togglePromotionsEnabled, setTogglePromotionsEnabled] = useState(
    qr_promotions?.enabled
  );

  const [gateway, setGateway] = useState(qr_payments?.gateway);
  const [redirectURL, setRedirectURL] = useState(
    qr_payments?.redirect_url ?? ""
  );

  const [canEditRedirectURL, setCanEditRedirectURL] = useState(
    !!qr_payments.redirect_url
  );

  return isHide !== true ? (
    <div>
      <div className="flex items-center">
        <Toggle
          onChange={() => {
            const newValue = !togglePaymentsEnabled;
            setTogglePaymentsEnabled(newValue);
            setValue("qr_payments_enabled", newValue);
          }}
          value={togglePaymentsEnabled}
        />
        <span className="text-sm font-medium pl-4 text-gray-900">
          <Trans>Inject QR code on bills from POS</Trans>
        </span>
        <input
          id="qr_payments-enabled"
          name="qr_payments_enabled"
          value={togglePaymentsEnabled}
          ref={register()}
          type={"hidden"}
        />
      </div>
      <div className={"ml-10 " + (togglePaymentsEnabled ? "" : "opacity-50")}>
        <div className={"mt-4"}>
          <LeftLabeledContainer name={t`Before QR`}>
            <textarea
              disabled={!togglePaymentsEnabled}
              cols={50}
              rows={2}
              id="payments-intro"
              name="payments_intro"
              className={`copper-input text-responsive-xs max-h-96`}
              defaultValue={qr_payments?.intro ?? ""}
              placeholder={t`Pay this check with your phone\r\nPowered by Copper`}
              ref={register}
            />
          </LeftLabeledContainer>
        </div>
        <div className={"ml-20"}>
          <div className={"flex items-center pt-5 "}>
            <input
              disabled={!togglePaymentsEnabled}
              ref={register}
              type="radio"
              id="qr_method-checkout"
              name="qr_method"
              value="checkout"
              defaultChecked={!qr_payments?.redirect_url}
              onClick={(e) => {
                setValue("qr_method", "checkout");
              }}
              onChange={(e) => {
                setCanEditRedirectURL(!e.target.checked);
              }}
            />
            <span className="text-sm font-medium pl-4 text-gray-900 w-80">
              <span className={"float-left"}>
                <Trans>Copper payments</Trans>
              </span>
              {!gateway && (
                <span className="has-tooltip float-left  ml-2">
                  <span className="tooltip rounded shadow-lg z-[2147483647] bg-white p-2 border border-gray-100 text-base mt-5  ">
                    <Trans>
                      Please configure and select a payment gateway in Payment
                      Settings first
                    </Trans>
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon w-5 text-gray-light"
                    fill="red"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" />
                  </svg>
                </span>
              )}
            </span>
          </div>

          <div className={"flex items-center pt-5"}>
            <input
              disabled={!togglePaymentsEnabled}
              ref={register}
              type="radio"
              id="qr_method-redirect"
              name="qr_method"
              value="redirect"
              defaultChecked={qr_payments?.redirect_url}
              onClick={(e) => {
                setValue("qr_method", "redirect");
              }}
              onChange={(e) => {
                setCanEditRedirectURL(e.target.checked);
              }}
            />
            <span className="text-sm font-medium pl-4 text-gray-900 w-32">
              <Trans>Custom URL</Trans>
            </span>
            <input
              disabled={!canEditRedirectURL}
              ref={register()}
              id="redirect-url"
              name="redirect_url"
              className="copper-input"
              value={redirectURL}
              onChange={(e) => {
                setRedirectURL(e.target.value);
                setValue("redirect_url", e.target.value);
              }}
            />
          </div>
        </div>
        <div className={"mt-4"}>
          <LeftLabeledContainer name={t`After QR`}>
            <textarea
              disabled={!togglePaymentsEnabled}
              cols={50}
              rows={2}
              id="payments-outro"
              name="payments_outro"
              className={`copper-input text-responsive-xs max-h-96`}
              defaultValue={qr_payments?.outro ?? ""}
              placeholder={t`No app or signup required\r\nSimply scan the QR with your camera\r\nor open your browser and go to cop.pe`}
              ref={register}
            />
          </LeftLabeledContainer>
        </div>
      </div>

      <div className="flex items-center mt-5">
        <Toggle
          onChange={() => {
            const newValue = !togglePromotionsEnabled;
            setTogglePromotionsEnabled(newValue);
            setValue("qr_promotions_enabled", newValue);
          }}
          value={togglePromotionsEnabled}
        />
        <span className="text-sm font-medium pl-4 text-gray-900">
          <Trans>Inject QR code on receipts from POS</Trans>
        </span>
        <input
          id="qr_promotions-enabled"
          name="qr_promotions_enabled"
          value={togglePromotionsEnabled}
          ref={register()}
          type={"hidden"}
        />
      </div>
      <div className={"ml-10 " + (togglePromotionsEnabled ? "" : "opacity-50")}>
        <div className={"mt-4"}>
          <LeftLabeledContainer name={t`Before QR`}>
            <textarea
              disabled={!togglePromotionsEnabled}
              cols={50}
              rows={2}
              id="promotions-intro"
              name="promotions_intro"
              className={`copper-input text-responsive-xs max-h-96`}
              defaultValue={qr_promotions?.intro ?? ""}
              placeholder={t`Get more benefits`}
              ref={register}
            />
          </LeftLabeledContainer>
        </div>
        <div className={"mt-4"}>
          <LeftLabeledContainer name={t`Custom URL`}>
            <input
              disabled={!togglePromotionsEnabled}
              id="promotions-redirect-url"
              name="promotions_redirect_url"
              className={`copper-input`}
              defaultValue={qr_promotions?.redirect_url ?? ""}
              ref={register}
            />
          </LeftLabeledContainer>
        </div>

        <div className={"mt-4"}>
          <LeftLabeledContainer name={t`After QR`}>
            <textarea
              disabled={!togglePromotionsEnabled}
              cols={50}
              rows={2}
              id="promotions-outro"
              name="promotions_outro"
              className={`copper-input text-responsive-xs max-h-96`}
              defaultValue={qr_promotions?.outro ?? ""}
              placeholder={t`Scan the QR with your camera`}
              ref={register}
            />
          </LeftLabeledContainer>
        </div>
      </div>

      <div className="flex items-center mt-5">
        <Toggle
          onChange={() => {
            const newValue = !autoReboot;
            setAutoReboot(newValue);
            setValue("auto_reboot", newValue);
          }}
          value={autoReboot}
        />
        <span className="text-sm font-medium pl-4 text-gray-900">
          <Trans>
            Enable the Cords to reboot and update automatically when a new
            firmware version is available
          </Trans>
        </span>

        <input
          ref={register}
          hidden={true}
          id="auto_reboot"
          name="auto_reboot"
          readOnly={true}
          value={autoReboot}
        />
      </div>

      <div className="flex items-center mt-5">
        <span
          className={"text-responsive-base font-medium py-2 mr-3  flex-grow"}
        >{t`Printer for payment confirmations`}</span>
        <div>
          <select
            ref={register()}
            id="preferred_printer"
            name="preferred_printer"
            className="copper-input"
            value={preferredPrinter}
            onChange={(e) => {
              setPreferredPrinter(e.target.value);
              setValue("preferred_printer", e.target.value);
            }}
          >
            <option value="">
              {t`Same as the one to which the check is printed`}
            </option>
            {devices
              .filter((device) => device.device_type === "copper_Cord")
              .map((device) => (
                <option value={device.serial_number} key={device.id}>
                  {device.label || device.serial_number}
                </option>
              ))}
          </select>
        </div>
      </div>
      {/*</LeftLabeledContainer>*/}
    </div>
  ) : null;
};

export default React.memo(CordSettingsComponent);
