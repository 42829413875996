import React from "react";

const ResourceHeader: React.FC = ({ children }) => {
  return (
    <h2 className="text-gray-400 font-medium text-12px uppercase">
      {children}
    </h2>
  );
};

export default ResourceHeader;
