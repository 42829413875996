import React from "react";
import { useHistory } from "react-router-dom";
import AuthBackdrop from "../../components/auth/AuthBackdrop";
import { Trans } from "@lingui/macro";

const ResetSuccess: React.FC = () => {
  const history = useHistory();

  const onContinue = () => history.push({ pathname: "/" });

  return (
    <AuthBackdrop>
      <>
        <p className="text-gray-700 text-responsive-xl">
          <Trans>You`ve successfully changed your password</Trans>
        </p>

        <div className="pt-10">
          <button className="copper-button-confirm-full" onClick={onContinue}>
            <Trans>Continue to Login</Trans>
          </button>
        </div>
      </>
    </AuthBackdrop>
  );
};

export default ResetSuccess;
