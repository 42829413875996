import React from "react";

const FormInputError: React.FC<{ error: string | undefined }> = ({ error }) => {
  if (!error) {
    return <></>;
  }
  return (
    <div className="label-error-sm pl-1.5 label-error -mb-4 md:-mb-3">
      {error}
    </div>
  );
};

export default FormInputError;
