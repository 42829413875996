import React, { useState } from "react";
import AddressFields from "./AddressFields";
import FormInput from "./FormInput";
import LeftLabeledContainer from "./LeftLabeledContainer";
import { t } from "@lingui/macro";

const NameAddressForm: React.FC<{
  register: any;
  errors: any;
  display_name?: string;
  name?: string;
  email?: string;
  livenUID?: string;
  taxID?: string;
  address: any;
  phone?: string;
  usesDisplayName?: boolean;
  showEmail?: boolean;
  showLivenUID?: boolean;
  showTaxID?: boolean;
  nameLabel?: string;
  addressLabel?: string;
  preselectedCountry?: any;
  showStatus?: boolean;
  status?: string;
  statusChangeWarning?: string;
  statusLockedMessage?: string | null;
}> = ({
  register,
  errors,
  display_name,
  name,
  email,
  livenUID,
  taxID,
  address,
  phone,
  usesDisplayName,
  showEmail,
  showLivenUID,
  showTaxID,
  nameLabel,
  addressLabel,
  preselectedCountry,
  showStatus,
  status,
  statusChangeWarning,
  statusLockedMessage,
}) => {
  const [newStatus, setNewStatus] = useState(status);
  return (
    <>
      <LeftLabeledContainer name={nameLabel || t`Name`}>
        {usesDisplayName ? (
          <FormInput
            formRegisterRef={register({ required: t`Name required` })}
            id="display_name"
            defaultValue={display_name ?? ""}
            error={errors?.display_name}
          />
        ) : (
          <FormInput
            formRegisterRef={register({ required: t`Name required` })}
            id="name"
            defaultValue={name ?? ""}
            error={errors?.name}
          />
        )}
      </LeftLabeledContainer>
      {showStatus && (
        <LeftLabeledContainer name={t`Status`}>
          {statusLockedMessage ? (
            <div>
              <select disabled={true} ref={register()} className="copper-input">
                <option value="" disabled={true} selected={true}>
                  {statusLockedMessage}
                </option>
              </select>
            </div>
          ) : (
            <div>
              <select
                ref={register()}
                id="status"
                name="status"
                className="copper-input"
                value={newStatus}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setNewStatus(newValue);
                }}
              >
                <option value="active">{t`Active`}</option>
                <option value="inactive">{t`Inactive`}</option>
                <option value="disabled">{t`Disabled`}</option>
              </select>
              {newStatus !== status && newStatus !== "inactive" && (
                <div>
                  <span className={"pl-1 text-red-500 text-sm"}>
                    {statusChangeWarning}
                  </span>
                  {"   "}
                  <a
                    className={"text-sm text-blue-400 cursor-pointer"}
                    onClick={() => {
                      setNewStatus(status);
                    }}
                  >
                    Cancel
                  </a>
                </div>
              )}
            </div>
          )}
        </LeftLabeledContainer>
      )}
      {showLivenUID && (
        <LeftLabeledContainer name={t`Liven GID`}>
          <FormInput
            placeholder={t`Verified Liven Group ID`}
            formRegisterRef={register()}
            id="settings[verified_liven_group_id]"
            defaultValue={livenUID ?? ""}
          />
        </LeftLabeledContainer>
      )}

      {showTaxID && (
        <LeftLabeledContainer
          name={preselectedCountry === "AU" ? "ABN" : t`Tax ID`}
        >
          {" "}
          <FormInput
            formRegisterRef={register()}
            id="settings[tax_id]"
            defaultValue={taxID ?? ""}
          />
        </LeftLabeledContainer>
      )}

      {showEmail && (
        <LeftLabeledContainer name={t`Email`}>
          <FormInput
            formRegisterRef={register({ required: t`Email required` })}
            id="email"
            defaultValue={email ?? ""}
            error={errors?.email}
          />
        </LeftLabeledContainer>
      )}
      <AddressFields
        {...{
          register,
          errors,
          defaultAddress: address,
          defaultPhone: phone,
          preselectedCountry: preselectedCountry,
        }}
      />
    </>
  );
};

export default NameAddressForm;
