import { API, Auth } from "aws-amplify";
import React, { createContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

type authContextType = {
  user: authUserType | null;
  isReseller: boolean;
  isAdmin: boolean;
  isLoggedIn: boolean | undefined;
  signOut: Function;
  signIn: Function;
  initPassword: Function;
  isOverrideUser: boolean;
  overrideUser: Function;
  resetUser: Function;
  loadUser: Function;
  role?: string;
  isOwner: boolean;
  isEmployee: boolean;
  location?: string;
};

interface userAttributesType {
  "custom:account": string;
  "custom:role"?: string;
  "custom:location"?: string;
  email: string;
  email_verified: boolean;
  name: string;
  sub?: string;
}

interface authUserType {
  attributes: userAttributesType;
  account: string;
  accountData: any;
  id: string;
  username: string;
  challengeParam?: any;
}

const AuthContext = createContext<authContextType>({} as authContextType);

const AuthProvider = (props: any) => {
  const [user, setUser] = useState<authUserType | null>(null);
  const [isOverrideUser, setIsOverrideUser] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const history = useHistory();

  const loadUser = async () => {
    const data = await Auth.currentUserInfo();

    if (!data) {
      delete localStorage.copperAccountOverride;
      setIsLoggedIn(false);
      return;
    }

    const account =
      localStorage.copperAccountOverride || data.attributes["custom:account"];

    const accountData = await API.get("Conduit", `/accounts/${account}`, {});

    setUser({
      ...data,
      account,
      accountData,
    });

    setIsLoggedIn(true);
    setIsOverrideUser("copperAccountOverride" in localStorage);
  };

  useEffect(() => {
    try {
      loadUser();
    } catch (err) {
      delete localStorage.copperAccountOverride;
    }
  }, []);

  const overrideUser = async (accountId: string) => {
    setIsOverrideUser(true);
    localStorage.copperAccountOverride = accountId;
    const accountData = await API.get("Conduit", `/accounts/${accountId}`, {});
    const overrideUser = { ...user, account: accountId, accountData };
    setUser(overrideUser as authUserType);
    history.push("/");
  };

  const resetUser = async () => {
    setIsOverrideUser(false);
    delete localStorage.copperAccountOverride;
    const account = user!.attributes["custom:account"];
    const accountData = await API.get("Conduit", `/accounts/${account}`, {});
    const originalUser = { ...user, account, accountData };
    setUser(originalUser as authUserType);
    history.push("/");
  };

  const isAdmin = () => user?.account.split("_")[0] === "adm";
  const isReseller = () => user?.account.split("_")[0] === "var";

  const signOut = async () => {
    await Auth.signOut();
    delete localStorage.copperAccountOverride;
    setUser(null);
    setIsLoggedIn(false);
    return <Redirect to={{ pathname: "/login" }} />;
  };

  const signIn = async (email: string, passoword: string, redirect?: any) => {
    delete localStorage.copperAccountOverride;
    return await Auth.signIn(email, passoword).then((user) => {
      const attributes = user.attributes || user.challengeParam.userAttributes;
      user.attributes = attributes;
      user.account = attributes["custom:account"];
      setUser(user);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        redirect && redirect();
      } else {
        setIsLoggedIn(true);
        loadUser();
      }
      return user;
    });
  };

  const initPassword = async (password: string) => {
    return Auth.completeNewPassword(user, password)
      .then((resp) => {
        const attributes = resp.challengeParam.userAttributes;
        setUser({
          ...user,
          attributes,
          account: attributes["custom:account"],
        } as authUserType);
        setIsLoggedIn(true);
        loadUser();
        return resp;
      })
      .catch((error) => {
        throw error;
      });
  };

  const role = user?.attributes?.["custom:role"] || "owner";
  const isOwner = role === "owner";
  const isEmployee = role === "employee";
  const userLocation = user?.attributes?.["custom:location"];
  const authContextValue: authContextType = {
    user,
    isReseller: isReseller(),
    isAdmin: isAdmin(),
    isLoggedIn,
    signOut,
    signIn,
    initPassword,
    resetUser,
    overrideUser,
    isOverrideUser,
    loadUser,
    role,
    isOwner,
    isEmployee,
    location: userLocation,
  };

  return <AuthContext.Provider value={authContextValue} {...props} />;
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
