import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../services/auth-context";
import Modal from "../../Modal";
import { API } from "aws-amplify";
import Spinner from "../../Spinner";
import { t, Trans } from "@lingui/macro";
import removeEmptyObjectProps from "../../../util/removeEmptyObjectProps";
import CordSettingsComponent from "./CordSettingsComponent";

const percentageToNumeric = (number: any) =>
  number && (Number.parseFloat(number) / 100).toFixed(3);
const currencyToCents = (number: any) =>
  number && (Number.parseFloat(number) * 100).toFixed(2);
export const CordSettingsModal: React.FC<{
  location?: any;
  devices: any[];
  visible: boolean;
  onCancel: any;
  onClickOutside?: any;
  merchantId: any;
  updateLocation: Function;
}> = ({
  location,
  devices,
  visible,
  onCancel,
  onClickOutside,
  merchantId,
  updateLocation,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { settings } = location;
  const [gateway, setGateway] = useState(settings?.qr_payments?.gateway);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");
  const { isReseller } = useAuth();

  const onSave = (formData: any) => {
    setRequestError("");
    console.log(formData);
    const endpoint = `/locations/${location.id}`;
    let body = {
      settings: {
        qr_payments: {
          enabled: formData.qr_payments_enabled,
          redirect_url: formData.redirect_url || "",
          intro: formData.payments_intro,
          outro: formData.payments_outro,
        },
        qr_promotions: {
          enabled: formData.qr_promotions_enabled,
          intro: formData.promotions_intro,
          outro: formData.promotions_outro,
          redirect_url: formData.promotions_redirect_url,
        },
        auto_reboot: formData.auto_reboot,
        preferred_printer: formData.preferred_printer,
      },
    };
    body = removeEmptyObjectProps(body);
    body.settings.preferred_printer = formData.preferred_printer;

    if (formData.qr_payments_enabled === "true") {
      if (formData.qr_method === "checkout") {
        body.settings.qr_payments.redirect_url = "";
        if (!gateway) {
          setRequestError(
            t`No payment gateway selected. Please go to Payment Settings and configure Copper Payments`
          );
          return;
        }
      } else if (formData.qr_method === "redirect") {
        if (!formData.redirect_url) {
          setRequestError(t`Please define a custom URL for bills`);
          return;
        }
      }
    }

    if (
      !formData.promotions_redirect_url &&
      formData.qr_promotions_enabled === "true"
    ) {
      setRequestError(
        t`Please define a URL for receipts or disable QR codes on receipts`
      );
      return;
    }
    setRequestError("");

    setIsSubmitting(true);

    API.post("Conduit", endpoint, {
      headers: { "copper-account": merchantId },
      body: body,
    })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response.data.error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      onClickOutside={onClickOutside}
      visible={visible}
      maxWidthClass="max-w-screen-sm"
    >
      <form className="h-full" onSubmit={handleSubmit(onSave)}>
        <div className="pl-3 flex flex-col justify-between h-full">
          <div className="text-responsive-lg font-medium text-center mb-8">
            <Trans>Copper Cord Settings</Trans>
          </div>

          <div className="space-y-4">
            <CordSettingsComponent
              register={register}
              location={location}
              devices={devices}
              setValue={setValue}
              isHide={false}
            ></CordSettingsComponent>
          </div>
          <div className="flex justify-between pt-4">
            {!!requestError ? (
              <span className="label-error my-auto">{requestError}</span>
            ) : (
              <div />
            )}
            <button
              type="submit"
              className={`copper-button-confirm ${
                isSubmitting && "text-transparent"
              }`}
              disabled={false}
            >
              {isSubmitting && (
                <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0 md:px-1.5">
                  <Spinner size={20} color="white"></Spinner>
                </div>
              )}
              <Trans>Save</Trans>
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
