import React, { useState } from "react";
import Toggle from "../../Toggle";
import TipField from "./TipField";
import { t, Trans } from "@lingui/macro";
import { isLocalDevEnv } from "../../utils/util";

const GatewaySelectionComponent: React.FC<{
  isDisabled?: boolean;
  register: any;
  location?: any;
  setValue: any;
  isHide?: boolean;
  isAustralia: boolean;
  errors: any;
}> = ({
  location,
  setValue,
  register,
  isHide,
  isAustralia,
  errors,
  isDisabled = false,
}) => {
  const { settings } = location;
  const [gateway, setGateway] = useState(settings?.qr_payments?.gateway);

  const [enabled, setEnabled] = useState(
    settings?.qr_payments?.enabled ?? false
  );
  const notConnected = (name: string) => {
    return location?.settings[name].connected != true;
  };
  return (
    <div className="space-y-3">
      <div className="flex items-center h-8">
        <Toggle
          onChange={(value: boolean) => {
            const newValue = !enabled;
            setEnabled(newValue);
            setValue("qr_payments_enabled", newValue);
          }}
          value={enabled}
        />
        <input
          id="qr_payments_enabled"
          name="qr_payments_enabled"
          value={enabled}
          ref={register()}
          type={"hidden"}
        />
        <span className="w-full text-lg pl-4 text-gray-900">
          <Trans>Enable Copper payments</Trans>
        </span>
      </div>
      {enabled && (
        <div hidden={!enabled} className={"flex "}>
          <label className={"m-auto text-sm "}>Payments provider</label>
          <select
            disabled={!enabled}
            ref={register}
            id="qr_payments_gateway"
            name="qr_payments_gateway"
            className="copper-input-dropdown w-60 m-auto "
            value={gateway}
            onChange={(e) => {
              setGateway(e.target.value);
              setValue("qr_payments_gateway", e.target.value);
            }}
          >
            <option disabled={true} value="" selected={!gateway}>
              [{t`Select a gateway`}]
            </option>
            <option disabled={notConnected("bluefin_payments")} value="bluefin">
              Bluefin PayConex
            </option>
            <option value="stripe" disabled={notConnected("stripe_payments")}>
              Stripe Connect
            </option>
            <option value="transit" disabled={notConnected("transit_payments")}>
              TSYS TransIT
            </option>
            <option value="tyro" disabled={notConnected("tyro_payments")}>
              Tyro Connect
            </option>
            <option value="express" disabled={notConnected("express_payments")}>
              Worldpay Express
            </option>
            <option value="liven" disabled={notConnected("liven_payments")}>
              Liven
            </option>
            {isLocalDevEnv && (
              <option value="demo">{t`Staging only demo`}</option>
            )}
          </select>
        </div>
      )}
    </div>
  );
};

export default React.memo(GatewaySelectionComponent);
