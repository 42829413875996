import { API } from "aws-amplify";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../components/DataTable";
import MainHeader from "../components/MainHeader";
import Spinner from "../components/Spinner";
import objectToQueryString from "../util/objectToQueryString";
import CenteredErrorLabel from "../components/CenteredErrorLabel";
import FixedMain from "./containers/FixedMain";
import { t, Trans } from "@lingui/macro";
import { getCurrentLocale } from "../util/i18n";
import ResellerFilter, {
  getResellerQueryFilterSettings,
  resetFilterSettings,
} from "../components/filters/resellers/ResellerFilter";
import { valueListToString } from "../components/utils/util";
import ResellerModal from "../components/modals/ResellerModal";
import Button from "../components/Button";

const dateFormat = "YYYY-MM-DD";
const pageItemCount = 10;
const loadMoreCount = 10;
const currentYear = moment().year();
moment.locale(getCurrentLocale());

const slash = "     ";

const Resellers: React.FC = () => {
  const history = useHistory();
  const [showingNewModal, setShowingNewModal] = useState(false);
  const onModalSubmitSuccess = (newMerchant: any) => {
    setShowingNewModal(false);
    history.push(`/resellers/${newMerchant.id}`);
  };

  const onModalCancel = () => {
    setShowingNewModal(false);
  };
  const [error, setError] = useState("");

  const [resellers, setResellers] = useState<any[] | any>(null);
  const [filterLocationId, setFilterLocationId] = useState("");
  const [filterSettings, setFilterSettings] = useState(
    getResellerQueryFilterSettings()
  );

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadResellers = (
    limit: number,
    offset: number,
    consumer: (data: any) => void
  ) => {
    setLoading(true);
    setError("");
    let parameters = filterSettingsToRequestParameters(filterSettings);
    window.history.replaceState("", "", `?${objectToQueryString(parameters)}`);
    API.get("Conduit", "/resellers", {
      queryStringParameters: { ...parameters, offset: offset, limit: limit },
    })
      .then(({ data: recordsList }) => {
        let filtered = recordsList;
        if (filterSettings.location || filterSettings.merchant) {
          filtered = filtered.filter((r: any) => {
            return r.locations > 0 && r.merchants > 0;
          });
        }
        setHasMore(filtered.length == limit);
        consumer(filtered);
      })
      .catch((err) => {
        setError(err.response?.data?.error?.message || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadResellers(pageItemCount, 0, (data) => {
      setResellers(data);
    });
  }, [filterSettings]);

  const statusClassName = (status: string) => {
    switch (status) {
      case "active":
        return " ";
      case "inactive":
        return " text-yellow-600";
      case "disabled":
        return " text-red-600";
    }
  };
  const countRow = (
    total: number,
    active: number,
    inactive: number,
    disabled: number
  ) => {
    return (
      <div>
        {/*<span>{total}</span>*/}
        {active > 0 && <span className={"text-green-600"}>{active} a</span>}

        {inactive > 0 && (
          <span className={"text-yellow-600"}>
            {slash}
            {inactive} i
          </span>
        )}
        {disabled > 0 && (
          <span className={"text-red-600"}>
            {slash}
            {disabled} d
          </span>
        )}
      </div>
    );
  };
  const getResellers = () => {
    return resellers.map((reseller: any) => {
      const isSingapore = reseller.country.toLowerCase() == "sg";
      return {
        url: `/resellers/${reseller.id}`,
        name: (
          <div
            className={
              "capitalize w-24 text-left" + statusClassName(reseller.status)
            }
          >
            {reseller.name}
          </div>
        ),
        where: (
          <div className="capitalize w-10 text-left">
            {isSingapore ? "Singapore" : `${reseller.city}, ${reseller.state}`}
            <span className={"font-bold"}> {reseller.country}</span>
          </div>
        ),
        merchants: (
          <div className="capitalize w-10">
            {countRow(
              reseller.merchants,
              reseller.activeMerchants,
              reseller.inactiveMerchants,
              reseller.disabledMerchants
            )}
          </div>
        ),
        locations: (
          <div className="capitalize w-10">
            {countRow(
              reseller.locations,
              reseller.activeLocations,
              reseller.inactiveLocations,
              reseller.disabledLocations
            )}
          </div>
        ),
        cords: (
          <div className="capitalize w-10">
            {countRow(0, reseller.activeCords, 0, reseller.inactiveCords)}
          </div>
        ),
        devices: (
          <div className="capitalize w-10">
            {countRow(0, reseller.activeNotCords, 0, reseller.inactiveNotCords)}
          </div>
        ),
      };
    });
  };

  const componentState = {
    loading,
    filterLocationId,
    setFilterLocationId,
    filterSettings,
    setFilterSettings,
  };
  const bottomScrollRef = useRef<HTMLDivElement | null>(null);

  return (
    <FixedMain>
      <div>
        <div className="p-4 flex justify-between">
          <MainHeader>
            <Trans>Resellers</Trans>
          </MainHeader>
          <Button
            onClick={() => setShowingNewModal(true)}
            icon="ion-android-add"
          >
            <Trans>New</Trans>
          </Button>{" "}
        </div>
        <ResellerModal
          visible={showingNewModal}
          onCancel={onModalCancel}
          onSubmitSuccess={onModalSubmitSuccess}
          onClickOutside={onModalCancel}
          removeBlankFormValues={true}
          sendType={true}
        />
        <div className={"w-full h-12"}>
          <ResellerFilter {...componentState}></ResellerFilter>
        </div>
        <div
          className={"overflow-auto"}
          style={{ height: "calc(100vh - 200px)" }}
        >
          {!!error && <CenteredErrorLabel message={error} />}
          {!!resellers ? (
            <DataTable
              data={getResellers()}
              noItemsHeader={t`No resellers found`}
              noItemsBody={t`Probably something went wrong`}
              headerClasses="pl-4"
              tdClass="px-4 py-2"
              // enableHalfWidthCells={true}
              noBorderTopOnHeaders={true}
              disableFullWidthCells={true}
              disableFlexBetween={true}
              footer={
                hasMore && (
                  <div
                    className={"w-full flex items-center justify-center p-4"}
                  >
                    <div
                      ref={bottomScrollRef}
                      className="grid  place-items-center w-36 h-8 cursor-pointer p-4 py-0.5 text-base rounded text-white bg-copper-purple my-auto hover:bg-copper-purple-hover"
                      onClick={() => {
                        if (loading) {
                          return;
                        }

                        loadResellers(
                          loadMoreCount,
                          resellers?.length ?? 0,
                          (data) => {
                            setResellers(resellers.concat(data));
                          }
                        );
                      }}
                    >
                      <div>
                        {loading && !error ? (
                          <Spinner size={20} color="white"></Spinner>
                        ) : (
                          t`Load more...`
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
              columns={[
                {
                  name: "name",
                  label: t`Name`,
                  type: "string",
                  headerClass: "uppercase w-24",
                  tdClass: "",
                },
                {
                  name: "where",
                  label: t`Where`,
                  type: "string",
                  headerClass: "uppercase w-4",
                  tdClass: "uppercase w-4",
                },
                {
                  name: "merchants",
                  label: t`Merchants`,
                  type: "string",
                  headerClass: "uppercase w-12",
                  tdClass: "",
                },
                {
                  name: "locations",
                  label: t`Locations`,
                  type: "string",
                  headerClass: "uppercase w-12",
                  tdClass: "",
                },
                {
                  name: "cords",
                  label: t`Cords`,
                  type: "string",
                  headerClass: "uppercase w-12",
                  tdClass: "",
                },
                {
                  name: "devices",
                  label: t`Devices`,
                  type: "string",
                  headerClass: "uppercase w-12",
                  tdClass: "",
                },
              ]}
            />
          ) : (
            !error && (
              <div className="flex justify-center p-responsive">
                <Spinner size={20} color="gray"></Spinner>
              </div>
            )
          )}
        </div>
      </div>
    </FixedMain>
  );
};

const filterSettingsToRequestParameters = (filterSettings: any) => {
  let requestParameters = {};
  if (filterSettings.sort) {
    requestParameters = {
      ...requestParameters,
      sort: filterSettings.sort ?? resetFilterSettings.sort,
    };
  }
  if (filterSettings.sortDirection) {
    requestParameters = {
      ...requestParameters,
      direction: filterSettings.sortDirection,
    };
  }

  const country = valueListToString(filterSettings.country);
  if (country) {
    requestParameters = {
      ...requestParameters,
      country: country,
    };
  }

  const status = valueListToString(filterSettings.status);
  if (status) {
    requestParameters = {
      ...requestParameters,
      status: status,
    };
  }

  if (filterSettings.name) {
    requestParameters = {
      ...requestParameters,
      name: filterSettings.name,
    };
  }

  if (filterSettings.location) {
    requestParameters = {
      ...requestParameters,
      location: filterSettings.location,
    };
  }

  if (filterSettings.merchant) {
    requestParameters = {
      ...requestParameters,
      merchant: filterSettings.merchant,
    };
  }

  return requestParameters;
};

export default Resellers;
