import React, { useEffect, useState } from "react";
import useClickOutside from "./utils/useClickOutside";

export interface ChecklistDropdownItem {
  key: string;
  value: string;
}

export interface ChecklistDropdownProps {
  items: ChecklistDropdownItem[];
  selectedItems: string[];
  noSelectionText: string;
  onSelection: (items: any[]) => void;
}

export const ChecklistDropdown: React.FC<ChecklistDropdownProps> = (props) => {
  const { items, selectedItems, noSelectionText, onSelection } = props;
  useEffect(() => {
    setSelected(selectedItems);
  }, [selectedItems]);
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState<string[]>(selectedItems);
  const clickOutsideRef = useClickOutside(() => {
    if (!expanded) {
      return;
    }
    setTimeout(() => setExpanded(false), 150);
  });

  const onSelectionChanged = (e: any) => {
    const checked = e.target.checked;
    const selectedItem = e.target.value;
    const newSelection = checked
      ? selected.concat(selectedItem)
      : selected.filter((item) => {
          return item != selectedItem;
        });

    setSelected(newSelection);
    onSelection(newSelection);
  };

  return (
    <div ref={clickOutsideRef}>
      {expanded && (
        <div
          className={
            "w-auto h-auto p-1 relative z-3 bg-gray-100 shadow-lg -mt-1 -ml-1 rounded-md border border-slate-800"
          }
        >
          <ul>
            {items.map((item: any) => (
              <li key={item.key}>
                <label className={"pl-1 pr-2"}>
                  <input
                    checked={selected.includes(item.key)}
                    className={"align-middle m-1"}
                    type={"checkbox"}
                    value={item.key}
                    onChange={onSelectionChanged}
                  />
                  {item.value}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
      <input
        hidden={expanded}
        onClick={() => {
          setExpanded(!expanded);
        }}
        readOnly={true}
        className="cursor-default	copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
        value={
          selected.length == 0
            ? noSelectionText
            : selected.length == 1
            ? items.filter((item) => {
                return item.key == selected[0];
              })[0].value
            : "Multiple"
        }
      />
    </div>
  );
};
