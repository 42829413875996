import React, { useEffect, useState } from "react";
import Toggle from "../../Toggle";
import { Trans } from "@lingui/macro";
import QRCode from "qrcode";
import mergeImages from "merge-images";
import { isLocalDevEnv } from "../../utils/util";
import Button from "../../Button";
import { QRUrlImage } from "./QRUrlImage";

const generatePrintableQR = (imageURL: string, url: string) => {
  const image = new Image();
  image.onload = () => {
    const width = image.width;
    const height = image.height;
    const qrWidth = width * 0.7;
    const qrX = (width - qrWidth) / 2;
    const qrY = (height - qrWidth) / 2;

    QRCode.toDataURL(url, { type: "png", width: qrWidth }).then((img: any) => {
      mergeImages([{ src: image.src }, { src: img, x: qrX, y: qrY }]).then(
        (b64: any) => {
          const resultImage = new Image();
          resultImage.src = b64;

          const w = window.open("");
          if (w) {
            w.document.write("<html><head><style>");
            w.document.write("@media print {");
            w.document.write("img.printable {");
            w.document.write("width: 8.5in;");
            w.document.write("height: auto;");
            w.document.write("max-height: 10.8in;");
            w.document.write("page-break-inside: avoid;");
            w.document.write("display: block;");
            w.document.write("margin: 0 auto;");
            w.document.write("}");
            w.document.write("</style></head><body>");
            resultImage.className = "printable";
            w.document.write(resultImage.outerHTML);
            w.document.write("</body></html>");
            setTimeout(() => {
              w.print();
            }, 1500);
          }
        }
      );
    });
  };
  image.src = imageURL;
};
const ConsumerQRComponent: React.FC<{
  location?: any;
  saveSettings: any;
}> = ({ location, saveSettings }) => {
  const [gateway, setGateway] = useState(
    location?.settings?.qr_payments?.gateway
  );

  const [enabled, setEnabled] = useState(
    location?.settings?.manual_amount_entry
  );

  const showCustomerPrintableQR = () => {
    const imageURL =
      gateway === "liven"
        ? "/img/manual-qr-template-liven.png"
        : "/img/manual-qr-template.png";
    return generatePrintableQR(imageURL, paymentURL);
  };

  const [paymentURL, setPaymentURL] = useState(
    `https://${isLocalDevEnv ? "staging." : ""}cop.pe/r/` + location.id
  );
  const [image, setImage] = useState("");

  useEffect(() => {
    generateQRCode();
  }, [paymentURL]);

  const generateQRCode = async () => {
    try {
      const url = await QRCode.toDataURL(paymentURL);
      setImage(url);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="space-y-3">
      <div className="flex items-center h-8">
        <Toggle
          onChange={(value: boolean) => {
            const newValue = !enabled;
            setEnabled(newValue);
            saveSettings({
              settings: {
                manual_amount_entry: newValue,
              },
            });
          }}
          value={enabled}
          isDisabled={!gateway}
        />
        <span className=" text-sm pl-4 pr-3 text-gray-900">
          <Trans>Enable consumer amount entry</Trans>
        </span>
      </div>
      {enabled && (
        <div>
          <div>
            {image && <QRUrlImage url={paymentURL} image={image}></QRUrlImage>}
          </div>
          <div className={"flex"}>
            <Button
              icon="ion-printer"
              onClick={showCustomerPrintableQR}
              className={"border-none m-auto"}
            >
              <Trans>Print</Trans>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ConsumerQRComponent);
