import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { t, Trans } from "@lingui/macro";
import { getCurrentLocale } from "../../../util/i18n";
import {
  ALL,
  arrayToSelectOptions,
  getQueryParam,
  queryDateParam,
  safeCents,
} from "../../utils/util";

registerLocale("es", es);

function pair(key: string, value: string) {
  return { key: key, value: value };
}

export const paidFilterOptions = [
  pair(ALL, t`All`),
  pair("0", t`Not fully paid`),
  pair("1", t`Fully paid`),
];

export const transactionFilterOptions = [
  pair(ALL, t`All`),
  pair("0", t`No transactions`),
  pair("1", t`One transaction`),
  pair("2", t`Multiple transactions`),
];

export const resetFilterSettings = {
  sort: "",
  sortDirection: "",
  reference: "",
  server: "",
  label: "",
  minAmount: 0,
  maxAmount: 0,
  location: { id: "" },
  txns: ALL,
  paid: ALL,
  startDate: null,
  endDate: null,
};

export const getTicketQueryFilterSettings = () => ({
  sort: getQueryParam("sort") ?? "",
  sortDirection: getQueryParam("direction") ?? "",
  reference: getQueryParam("reference") ?? "",
  server: getQueryParam("server") ?? "",
  label: getQueryParam("label") ?? "",
  minAmount: safeCents(getQueryParam("min_amount") ?? "") / 100 ?? 0,
  maxAmount: safeCents(getQueryParam("max_amount") ?? "") / 100 ?? 0,
  location: { id: getQueryParam("location") ?? "" },
  txns: getQueryParam("txns") ?? ALL,
  paid: getQueryParam("paid") ?? ALL,
  startDate: queryDateParam("from_date"),
  endDate: queryDateParam("to_date"),
});

const TicketFilter: React.FC<any> = (props) => {
  const { locations, filterSettings, setFilterSettings } = props;

  const [debounceTimer, setDebounceTimer] = useState<any>();

  const [internalFilterSettings, setInternalFilterSettings] =
    useState(filterSettings);

  useEffect(() => {
    setInternalFilterSettings({
      ...internalFilterSettings,
      minAmount: internalFilterSettings.minAmount / 100,
      maxAmount: internalFilterSettings.maxAmount / 100,
    });
  }, []);

  const updateExternalFilter = (filterSettings: any) => {
    setFilterSettings({
      ...filterSettings,
      minAmount: safeCents(filterSettings.minAmount),
      maxAmount: safeCents(filterSettings.maxAmount),
    });
  };

  const updateFilter = (update: any, debounce: boolean = false) => {
    clearTimeout(debounceTimer);
    const updatedFilter = { ...internalFilterSettings, ...update };
    setInternalFilterSettings(updatedFilter);
    if (!debounce) {
      updateExternalFilter(updatedFilter);
      return;
    }
    setDebounceTimer(
      setTimeout(() => {
        updateExternalFilter(updatedFilter);
      }, 500)
    );
    return () => clearTimeout(debounceTimer);
  };

  if (!locations) {
    return <></>;
  }

  const filterInputContainer = (
    text: string,
    contents: any,
    extra: any = null
  ) => {
    return (
      <div className="text-responsive-xs">
        <div className={"pl-1 pb-1"}>{text}</div>
        <div className={"w-24 flex"}>
          {contents}
          {extra}
        </div>
      </div>
    );
  };

  const dropdown = (
    text: string,
    defaultValue: any,
    filterProperty: string,
    options: any[]
  ) => {
    return filterInputContainer(
      text,
      <select
        className="bg-white copper-input-basic text-gray-500 rounded p-0.5 px-1.5 w-20 h-6"
        value={defaultValue}
        onChange={(e) => {
          let change: any = {};
          change[filterProperty] = e.target.value;
          updateFilter(change);
        }}
      >
        {arrayToSelectOptions(options)}
      </select>
    );
  };

  const transactionFilterDropdown = dropdown(
    t`Transactions`,
    internalFilterSettings.txns,
    "txns",
    transactionFilterOptions
  );

  const paidFilterDropdown = dropdown(
    t`Paid`,
    internalFilterSettings.paid,
    "paid",
    paidFilterOptions
  );

  const clearButton = (
    <div
      className="text-responsive-xs font-light flex cursor-pointer text-copper-purple p-0.5  mr-1 my-4"
      onClick={() => {
        updateFilter(resetFilterSettings);
      }}
    >
      <Trans>Clear</Trans>
    </div>
  );

  const minAmountInput = filterInputContainer(
    t`From amount`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      maxLength={8}
      placeholder="0"
      value={internalFilterSettings.minAmount}
      onChange={(e) => {
        updateFilter(
          {
            minAmount: e.target.value,
          },
          true
        );
      }}
    />
  );

  const maxAmountInput = filterInputContainer(
    t`To amount`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      maxLength={8}
      placeholder="0"
      value={internalFilterSettings.maxAmount}
      onChange={(e) => {
        let value = safeCents(e.target.value);
        if (value == null) {
          value = 0;
        }
        updateFilter(
          {
            maxAmount: e.target.value,
          },
          true
        );
      }}
    />
  );

  const sortDirectionButton = (
    <div
      className={"cursor-pointer"}
      onClick={() => {
        updateFilter({
          sortDirection:
            filterSettings.sortDirection == "desc" ||
            filterSettings.sortDirection == ""
              ? "asc"
              : "desc",
        });
      }}
    >
      <svg
        transform={
          filterSettings.sortDirection == "asc" ? "scale(-1,-1)" : "scale(1,1)"
        }
        width={24}
        height={24}
        fill={"#959595"}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M7.03 13.92H11.03V5L13.04 4.97V13.92H17.03L12.03 18.92Z" />
      </svg>
    </div>
  );

  const sortDropdown = (
    <div className={"pr-2"}>
      {filterInputContainer(
        t`Sort`,
        <select
          className="bg-white copper-input-basic text-gray-500 rounded p-0.5 px-1.5 w-20 h-6"
          value={internalFilterSettings.sort}
          onChange={(e) => {
            updateFilter({
              sort: e.target.value,
            });
          }}
        >
          <option value="created">{t`Date`}</option>
          <option value="amount">{t`Amount`}`</option>
        </select>,
        sortDirectionButton
      )}
    </div>
  );

  const locationDropdown = filterInputContainer(
    t`Location`,
    <select
      className="bg-white copper-input-basic text-gray-500 rounded p-0.5 px-1.5 w-20 h-6"
      value={internalFilterSettings.location.id}
      onChange={(e) => {
        updateFilter({
          location: {
            ...internalFilterSettings.location,
            id: e.target.value,
          },
        });
      }}
    >
      <option value="">{t`All`}</option>
      {locations.map((location: any) => (
        <option value={location.id}>{location.name}</option>
      ))}
    </select>
  );

  const startDateInput = filterInputContainer(
    t`From date`,
    <DatePicker
      selected={internalFilterSettings.startDate}
      onChange={(date: any) => {
        updateFilter({
          startDate: date,
        });
      }}
      customInput={
        <input
          readOnly={true}
          className="copper-input-basic rounded text-gray-500 text-center p-2px pb-1px w-20 h-6"
        />
      }
      locale={getCurrentLocale()}
    />
  );

  const endDateInput = filterInputContainer(
    t`To date`,
    <DatePicker
      selected={internalFilterSettings.endDate}
      onChange={(date: any) =>
        updateFilter({
          endDate: date,
        })
      }
      customInput={
        <input
          readOnly={true}
          className="copper-input-basic rounded text-gray-500 text-center p-2px pb-1px w-20 h-6"
        />
      }
      locale={getCurrentLocale()}
    />
  );
  const ticketInput = filterInputContainer(
    t`Number`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      value={internalFilterSettings.reference}
      onChange={(e) => {
        updateFilter(
          {
            reference: e.target.value,
          },
          true
        );
      }}
    />
  );

  const serverInput = filterInputContainer(
    t`Server`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      value={internalFilterSettings.server}
      onChange={(e) => {
        updateFilter(
          {
            server: e.target.value,
          },
          true
        );
      }}
    />
  );

  const labelInput = filterInputContainer(
    t`Label`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      value={internalFilterSettings.label}
      onChange={(e) => {
        updateFilter(
          {
            label: e.target.value,
          },
          true
        );
      }}
    />
  );

  return (
    <div className="flex flex-row">
      {sortDropdown}
      {locationDropdown}
      {startDateInput}
      {endDateInput}
      {minAmountInput}
      {maxAmountInput}
      {ticketInput}
      {serverInput}
      {labelInput}
      {transactionFilterDropdown}
      {paidFilterDropdown}
      {clearButton}
    </div>
  );
};

export default TicketFilter;
