import { Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API } from "aws-amplify";
import UploadImage from "../../components/UploadImage";
import Modal from "../Modal";
import ModalForm from "../ModalForm";
import FormInput from "../FormInput";
import LeftLabeledContainer from "../LeftLabeledContainer";
import { appendHttpsIfMissing } from "../../util/urls";
import { t, Trans } from "@lingui/macro";
import Toggle from "../Toggle";
import { checkIcon, copyIcon } from "./PaymentSettings/QRUrlImage";
import { isLocalDevEnv } from "../utils/util";

const generateWaiterURL = (locationID: string) => {
  return `${
    isLocalDevEnv ? "https://staging.cop.pe/" : "https://cop.pe/"
  }r/waiter/${locationID}?table={{ no | default: '' }}`;
};

const generateBillURL = (locationID: string) => {
  return `${
    isLocalDevEnv ? "https://staging.cop.pe/" : "https://cop.pe/"
  }r/bill/${locationID}?table={{ no | default: '' }}`;
};
const NomnieModal: React.FC<{
  location?: any;
  devices: any[];

  merchantId: any;
  onCancel: any;
  onClickOutside?: any;
  visible: boolean;
}> = ({ location, devices, merchantId, onCancel, onClickOutside, visible }) => {
  const nomnie = location?.settings?.nomnie;

  const { register, setValue, handleSubmit, errors } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState("");

  const callWaiterURL = location && generateWaiterURL(location.id);
  const requestBillURL = location && generateBillURL(location.id);
  const [toggleCallWaiterEnabled, setToggleCallWaiterEnabled] = useState(
    nomnie?.call_waiter
  );
  const [toggleRequestBill, setToggleRequestBill] = useState(
    nomnie?.request_bill
  );
  const [copyButtonIcon, setCopyButtonIcon] = useState(copyIcon);
  const onFormSubmit = async (formData: any) => {
    if (!formData) return;
    console.log(formData);
    const { logo, icon } = formData;

    setIsSubmitting(true);

    const endpoint = `/locations/${location.id}`;
    await API.post("Conduit", endpoint, {
      headers: { "copper-account": merchantId },
      body: {
        website: formData.website,
        settings: {
          nomnie: {
            call_waiter: formData.call_waiter,
            notify_printer: formData.notify_printer,
            request_bill: formData.request_bill,
            bill_printer: formData.bill_printer,
          },
        },
      },
    })
      .then(() => {
        // updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response.data.error.message);
      });

    //onSubmitSuccess();
    setIsSubmitting(false);
  };

  const onModalCancel = () => {
    onCancel();
    setRequestError("");
    setIsSubmitting(false);
  };

  return (
    <Modal onClickOutside={onClickOutside} visible={visible}>
      <ModalForm
        title=""
        isSubmitting={isSubmitting}
        onCancel={onModalCancel}
        handleSubmit={handleSubmit(onFormSubmit)}
        submitError={requestError}
        backgroundColorClass="bg-white"
      >
        <h2 className="text-2xl text-center">
          <Trans>Nomnie integration</Trans>
        </h2>
        <div className="space-y-3">
          <div>
            <div className="flex items-center">
              <Toggle
                onChange={() => {
                  const newValue = !toggleCallWaiterEnabled;
                  setToggleCallWaiterEnabled(newValue);
                  setValue("call_waiter", newValue);
                }}
                value={toggleCallWaiterEnabled}
              />
              <span className="text-sm font-medium pl-4 text-gray-900">
                <Trans>Enable customers to call the waiter</Trans>
              </span>
              <input
                id="call_waiter"
                name="call_waiter"
                value={toggleCallWaiterEnabled}
                ref={register()}
                type={"hidden"}
              />
            </div>
            <div
              className={
                "ml-10 " + (toggleCallWaiterEnabled ? "" : "opacity-50")
              }
            >
              <div className="flex mt-4 items-center">
                <div className={"flex-grow"}>
                  <LeftLabeledContainer name={t`Nomnie URL`}>
                    <input
                      disabled={!toggleCallWaiterEnabled}
                      readOnly={true}
                      className={`copper-input text-responsive-xs max-h-96`}
                      value={toggleCallWaiterEnabled ? callWaiterURL : ""}
                      ref={register}
                    />
                  </LeftLabeledContainer>
                </div>
                <div className={"flex"}>
                  <span
                    className="ml-2 cursor-pointer rounded-xl hover:bg-gray-100 p-3"
                    onClick={async () => {
                      if (!toggleCallWaiterEnabled) {
                        return;
                      }
                      setCopyButtonIcon(checkIcon);
                      await navigator.clipboard.writeText(callWaiterURL);
                      setTimeout(() => {
                        setCopyButtonIcon(copyIcon);
                      }, 2000);
                    }}
                  >
                    {copyButtonIcon}
                  </span>
                </div>
              </div>
              <LeftLabeledContainer name={t`Printer`}>
                <div className={"mt-2"}>
                  <select
                    ref={register()}
                    id="notify_printer"
                    name="notify_printer"
                    disabled={!toggleCallWaiterEnabled}
                    className="copper-input"
                    defaultValue={location?.settings?.nomnie?.printer}
                  >
                    <option value="">{t`Print to all available printers`}</option>
                    {devices
                      .filter((device) => device.device_type === "copper_Cord")
                      .map((device) => (
                        <option value={device.serial_number} key={device.id}>
                          {device.label || device.serial_number}
                        </option>
                      ))}
                  </select>
                </div>
              </LeftLabeledContainer>
            </div>
          </div>
          {/*------------------------------------------------------*/}
          <div>
            <div className="flex items-center">
              <Toggle
                onChange={() => {
                  const newValue = !toggleRequestBill;
                  setToggleRequestBill(newValue);
                  setValue("request_bill", newValue);
                }}
                value={toggleRequestBill}
              />
              <span className="text-sm font-medium pl-4 text-gray-900">
                <Trans>Enable customers to request and pay the bill</Trans>
              </span>
              <input
                id="request_bill"
                name="request_bill"
                value={toggleRequestBill}
                ref={register()}
                type={"hidden"}
              />
            </div>
            <div className={"ml-10 " + (toggleRequestBill ? "" : "opacity-50")}>
              <div className="flex mt-4 items-center">
                <div className={"flex-grow"}>
                  <LeftLabeledContainer name={t`Nomnie URL`}>
                    <input
                      disabled={!toggleRequestBill}
                      readOnly={true}
                      className={`copper-input text-responsive-xs max-h-96`}
                      value={toggleRequestBill ? requestBillURL : ""}
                      ref={register}
                    />
                  </LeftLabeledContainer>
                </div>
                <div className={"flex"}>
                  <span
                    className="ml-2 cursor-pointer rounded-xl hover:bg-gray-100 p-3"
                    onClick={async () => {
                      if (!toggleRequestBill) {
                        return;
                      }
                      setCopyButtonIcon(checkIcon);
                      await navigator.clipboard.writeText(requestBillURL);
                      setTimeout(() => {
                        setCopyButtonIcon(copyIcon);
                      }, 2000);
                    }}
                  >
                    {copyButtonIcon}
                  </span>
                </div>
              </div>
              <LeftLabeledContainer name={t`Printer`}>
                <div className={"mt-2"}>
                  <select
                    ref={register()}
                    id="bill_printer"
                    name="bill_printer"
                    disabled={!toggleRequestBill}
                    className="copper-input"
                    defaultValue={location?.settings?.nomnie?.printer}
                  >
                    <option value="">{t`Print to all available printers`}</option>
                    {devices
                      .filter((device) => device.device_type === "copper_Cord")
                      .map((device) => (
                        <option value={device.serial_number} key={device.id}>
                          {device.label || device.serial_number}
                        </option>
                      ))}
                  </select>
                </div>
              </LeftLabeledContainer>
            </div>
          </div>
        </div>
      </ModalForm>
    </Modal>
  );
};

export default NomnieModal;
