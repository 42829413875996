import { API } from "aws-amplify";
import React, { useState } from "react";
import removeEmptyObjectProps from "../../../util/removeEmptyObjectProps";
import Spinner from "../../Spinner";
import NonCashComponent from "./NonCashComponent";
import PreAuthComponent from "./PreAuthComponent";
import SplitSettingsComponent from "./SplitSettingsComponent";
import TipSettingsComponent from "./TipSettingsComponent";
import { useAuth } from "../../../services/auth-context";
import { t, Trans } from "@lingui/macro";
import GatewaySelectionComponent from "./GatewaySelectionComponent";

const BuildOtherSettings: React.FC<{
  location: any;
  updateLocation: any;
  merchantId: string;
  register: any;
  reset?: any;
  handleSubmit: any;
  setValue: any;
  isSubmitting: any;
  setIsSubmitting: any;
  requestError: any;
  setRequestError: any;
  isReseller: boolean;
  errors: any;
}> = ({
  location,
  updateLocation,
  merchantId,
  register,
  handleSubmit,
  setValue,
  isSubmitting,
  setIsSubmitting,
  requestError,
  setRequestError,
  isReseller,
  errors,
}) => {
  const { settings } = location;
  const { user } = useAuth();
  const isAustralia =
    user?.accountData?.defaultAddress?.country === "AU" ||
    user?.accountData?.address?.country === "AU";

  const percentageToNumeric = (number: any) =>
    number && (Number.parseFloat(number) / 100).toFixed(3);

  const currencyToCents = (number: any) =>
    number && (Number.parseFloat(number) * 100).toFixed(2);

  const onSave = (formData: any) => {
    console.log(formData);
    if (
      !formData.qr_payments_gateway &&
      formData.qr_payments_enabled === "true"
    ) {
      setRequestError(
        "Please select a payment gateway or disable Copper Payments"
      );
      return;
    }
    const endpoint = `/locations/${location.id}`;
    let body = removeEmptyObjectProps({
      settings: {
        qr_payments: {
          enabled: formData.qr_payments_enabled,
          gateway: formData.qr_payments_gateway,
        },
        cutoff_time: formData.cutoff_time,
        splits: {
          enabled: formData.splits_enabled,
          max: formData.max_splits,
        },
        tips: {
          enabled: formData.tips_enabled,
          include_tax: isAustralia || formData.include_tax,
          quick_tip1: percentageToNumeric(formData.quick_tip1),
          quick_tip2: percentageToNumeric(formData.quick_tip2),
          quick_tip3: percentageToNumeric(formData.quick_tip3),
          quick_tip4: percentageToNumeric(formData.quick_tip4),
        },
        adjustments: {
          before_tax: formData.adjustment_before_tax,
          enabled: formData.adjustment_enabled,
          rate: percentageToNumeric(formData.adjustment_rate),
        },
        pre_auth: {
          amount: currencyToCents(formData.pre_auth_amount),
          consent_text: formData.pre_auth_consent_text,
          enabled: formData.pre_auth_enabled,
        },
      },
    });

    setRequestError("");

    setIsSubmitting(true);

    API.post("Conduit", endpoint, {
      headers: { "copper-account": merchantId },
      body: body,
    })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response.data.error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <form className="h-full" onSubmit={handleSubmit(onSave)}>
      <div className="flex flex-col justify-between h-full">
        <div className="space-y-4">
          <GatewaySelectionComponent
            register={register}
            location={location}
            setValue={setValue}
            isHide={isReseller}
            isAustralia={isAustralia}
            errors={errors}
          ></GatewaySelectionComponent>
          {isReseller && (
            <NonCashComponent
              register={register}
              location={location}
              setValue={setValue}
              isAustralia={isAustralia}
            />
          )}

          <PreAuthComponent
            register={register}
            location={location}
            setValue={setValue}
          />

          <SplitSettingsComponent
            register={register}
            location={location}
            setValue={setValue}
            isHide={isReseller}
          />

          <TipSettingsComponent
            register={register}
            location={location}
            setValue={setValue}
            isHide={isReseller}
            isAustralia={isAustralia}
            errors={errors}
          />
        </div>
        <div className="flex justify-between">
          {!!requestError ? (
            <span className="label-error my-auto">{requestError}</span>
          ) : (
            <div />
          )}
          <button
            type="submit"
            className={`copper-button-confirm ${
              isSubmitting && "text-transparent"
            }`}
            disabled={false}
          >
            {isSubmitting && (
              <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0 md:px-1.5">
                <Spinner size={20} color="white"></Spinner>
              </div>
            )}
            <Trans>Save</Trans>
          </button>
        </div>
      </div>
    </form>
  );
};

export const OtherSettingTab = {
  name: t`Other Settings`,
  resellersOnly: false,
  navContent: (active: boolean) => (
    <div className="flex">
      <img
        className="mr-2"
        width="18"
        alt="other-settings"
        src={`/img/payment_options/grid.svg`}
      />
      <div className="text-responsive-xl font-semibold opacity-70">
        <Trans>Other Settings</Trans>
      </div>
    </div>
  ),
  headerText: t`Other Payment Settings`,
  build: BuildOtherSettings,
};

export default React.memo(BuildOtherSettings);
