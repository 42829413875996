import { API } from "aws-amplify";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LogicBadge } from "../components/Badge";
import Button from "../components/Button";
import DataTable from "../components/DataTable";
import PayoutFilter, {
  dateRangeOption,
  defaultFilterSettings,
} from "../components/filters/payouts/PayoutFilter";
import MainHeader from "../components/MainHeader";
import PaginationControls from "../components/PaginationControls";
import Spinner from "../components/Spinner";
import { queryStringToObject } from "../util/objectToQueryString";
import Main from "./containers/Main";
import { t, Trans } from "@lingui/macro";
import { getCurrentLocale } from "../util/i18n";

moment.locale(getCurrentLocale());

const dbTimezone = "America/Los_Angeles";

const Payouts: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const parsedRequestParams = queryStringToObject(
    location.search.replace("?", "")
  );
  const [payouts, setPayouts] = useState<any[] | any>(null);
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [locations, setLocations] = useState<any[] | any>([]);
  const [filterLocationId, setFilterLocationId] = useState("");
  const [filterSettings, setFilterSettings] = useState(defaultFilterSettings);
  const [filtering, setFiltering] = useState<any[] | any>(false);
  const [paramsFromUrl, setParamsFromUrl] = useState(parsedRequestParams);

  useEffect(() => {
    API.get("Conduit", "/locations", {
      queryStringParameters: { limit: 100 },
    }).then((locations) => {
      if (locations.data) {
        setLocations(
          locations.data.map((location: any) => ({
            id: location.id,
            name: location.display_name,
            settings: location.settings,
          }))
        );
      }
    });
  }, []);

  const getPayouts = () => {
    return payouts.map((payout: any) => ({
      click: () => {
        history.push(`/payouts/${payout.id}`);
      },
      amount: (
        <div className="flex flex-row -mr-4">
          <span className="w-14">{"$" + (payout.amount / 100).toFixed(2)}</span>
          <span className="uppercase text-center text-gray-400 w-14 pr-2">
            {payout.currency}
          </span>
          <div className="w-24">
            <LogicBadge
              value={payout.status}
              full
              definition={{
                paid: "green",
                pending: "yellow",
                in_transit: "yellow",
                canceled: "red",
                failed: "red",
              }}
            />
          </div>
        </div>
      ),
      arrivalDate: moment(new Date(payout.arrival_date * 1000))
        .utc()
        .format("MMM DD"),
      externalAccount: (
        <span className="capitalize">
          {(!!payout?.destination &&
            payout.destination.bank_name +
              " **** " +
              payout.destination.last4) ||
            t`Unknown`}
        </span>
      ),
      reference: payout.id,
      statementDescriptor: payout.statement_descriptor || t`Unknown`,
    }));
  };

  const componentState = {
    isShowingFilters,
    setIsShowingFilters,
    locations,
    filterLocationId,
    setFilterLocationId,
    filterSettings,
    setFilterSettings,
    setFiltering,
  };

  if (filtering) {
    window.history.replaceState("", "", `?`);
    setTimeout(() => {
      setPayouts(null);
      setFiltering(false);
    }, 200);
  }

  return (
    <Main>
      <div>
        <div className="p-4 flex justify-between">
          <MainHeader>
            <Trans>Payouts</Trans>
          </MainHeader>
          <Button
            onClick={() => setIsShowingFilters(!isShowingFilters)}
            icon="ion-android-funnel"
          >
            <Trans>Filter</Trans>
          </Button>
          <PayoutFilter {...componentState} />
        </div>
        {!!payouts && !filtering ? (
          <DataTable
            data={getPayouts()}
            noItemsHeader={t`No payouts yet`}
            noItemsBody={t`When a payout is made, it will show here.`}
            headerClasses="pl-4"
            tdClass="px-4 py-2"
            noBorderTopOnHeaders={true}
            disableFullWidthCells={true}
            disableFlexBetween={true}
            columns={[
              {
                name: "amount",
                label: t`Amount`,
                type: "currency",
                headerClass: "uppercase w-12",
                tdClass: "",
              },
              {
                name: "arrivalDate",
                label: t`Estimated arrival`,
                type: "datetime",
                headerClass: "uppercase w-12",
              },
              {
                name: "externalAccount",
                label: t`External account`,
                type: "string",
                headerClass: "uppercase",
                tdClass: "w-1/2",
              },
              {
                name: "reference",
                label: t`Reference`,
                type: "string",
                headerClass: "uppercase",
                tdClass: "w-1/2",
              },
              {
                name: "statementDescriptor",
                label: t`Statement descriptor`,
                type: "string",
                headerClass: "uppercase",
                tdClass: "w-1/2",
              },
            ]}
          />
        ) : (
          <div className="flex justify-center p-responsive">
            <Spinner size={20} color="gray"></Spinner>
          </div>
        )}
      </div>
      {!filtering && (
        <PaginationControls
          endpoint="/stripe/payouts"
          onListChange={(payouts: any) => {
            setParamsFromUrl({});
            setPayouts(payouts);
          }}
          initialPage={
            (!!paramsFromUrl?.page && parseInt(paramsFromUrl.page)) || 1
          }
          requestParameters={filterSettingsToRequestParameters(
            filterSettings,
            paramsFromUrl
          )}
          hide={!payouts}
        />
      )}
    </Main>
  );
};

const filterSettingsToRequestParameters = (
  filterSettings: any,
  fromUrl: any
) => {
  let requestParameters = fromUrl;

  if (filterSettings.dateRange.enabled) {
    requestParameters = {
      ...requestParameters,
      ...buildDateRangeFilters(filterSettings.dateRange),
    };
  }
  return requestParameters;
};

export const buildDateRangeFilters = (
  dateRangeSettings: any,
  field: string = "created"
) => {
  const [gte, lte] = [`${field}[gte]`, `${field}[lte]`, `${field}[lt]`];
  const buildInBetween = () => {
    const inputStartDate = dateRangeSettings.startDate.toISOString();
    const inputEndDate = dateRangeSettings.endDate.toISOString();
    const startDateTime = moment(inputStartDate).tz(dbTimezone).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const endDateTime = moment(inputEndDate).clone().tz(dbTimezone).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    return {
      [gte]: startDateTime.valueOf() / 1000,
      [lte]: endDateTime.valueOf() / 1000,
    };
  };

  return (
    {
      [dateRangeOption.inBetween]: buildInBetween,
    }[dateRangeSettings.type] ||
    (() => {
      alert(t`Date Filter Type Not Found: ${dateRangeSettings.type}`);
      return {};
    })
  )();
};

export default Payouts;
