import { API } from "aws-amplify";
import React from "react";
import Button from "../../../Button";
import BrandollarsSettingsComponent from "../BrandollarsSettingsComponent";
import Spinner from "../../../Spinner";
import { t, Trans } from "@lingui/macro";

const BuildLivenOptions: React.FC<{
  location: any;
  updateLocation: any;
  merchantId: any;
  register: any;
  handleSubmit: any;
  reset: any;
  isSubmitting: any;
  setIsSubmitting: any;
  requestError: any;
  setRequestError: any;
  setValue: any;
}> = ({
  location,
  updateLocation,
  merchantId,
  register,
  handleSubmit,
  reset,
  isSubmitting,
  setIsSubmitting,
  requestError,
  setRequestError,
  setValue,
}) => {
  const isConnected = location.settings?.liven_payments?.connected === true;

  const SetLocation = (data: any) => {
    setIsSubmitting(true);
    const endpoint = `/locations/${location.id}`;

    let postBody = {
      headers: { "copper-account": merchantId },
      body: {
        settings: {
          liven_payments: data,
        },
      },
    } as any;
    return API.post("Conduit", endpoint, postBody);
  };

  const disconnectFromLiven = () => {
    SetLocation("")
      .then(() => {
        updateLocation();
        reset({
          branch_id: "",
          pos_passcode: "",
          connected: "false",
          issue_brandollar: "false",
        });
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const saveSettings = (formData: any) => {
    const { branch_id, issue_brandollar, pos_passcode } = formData;
    SetLocation({ branch_id, issue_brandollar, pos_passcode })
      .then(() => {
        updateLocation();
      })
      .catch((err) => {
        setRequestError(err.response?.data?.error?.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const disconnectButton = (
    <Button onClick={disconnectFromLiven}>
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>
          <Trans>Disconnect</Trans>
        </span>
      </>
    </Button>
  );
  const connectButton = (
    <button
      type={"submit"}
      className={`copper-button-confirm ${false && "text-transparent"}`}
    >
      <>
        {isSubmitting && (
          <div className="absolute flex align-center justify-center -my-0.5 px-1 md:my-0.5 md:px-1.5 ml-7">
            <Spinner size={20} color="white"></Spinner>
          </div>
        )}
        <span className={`${isSubmitting && "text-transparent"}`}>
          <Trans>Connect</Trans>
        </span>
      </>
    </button>
  );

  return (
    <form className="h-full" onSubmit={handleSubmit(saveSettings)}>
      <div className="flex flex-col justify-between h-full">
        <div className="space-y-3">
          <div>
            <label
              htmlFor="branch-id"
              className="block text-base py-1 text-gray-700"
            >
              <Trans>Branch ID</Trans>
            </label>
            <input
              ref={register({ required: t`Branch ID required` })}
              id="branch-id"
              name="branch_id"
              disabled={isConnected}
              autoComplete="off"
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={location.settings?.liven_payments?.branch_id}
            />
          </div>
          <div>
            <label
              htmlFor="pos-passcode"
              className="block text-base py-1 text-gray-700"
            >
              <Trans>POS Passcode</Trans>
            </label>
            <input
              ref={register()}
              id="pos-passcode"
              name="pos_passcode"
              disabled={isConnected}
              autoComplete="off"
              className={isConnected ? "copper-input-disabled" : "copper-input"}
              defaultValue={location.settings?.liven_payments?.pos_passcode}
              placeholder="Required if Brandollar issuing is enabled"
            />
          </div>
          <BrandollarsSettingsComponent
            isDisabled={isConnected}
            register={register}
            location={location}
            setValue={setValue}
          />
        </div>

        <div className="flex justify-between">
          {!!requestError ? (
            <span className="label-error my-auto">{requestError}</span>
          ) : (
            <div />
          )}
          {isConnected ? disconnectButton : connectButton}
        </div>
      </div>
    </form>
  );
};

export const LivenTab = {
  name: "Liven",
  resellersOnly: false,
  navContent: (active: boolean) => (
    <>
      <img
        className=""
        width="80"
        alt="liven-logo"
        src={`/img/payment_options/liven-${active ? "active" : "inactive"}.svg`}
      />
    </>
  ),
  headerText: t`Connect with Liven`,
  build: BuildLivenOptions,
};

export default React.memo(BuildLivenOptions);
