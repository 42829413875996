import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import DataTable from "../DataTable";
import Spinner from "../Spinner";
import { t, Trans } from "@lingui/macro";

const UsersList: React.FC<{
  account: any;
  users: any;
  onDeleteUser: any;
  onReInviteUser: any;
}> = ({ account, users, onDeleteUser, onReInviteUser }) => {
  const [locations, setLocations] = useState<any[] | any>([]);

  useEffect(() => {
    API.get("Conduit", "/locations", {
      headers: { "copper-account": account.id },
      queryStringParameters: { limit: 100 },
    }).then((locations: any) => {
      if (locations.data) {
        setLocations(
          locations.data.map((l: any) => ({ id: l.id, name: l.display_name }))
        );
      }
    });
  }, []);

  if (!users || !locations) {
    return (
      <div className="flex justify-center p-responsive">
        <Spinner size={20} color="gray"></Spinner>
      </div>
    );
  }

  const customControls = (id: string, status: string) => (
    <div className="text-right space-x-4	">
      {status === "FORCE_CHANGE_PASSWORD" && (
        <button
          className="copper-button-cancel p-2"
          onClick={() => onReInviteUser(id)}
        >
          <Trans>Resend Invite</Trans>
        </button>
      )}
      <button
        className="copper-button-delete text-white bg-red-600 p-2"
        onClick={() => onDeleteUser(id)}
      >
        <Trans>Delete</Trans>
      </button>
    </div>
  );

  let transRoleList: { [name: string]: string } = {
    Employee: t`Employee`,
    Manager: t`Manager`,
    Owner: t`Owner`,
  };

  const mappedUsers =
    users?.map(({ id, name, email, status, role, location }: any) => ({
      name,
      controls: customControls(id, status),
      email,
      role: transRoleList[capitalize(role)] || t`Owner`,
      location: locations.find((l: any) => l.id === location)?.name,

      click: () => {},
    })) || [];

  const columns = [
    {
      name: "name",
      label: t`Name`,
      type: "string",
      headerClass: "w-1/3",
    },

    {
      name: "email",
      label: t`Email`,
      type: "string",
      headerClass: "w-1/3",
    },

    {
      name: "role",
      label: t`Role`,
      type: "string",
      headerClass: "w-1/3",
    },

    {
      name: "location",
      label: t`Location`,
      type: "string",
      headerClass: "w-1/3",
    },

    {
      name: "controls",
      label: "",
      type: "string",
      headerClass: "w-1/3",
    },
  ];

  return (
    <DataTable
      data={mappedUsers}
      noItemsHeader={t`No users created`}
      noItemsBody={t`Users must be created before being visible here.`}
      columns={columns}
      headerClasses="px-8"
      tdChildClass="px-8 py-2"
      // tdClass='px-responsive-sm py-responsive-sm'
      disableFullWidthCells={true}
      disableFlexBetween={true}
      // tdChildClass="px-4 py-2"
      noHeaders={false}
      roundCorners={true}
    />
  );
};

export default UsersList;

function capitalize(str: string) {
  return str ? str[0].toUpperCase() + str.slice(1) : str;
}
