import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import Button from "../Button";
import { API } from "aws-amplify";
import { t } from "@lingui/macro";

const urlCheck = (url: any) => {
  //optional: change this to a 200 response check on the URL
  return new Promise((resolve) => setTimeout(resolve, 5000));
};

enum State {
  Idle,
  InvalidSelection,
  WaitingForURL,
  Generating,
  Failed,
  Done,
}

const ExportTransactionsModal: React.FC<{
  onClickOutside: any;
  visible: boolean;
  canExport: boolean;
  currentParams: any;
}> = ({ visible, onClickOutside, canExport, currentParams }) => {
  const [url, setURL] = useState<string | null>();
  const [state, setState] = useState(State.Idle);

  useEffect(() => {
    if (url) {
      urlCheck(url).then(() => {
        setState(State.Done);
      });
    }
  }, [url]);
  useEffect(() => {
    if (!visible) {
      setState(State.Idle);
    }
  }, [visible]);
  const mainMessage = () => {
    if (!canExport) {
      return t`Select a date range (within 3 months) to export transactions.`;
    }
    switch (state) {
      case State.Idle:
        return t`Export the transactions from the selected date range and criteria?`;
      case State.InvalidSelection:
        return t`Select a date range (within 3 months) to export transactions.`;
      case State.WaitingForURL:
        return t`Transaction export in progress..`;
      case State.Generating:
        return t`Transaction export in progress..`;
      case State.Failed:
        return t`Failed to generate transaction report, please try again`;
      case State.Done:
        return t`Transactions export is completed`;
    }
  };

  return (
    <Modal
      visible={visible}
      onClickOutside={onClickOutside}
      maxWidthClass={"max-w-sm"}
    >
      <div className={"space-y-5"}>
        <div className={"text-2xl text-center"}>Export transactions</div>
        <div>{mainMessage()}</div>
        <div className={"text-center space-x-10"}>
          {state == State.Done && (
            <Button
              icon={"ion-android-download"}
              onClick={() => {
                url && window.open(url, "_blank");
              }}
            >
              Download report
            </Button>
          )}
          {canExport && state == State.Idle && (
            <Button
              icon={"ion-android-sync"}
              onClick={() => {
                {
                  setState(State.WaitingForURL);
                  API.get("Conduit", "/transactions", {
                    queryStringParameters: {
                      ...currentParams,
                      csv_export: true,
                    },
                  })
                    .then((response) => {
                      setURL(response.url);
                      setState(State.Generating);
                    })
                    .catch((e) => {
                      console.log(e);
                      setState(State.Failed);
                    });
                }
              }}
            >
              Export
            </Button>
          )}
          <Button
            icon={"ion-android-close"}
            onClick={() => {
              setState(State.Idle);
              onClickOutside();
            }}
          >
            {state == State.Done || state == State.Failed || !canExport
              ? t`Close`
              : t`Cancel`}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ExportTransactionsModal;
