export const first = (arr: Array<any>) => (arr?.length && arr[0]) || undefined;

export const last = (arr: Array<any>) => {
  if (!arr) {
    return undefined;
  }
  const { length } = arr;
  return arr[length - 1];
};

export const separate = (arr: Array<any>, separator: any) => {
  if (!arr.length) {
    return arr;
  }
  const separatedArray = [arr[0]];
  for (let i = 1; i < arr.length; i++) {
    separatedArray.push(separator);
    separatedArray.push(arr[i]);
  }
  return separatedArray;
};
