import React from "react";

const AddressInfo: React.FC<{
  address: any;
}> = ({ address }) => {
  const isSingapore = address.country.toLowerCase() == "sg";
  return (
    <>
      <div>{address.line1}</div>
      {!!address.line2 && <div>{address.line2}</div>}
      {isSingapore ? (
        <div className="pb-responsive-xs">{`Singapore ${address.postal_code}`}</div>
      ) : (
        <div className="pb-responsive-xs">{`${address.city}, ${address.state} ${address.postal_code}`}</div>
      )}
      <div>{address.country}</div>
    </>
  );
};

export default AddressInfo;
