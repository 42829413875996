import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { t, Trans } from "@lingui/macro";
import {
  arrayToSelectOptions,
  getQueryParam,
  stringToValueList,
} from "../../utils/util";
import { ChecklistDropdown } from "../../ChecklistDropdown";
import Spinner from "../../Spinner";

registerLocale("es", es);

function pair(key: string, value: string) {
  return { key: key, value: value };
}

export const statusOptions = [
  pair("active", t`Active`),
  pair("inactive", t`Inactive`),
  pair("disabled", t`Disabled`),
];

export const resetFilterSettings = {
  sort: "",
  sortDirection: "",
  name: "",
  location: "",
  status: ["active", "inactive"],
};

export const getMerchantQueryFilterSettings = () => ({
  sort: getQueryParam("sort") ?? "",
  sortDirection: getQueryParam("direction") ?? "",
  name: getQueryParam("name") ?? "",
  location: getQueryParam("location") ?? "",
  status: stringToValueList(getQueryParam("status"), "-", [
    "active",
    "inactive",
  ]),
});

const MerchantFilter: React.FC<any> = (props) => {
  const { loading, filterSettings, setFilterSettings } = props;

  const [debounceTimer, setDebounceTimer] = useState<any>();

  const [internalFilterSettings, setInternalFilterSettings] =
    useState(filterSettings);

  const updateExternalFilter = (filterSettings: any) => {
    setFilterSettings(filterSettings);
  };

  const updateFilter = (update: any, debounce: boolean = false) => {
    clearTimeout(debounceTimer);
    const updatedFilter = { ...internalFilterSettings, ...update };
    setInternalFilterSettings(updatedFilter);
    if (!debounce) {
      updateExternalFilter(updatedFilter);
      return;
    }
    setDebounceTimer(
      setTimeout(() => {
        updateExternalFilter(updatedFilter);
      }, 500)
    );
    return () => clearTimeout(debounceTimer);
  };

  const filterInputContainer = (
    text: string,
    contents: any,
    extra: any = null
  ) => {
    return (
      <div className="text-responsive-xs">
        <div className={"pl-1 pb-1"}>{text}</div>
        <div className={"w-24 flex"}>
          {contents}
          {extra}
        </div>
      </div>
    );
  };

  const clearButton = (
    <div
      className="text-responsive-xs font-light flex cursor-pointer text-copper-purple p-0.5  mr-1 my-4"
      onClick={() => {
        updateFilter(resetFilterSettings);
      }}
    >
      {loading ? <Spinner size={20} color={"gray"} /> : <Trans>Clear</Trans>}
    </div>
  );

  const sortDirectionButton = (
    <div
      className={"cursor-pointer"}
      onClick={() => {
        updateFilter({
          sortDirection:
            filterSettings.sortDirection == "desc" ||
            filterSettings.sortDirection == ""
              ? "asc"
              : "desc",
        });
      }}
    >
      <svg
        transform={
          filterSettings.sortDirection == "asc" ? "scale(-1,-1)" : "scale(1,1)"
        }
        width={24}
        height={24}
        fill={"#959595"}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M7.03 13.92H11.03V5L13.04 4.97V13.92H17.03L12.03 18.92Z" />
      </svg>
    </div>
  );

  const sortDropdown = (
    <div className={"pr-2"}>
      {filterInputContainer(
        t`Sort`,
        <select
          className="bg-white copper-input-basic text-gray-500 rounded p-0.5 px-1.5 w-20 h-6"
          value={internalFilterSettings.sort}
          onChange={(e) => {
            updateFilter({
              sort: e.target.value,
            });
          }}
        >
          <option value="locations">{t`Locations`}</option>
          <option value="name">{t`Name`}</option>
        </select>,
        sortDirectionButton
      )}
    </div>
  );

  const nameInput = filterInputContainer(
    t`Name`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      value={internalFilterSettings.name}
      onChange={(e) => {
        updateFilter(
          {
            name: e.target.value,
          },
          true
        );
      }}
    />
  );

  const locationInput = filterInputContainer(
    t`Location`,
    <input
      className="copper-input-basic rounded mr-2 pl-2 pr-1 w-20 h-6"
      value={internalFilterSettings.location}
      onChange={(e) => {
        updateFilter(
          {
            location: e.target.value,
          },
          true
        );
      }}
    />
  );

  const statusDropdown = filterInputContainer(
    t`Status`,
    <ChecklistDropdown
      items={statusOptions}
      selectedItems={internalFilterSettings.status}
      onSelection={(selection) => {
        updateFilter(
          {
            status: selection,
          },
          true
        );
      }}
      noSelectionText={t`All`}
    />
  );

  return (
    <div className="flex flex-row">
      {sortDropdown}
      {statusDropdown}
      {nameInput}
      {locationInput}
      {clearButton}
    </div>
  );
};

export default MerchantFilter;
