import React from "react";

const InfoCard: React.FC<{
  data: any;
  description: string;
  additionalInfo?: any;
}> = ({ data, description, additionalInfo }) => {
  if (isNaN(data)) {
    return <></>;
  }

  return (
    <div className="bg-white border rounded-md flex flex-col justify-center text-center shadow-lg w-40 h-40">
      <div className="text-responsive-5xl font-semibold">{data}</div>
      <div className="text-responsive-sm opacity-60 -mt-2">{description}</div>
      <div className={`${!additionalInfo && "text-transparent"}`}>
        {additionalInfo || "_"}
      </div>
    </div>
  );
};

export default InfoCard;
