import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AddressFields from "../components/AddressFields";
import Button from "../components/Button";
import DataContainer from "../components/DataContainer";
import FormInput from "../components/FormInput";
import LeftLabeledContainer from "../components/LeftLabeledContainer";
import UsersList from "../components/lists/UsersList";
import NewUserModal from "../components/modals/NewUserModal";
import ResourceId from "../components/ResourceId";
import Spinner from "../components/Spinner";
import { useAuth } from "../services/auth-context";
import * as Conduit from "../services/Conduit";
import { compareObjects } from "../util/object";
import Main from "./containers/Main";
import { t, Trans } from "@lingui/macro";

const Settings: React.FC = () => {
  const { register, handleSubmit, errors } = useForm();
  const [hasEdited, setHasEdited] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const { user, isOverrideUser, isReseller, isAdmin } = useAuth();
  const [account, setAccount] = useState<any>();
  const [editedName, setEditedName] = useState<any>();
  const [originalAddress, setOriginalAddress] = useState<any>();
  const [editedAddress, setEditedAddress] = useState<any>();
  const accountId = user!.account;
  const [resetting, setResetting] = useState(false);
  const accountName = account?.name;
  const [ShowingNewUserModal, setShowingNewUserModal] =
    useState<boolean>(false);
  const [users, setUsers] = useState<any>(null);

  const getUsers = () => {
    Conduit.usersAccount.getUsers(accountId).then((usr) => {
      setUsers(usr.data);
    });
  };

  const deleteUser = (userId: string) => {
    Conduit.usersAccount.deleteUser(accountId, userId).then(() => {
      getUsers();
    });
  };
  const reInviteUser = (userId: string) => {
    Conduit.usersAccount.reInviteUser(accountId, userId, undefined);
  };

  useEffect(() => {
    API.get("Conduit", `/accounts/${accountId}`, {}).then((account: any) => {
      setAccount(account);
      setEditedName(account.name);
      const addressFormData = {
        line2: "",
        ...account.address,
      };
      setOriginalAddress(addressFormData);
      setEditedAddress(addressFormData);
    });
    getUsers();
  }, [accountId]);

  if (!originalAddress || resetting) {
    if (resetting) {
      setTimeout(() => {
        setEditedName(accountName);
        setEditedAddress(originalAddress);
        setResetting(false);
      }, 200);
    }
    return (
      <Main>
        <div className="flex justify-center p-responsive">
          <Spinner size={20} color="gray"></Spinner>
        </div>
      </Main>
    );
  }

  const onSave = (formData: any) => {
    setIsSubmitting(true);
    setSubmitError("");
    API.post("Conduit", `/accounts/${accountId}`, {
      body: formData,
    })
      .then(() => {
        setAccount(formData);
        setEditedName(formData.name);
        const addressFormData = {
          ...formData.address,
        };
        setOriginalAddress(addressFormData);
        setEditedAddress(addressFormData);
      })
      .catch((err) => setSubmitError(err.response?.data?.error?.message))
      .finally(() => setIsSubmitting(false));
  };

  const changesMade =
    accountName !== editedName ||
    !compareObjects(originalAddress, editedAddress);
  if (changesMade !== hasEdited) {
    setHasEdited(changesMade);
  }

  return (
    <Main>
      <div className="space-y-8 mb-24">
        <div>
          <DataContainer
            title={t`Account Settings`}
            controls={
              <div className="mt-3">
                <ResourceId>{accountId}</ResourceId>
              </div>
            }
          >
            <form onSubmit={handleSubmit(onSave)}>
              <div className="bg-form border-b flex flex-row justify-center -m-8 py-8">
                <div className="space-y-14 min-w-max max-w-2xl w-3/5">
                  <LeftLabeledContainer
                    name={t`Account name`}
                    widthClass="w-36"
                  >
                    <FormInput
                      formRegisterRef={register({ required: t`Name required` })}
                      id="name"
                      defaultValue={accountName}
                      error={errors?.name}
                      onChange={(e: any) => setEditedName(e.target.value)}
                    />
                  </LeftLabeledContainer>
                  <AddressFields
                    containerWidthClass="w-36"
                    label={t`Business address`}
                    register={register}
                    errors={errors}
                    defaultAddress={originalAddress}
                    hook={editedAddress}
                    setHook={setEditedAddress}
                  />
                  {/* )} */}
                </div>
              </div>

              <div className="flex justify-end mt-12 -mr-4 -mb-4">
                <>
                  <div className="flex justify-start label-error my-auto w-full">
                    {submitError}
                  </div>
                  <Button
                    onClick={() => setResetting(true)}
                    disabled={isSubmitting || !hasEdited}
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <button
                    type="submit"
                    className={`copper-button-confirm${
                      isSubmitting || !hasEdited ? "-disabled" : ""
                    } ${
                      isSubmitting && "text-transparent"
                    } ml-2 flex justify-center bg-color-red`}
                    disabled={isSubmitting || !hasEdited}
                  >
                    {isSubmitting && (
                      <div className="absolute flex align-center justify-center mx-auto -my-0.5 px-1 md:my-0 md:px-1.5">
                        <Spinner size={20} color="white"></Spinner>
                      </div>
                    )}
                    <Trans>Save</Trans>
                  </button>
                </>
              </div>
            </form>
          </DataContainer>
        </div>
        <div className="h-full">
          <DataContainer
            title={t`Users`}
            controls={
              <Button
                onClick={() => setShowingNewUserModal(true)}
                icon="ion-android-add"
              >
                <Trans>New</Trans>
              </Button>
            }
            removeInnerPadding={true}
          >
            <UsersList
              account={account}
              users={users}
              onDeleteUser={deleteUser}
              onReInviteUser={reInviteUser}
            />
            <NewUserModal
              merchantId={accountId}
              visible={ShowingNewUserModal}
              onSubmitSuccess={() => {
                getUsers();
                setShowingNewUserModal(false);
              }}
              onCancel={() => setShowingNewUserModal(false)}
              onClickOutside={() => setShowingNewUserModal(false)}
              hideRoleLocation={isReseller || isAdmin}
            />
          </DataContainer>
        </div>
      </div>
    </Main>
  );
};

export default Settings;
