import React, { useState } from "react";
import Toggle from "../../Toggle";
import { Trans } from "@lingui/macro";

const BrandollarsSettingsComponent: React.FC<{
  isDisabled?: boolean;
  register: any;
  location?: any;
  setValue: any;
}> = ({ location, setValue, register, isDisabled = false }) => {
  const [issueBrandollar, setIssueBrandollar] = useState(
    location.settings?.liven_payments?.issue_brandollar ?? false
  );

  return (
    <div className="space-y-3">
      <div>
        <div className="flex items-center h-8">
          <Toggle
            isDisabled={isDisabled}
            onChange={(value: boolean) => {
              const newValue = !issueBrandollar;
              setIssueBrandollar(newValue);
              setValue("issue_brandollar", newValue);
            }}
            value={issueBrandollar}
          />
          <input
            id="issue-brandollar"
            name="issue_brandollar"
            value={issueBrandollar}
            ref={register()}
            type={"hidden"}
          />
          <span className="block text-base py-1 text-gray-700 p-16px">
            <Trans>
              Issue Brandollar rewards on transactions paid with methods other
              than Liven
            </Trans>
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BrandollarsSettingsComponent);
