import moment from "moment/moment";
import React from "react";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";

const dateFormat = "YYYY-MM-DD";
export const ALL = "all";

export function renderAmountRounded(amount: number, currency: string) {
  return (amount / 100).toLocaleString(void 0, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 0,
  });
}

export function renderAmount(amount: number, currency: string) {
  return (amount / 100).toLocaleString(void 0, {
    style: "currency",
    currency: currency,
  });
}

export function renderAmountThousands(amount: number) {
  return `$${
    amount < 100000
      ? Math.round(amount / 100)
      : Math.round(amount / 100000) + "k"
  }`;
}

export const isLocalDevEnv =
  window.location.origin.includes("staging") ||
  window.location.origin.includes("dev") ||
  window.location.origin.includes("localhost") ||
  window.location.origin.includes("ngrok.io");

export const getQueryParam = (name: string) => {
  let params = new URLSearchParams(window.location.search);
  return params.get(name);
};

export const safeCents = (value?: string) => {
  if (!value) {
    return 0;
  }
  const result = parseFloat(value);
  if (isNaN(result)) {
    return 0;
  }
  return (result * 100) | 0;
};
export const arrayToSelectOptions = (array: any[]) => {
  return array.map((entry) => (
    <option className="font-light" key={entry.key} value={entry.key}>
      {i18n._(entry.value)}
    </option>
  ));
};
export const queryDateParam = (param: string) => {
  const value = getQueryParam(param);
  return value && moment(value, dateFormat).toDate();
};

export function valueListToString(list?: string[], separator: string = "-") {
  if (!list) {
    return "";
  }
  return list.join(separator);
}

export function stringToValueList(
  s: string | null,
  separator: string = "-",
  defaultIfEmpty: any = []
) {
  if (!s) {
    return defaultIfEmpty || [];
  }
  const result = s.split(separator);
  if (result.length === 0 && defaultIfEmpty) {
    return defaultIfEmpty;
  }
  return result;
}

export function positiveOrZero(value: number) {
  return value > 0 ? value : 0;
}

export function statusName(value: string) {
  switch (value) {
    case "active":
      return <Trans>Active</Trans>;
    case "inactive":
      return <Trans>Inactive</Trans>;
    case "disabled":
      return <Trans>Disabled</Trans>;
    default:
      return "";
  }
}
