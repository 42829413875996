import React from "react";
import Modal from "../Modal";
import ModalForm from "../ModalForm";

const ConfirmModal: React.FC<{
  onClickOutside: any;
  visible: boolean;
  title: string;
  onConfirmClicked: Function;
  onCancelClicked: Function;
  bodyText: string;
  buttonText: string;
  isSubmitting: boolean;
}> = ({
  visible,
  onClickOutside,
  title,
  onConfirmClicked,
  onCancelClicked,
  bodyText,
  buttonText,
  isSubmitting,
}) => {
  return (
    <Modal
      visible={visible}
      onClickOutside={onClickOutside}
      maxWidthClass={"max-w-sm"}
    >
      <ModalForm
        title={title}
        isSubmitting={isSubmitting}
        onCancel={onCancelClicked}
        handleSubmit={onConfirmClicked}
        submitError={false}
        buttonClass="copper-button-delete"
        confirmButtonText={buttonText}
      >
        <div className="text-responsive-base">{bodyText}</div>
      </ModalForm>
    </Modal>
  );
};

export default ConfirmModal;
