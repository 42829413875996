import React, { useState } from "react";
import getImageAttributes from "../util/getImageAttributes";
import { t, Trans } from "@lingui/macro";

const UploadImage: React.FC<{
  name: string;
  onSelect?: any;
  imageUrl: any;
  register?: any;
  description?: any;
  label?: string;
  toolTip?: string;
  imageClass?: string;
  onRemoveImage?: any;
  fixedSize?: string;
  uploadButtonClass?: string;
  minWidth?: number;
}> = ({
  name,
  register,
  onSelect,
  imageUrl,
  onRemoveImage,
  description,
  label,
  toolTip,
  imageClass,
  uploadButtonClass,
  minWidth,
  fixedSize,
}) => {
  const [error, setError] = useState<null | string>(null);
  const [_imageUri, setImageUri] = useState<any>(imageUrl);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const validateImage = async (file: any) => {
    if (!fixedSize || !file) return true;
    setError(null);
    try {
      const { height, width } = await getImageAttributes(
        URL.createObjectURL(file)
      );
      const vSize: any = fixedSize?.split("x");
      return vSize[0] === height.toString() && vSize[1] === width.toString();
    } catch (error) {
      console.error(error);
      setError(t`Invalid Image Size`);
      onSelect && onSelect("");
      return false;
    }
  };
  const onSelectImage = async (event: any) => {
    const file = event.target.files[0];
    if (await validateImage(file)) {
      onSelect && onSelect(file);
      setImageUri(URL.createObjectURL(file));
    } else {
      onSelect && onSelect("");
      setError(t`Invalid Image Size`);
    }
  };

  return (
    <div className="flex flex-row gap-4 justify-evenly">
      {!error && _imageUri && (
        <div
          className="relative"
          onMouseEnter={() => _imageUri && setIsMouseOver(true)}
          onMouseLeave={() => _imageUri && setIsMouseOver(false)}
        >
          <img
            className={`${imageClass} ${
              _imageUri ? "" : `w-${minWidth || 20} bg-gray-100`
            } p-2 border border-gray-200 rounded-md`}
            alt=""
            src={_imageUri || null}
          />
          {isMouseOver && (
            <div className="absolute text-12px  cursor-default top-0 left-0 w-full h-full bg-gray-300 bg-opacity-70 rounded-md flex items-center justify-center">
              <div
                className="flex items-center justify-center"
                onClick={() => {
                  onRemoveImage(null);
                  setImageUri(null);
                }}
              >
                <img
                  className="cursor-pointer inline-block h-3 -mt-0.5 mr-0.5"
                  src="/img/trash.svg"
                  alt="trash"
                />
                <span className="cursor-pointer text-gray-600">
                  <Trans>Remove</Trans>
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {(error || !_imageUri) && (
        <div
          className={`block self-center h-20 w-${
            minWidth || 20
          } p-2 border bg-gray-300 border-gray-200 rounded-md`}
        ></div>
      )}
      <label
        htmlFor={name}
        className={`${uploadButtonClass} flex flex-row items-center justify-center gap-4 px-12 border-dashed border ${
          error ? "border-2	 border-red-800" : "border-gray-200"
        } hover:border-gray-700`}
      >
        <img
          className="h-auto self-center"
          alt="upload"
          src="/img/upload_icon.svg"
        />
        {description && (
          <div className="text-xs text-gray-500">
            <span className="block text-xs text-red-800">{error}</span>
            {label}
            <span className="block text-tiny">{description}</span>
          </div>
        )}
      </label>
      {toolTip && (
        <span className="has-tooltip float-right" onMouseOver={() => {}}>
          <span className="tooltip rounded shadow-lg  bg-white p-3 w-56 text-base mt-0 -ml-52">
            {toolTip}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ionicon w-5 text-gray-light"
            fill="currentColor"
            viewBox="0 0 512 512"
          >
            <path d="M256 56C145.72 56 56 145.72 56 256s89.72 200 200 200 200-89.72 200-200S366.28 56 256 56zm0 82a26 26 0 11-26 26 26 26 0 0126-26zm64 226H200v-32h44v-88h-32v-32h64v120h44z" />
          </svg>
        </span>
      )}
      <input
        id={name}
        ref={register ? register() : null}
        name={name}
        onChange={onSelectImage}
        className="hidden"
        type="file"
        accept="image/png, image/jpeg"
      />
    </div>
  );
};

export default UploadImage;
