import React, { useState } from "react";
import { omitEmpty } from "../../../util/object";
import useClickOutside from "../../utils/useClickOutside";

import { t, Trans } from "@lingui/macro";

const otherDeviceTypes = [
  "acme_Anvil",
  "bbpos_WPOSE",
  "clover_Flex",
  "pax_A60",
  "pax_A77",
  "pax_A920",
  "poynt_P5",
  "poynt_P6",
  "wizarpos_Q2",
];

export type FilterState = {
  location?: string;
  device_type?: string;
  issue?: string;
  enabled?: boolean;
  label?: string;
  serial_number?: string;
  sort_by?: string;
  selected?: string;
};

const DevicesFilter: React.FC<{
  defaultValue: FilterState;
  locations: { id: string; name: string }[];
  isShowingFilters: boolean;
  setIsShowingFilters: (isShowing: boolean) => void;
  onApplyFilters: (filters: FilterState) => void;
}> = ({
  defaultValue,
  isShowingFilters,
  setIsShowingFilters,
  locations,
  onApplyFilters,
}) => {
  const [filters, setFilters] = useState<FilterState>(defaultValue);

  const clickOutsideRef = useClickOutside(() => {
    if (!isShowingFilters) {
      return;
    }
    setTimeout(() => setIsShowingFilters(false), 150);
  });

  const applyFilters = () => onApplyFilters(filters);

  const resetFilters = () => {
    setFilters({});
    onApplyFilters({});
  };

  const onChangeFilter = ({ target }: any) => {
    setFilters(
      omitEmpty({
        ...filters,
        [target.name]:
          target.type === "checkbox" ? target.checked : target.value,
      })
    );
  };

  if (!isShowingFilters) {
    return null;
  }

  return (
    <div
      className="shadow-lg absolute flex flex-col bg-white right-9 mt-1 top-36 w-56 border rounded-md z-50"
      ref={clickOutsideRef}
    >
      <div className="rounded-t flex justify-between border-b bg-gray-50  p-1.5">
        <div className="text-responsive-base font-medium my-auto">Filters</div>
        <div className="text-responsive-xs font-light flex">
          <div
            className="cursor-pointer text-copper-purple p-0.5  mr-1 my-auto"
            onClick={resetFilters}
          >
            <Trans>Clear</Trans>
          </div>
          <div
            className="cursor-pointer px-2 py-0.5 rounded text-white bg-copper-purple my-auto hover:bg-copper-purple-hover"
            onClick={applyFilters}
          >
            <Trans>Apply</Trans>
          </div>
        </div>
      </div>

      <FilterLabel htmlFor="location">
        <Trans>Location</Trans>
      </FilterLabel>
      <FilterControl>
        <select
          className="text-gray-500 bg-white copper-input-basic rounded p-0.5 px-1.5 w-full"
          name="location"
          id="location"
          onChange={onChangeFilter}
          value={filters.location || ""}
        >
          <option value="">{t`All`}</option>
          {locations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </select>
      </FilterControl>

      <FilterLabel htmlFor="device_type">
        <Trans>Device Type</Trans>
      </FilterLabel>
      <FilterControl>
        <select
          className="text-gray-500 bg-white copper-input-basic rounded p-0.5 px-1.5 w-full"
          name="device_type"
          id="device_type"
          onChange={onChangeFilter}
          value={filters.device_type || ""}
        >
          <option value="">{t`Any`}</option>
          <optgroup label="Copper">
            <option value="copper_Cord">{t`Cord`}</option>
            <option value="copper_VPOS">VPOS</option>
          </optgroup>
          <optgroup label="Companions">
            <option value="companion">{t`Any Companion`}</option>
            {otherDeviceTypes.map((deviceType) => (
              <option key={deviceType}>{deviceType}</option>
            ))}
          </optgroup>
        </select>
      </FilterControl>

      <FilterLabel htmlFor="issue">
        <Trans>Issue</Trans>
      </FilterLabel>
      <FilterControl>
        <select
          className="text-gray-500 bg-white copper-input-basic rounded p-0.5 px-1.5 w-full"
          name="issue"
          id="issue"
          onChange={onChangeFilter}
          value={filters.issue || ""}
        >
          <option value="">{t`None`}</option>
          <option value="any">{t`Any issue`}</option>
          <option value="offline">{t`Offline`}</option>
          <option value="outdated">{t`Cord update needed`}</option>
          <option value="wifi_rssi">{t`Poor wifi strength`}</option>
        </select>
      </FilterControl>

      <FilterLabel htmlFor="enabled">
        <Trans>Enabled</Trans>
      </FilterLabel>
      <FilterControl>
        <select
          className="text-gray-500 bg-white copper-input-basic rounded p-0.5 px-1.5 w-full"
          name="enabled"
          id="enabled"
          onChange={onChangeFilter}
          value={filters.enabled ? String(filters.enabled) : ""}
        >
          <option value="">{t`All`}</option>
          <option value="true">{t`Yes`}</option>
          <option value="false">{t`No`}</option>
        </select>
      </FilterControl>

      <FilterLabel htmlFor="label">
        <Trans>Label</Trans>
      </FilterLabel>
      <FilterControl>
        <input
          className="text-gray-500 bg-white copper-input-basic rounded p-0.5 px-1.5 w-full"
          name="label"
          id="label"
          onChange={onChangeFilter}
          value={filters.label || ""}
          type="search"
        />
      </FilterControl>

      <FilterLabel htmlFor="serial_number">
        <Trans>Serial Number</Trans>
      </FilterLabel>
      <FilterControl>
        <input
          className="text-gray-500 bg-white copper-input-basic rounded p-0.5 px-1.5 w-full"
          name="serial_number"
          id="serial_number"
          onChange={onChangeFilter}
          value={filters.serial_number || ""}
          type="search"
        />
      </FilterControl>

      <FilterLabel htmlFor="sort_by">
        <Trans>Sort by</Trans>
      </FilterLabel>
      <FilterControl>
        <select
          className="text-gray-500 bg-white copper-input-basic rounded p-0.5 px-1.5 w-full"
          name="sort_by"
          id="sort_by"
          onChange={onChangeFilter}
          value={filters.sort_by || ""}
        >
          <option value="">{t`Location`}</option>
          <option value="label">{t`Label`}</option>
          <option value="serial_number">{t`Serial number`}</option>
          <option value="last_seen">{t`Last seen`}</option>
          <option value="wifi_rssi">{t`Wifi strength`}</option>
          <option value="enabled">{t`Enabled`}</option>
        </select>
      </FilterControl>
    </div>
  );
};

const FilterLabel = ({
  children,
  htmlFor,
}: {
  children: React.ReactNode;
  htmlFor: string;
}) => (
  <div className="flex p-2">
    <div className="text-responsive-xs font-medium mt-0.5 opacity-70">
      <label htmlFor={htmlFor}>{children}</label>
    </div>
  </div>
);

const FilterControl = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-gray-50 text-responsive-xs border-t border-b px-2 py-2.5">
    {children}
  </div>
);

export default DevicesFilter;
