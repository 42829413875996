import { API } from "aws-amplify";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../components/Button";
import DataContainer from "../components/DataContainer";
import DevicesFilter, {
  FilterState,
} from "../components/filters/devices/DevicesFilter";
import DeviceList from "../components/lists/DeviceList";
import Spinner from "../components/Spinner";
import { useAuth } from "../services/auth-context";
import { t, Trans } from "@lingui/macro";

interface DevicesRegisteredProps {
  locations: { id: string; name: string }[];
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search]
  );
}

const DevicesRegistered: React.FC<DevicesRegisteredProps> = ({ locations }) => {
  const history = useHistory();
  const [devices, setDevices] = useState<any>(null);
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const containerRef = useRef<any>();
  const { user } = useAuth();
  const accountId = user!.account;

  const query = useQuery() as FilterState;
  const setQuery = (params: any) => {
    history.push({ search: new URLSearchParams(params).toString() });
  };

  const fetchDevices = useCallback(() => {
    delete query.selected;
    API.get("Conduit", "/devices/all", {
      queryStringParameters: {
        ...query,
        limit: 1000,
      },
    }).then((devices) => setDevices(devices.data));
  }, [query]);

  useEffect(() => fetchDevices(), [fetchDevices]);

  return (
    <div ref={containerRef}>
      <DataContainer
        title={t`Devices`}
        removeInnerPadding={true}
        controls={
          <>
            <Button
              onClick={() => setIsShowingFilters(!isShowingFilters)}
              icon="ion-android-funnel"
            >
              <Trans>Filter</Trans>
            </Button>
            <DevicesFilter
              isShowingFilters={isShowingFilters}
              onApplyFilters={setQuery}
              setIsShowingFilters={setIsShowingFilters}
              defaultValue={query}
              locations={locations}
            />
          </>
        }
      >
        {devices === null ? (
          <div className="flex justify-center p-responsive">
            <Spinner size={20} color="gray"></Spinner>
          </div>
        ) : (
          <DeviceList
            devices={devices}
            onDeviceEdited={fetchDevices}
            onDeviceRemoved={fetchDevices}
            locations={locations}
            merchantId={accountId}
          />
        )}
      </DataContainer>
    </div>
  );
};

export default DevicesRegistered;
