import React, { useState, useRef, useEffect } from "react";
import { API } from "aws-amplify";
import { Trans } from "@lingui/macro";

interface Props {
  reseller?: string;
  merchant?: string;
  self?: string;
}

export const Breadcrumbs: React.FC<Props> = ({ reseller, merchant, self }) => {
  const [resellerData, setResellerData] = useState<any>(null);
  const [merchantData, setMerchantData] = useState<any>(null);

  useEffect(() => {
    reseller &&
      API.get("Conduit", `/accounts/${reseller}`, {}).then((reseller) => {
        setResellerData({ id: reseller.id, name: reseller.name });
        console.log(reseller);
      });
    merchant &&
      API.get("Conduit", `/accounts/${merchant}`, {}).then((merchant) => {
        setMerchantData({ id: merchant.id, name: merchant.name });
        console.log(merchant);
      });
  }, []);
  if (!reseller && !merchant) {
    return <></>;
  }
  if ((reseller && !resellerData) || (merchant && !merchantData)) {
    return <></>;
  }
  return (
    <div className="m-2 p-2 text-sm">
      <a href={"/"}>
        <Trans>Home</Trans>
      </a>
      {" → "}
      {resellerData && (
        <>
          <a href={`/resellers/${resellerData.id}`}>{resellerData.name}</a>
          {" → "}
        </>
      )}
      {merchantData && (
        <>
          <a href={`/merchants/${merchantData.id}`}>{merchantData.name}</a>
          {" → "}
        </>
      )}
      <span>{self}</span>
    </div>
  );
};
