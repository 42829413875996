import axios from "axios";
const initAxios = () => {
  axios.interceptors.request.use((req) => {
    const { method } = req;

    if (
      (method !== "post" && method !== "put") ||
      req.headers["Content-Type"]?.split(";")[0] !== "application/json"
    ) {
      return req;
    }
    req.method = "post";
    req.headers["Content-Type"] = "application/x-www-form-urlencoded";

    const data = JSON.parse(req.data);
    const params = new URLSearchParams();

    Object.entries(data).forEach(function cb([key, value]) {
      if (typeof value === "object") {
        Object.entries(value as object).forEach(([k, v]) => {
          cb([`${key}[${k}]`, v as string]);
        });
        return;
      }

      params.append(key, value as string);
    });

    req.data = params;

    return req;
  });
};

export default initAxios;
