import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { API } from "aws-amplify";
import QRCode from "qrcode";
import { isLocalDevEnv } from "../../utils/util";

type Props = {
  id?: string;
};

export const PrintableMerchantQrInstructions: React.FC<
  RouteComponentProps<Props>
> = (props: RouteComponentProps<Props>) => {
  const { params } = props.match;
  const [busy, setBusy] = useState(true);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const getApiKey = () => {
    setBusy(true);
    API.get("Conduit", "/apikey", {
      queryStringParameters: { location: params.id, scope: 1 },
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
      },
    })

      .then(async (response) => {
        setBusy(false);
        setApiKey(response.key);
        const url = `https://${isLocalDevEnv ? "staging." : ""}cop.pe/r/${
          params.id
        }?sk=${response.key}`;
        return QRCode.toDataURL(url);
      })
      .then(async (image) => {
        await setImage(image);
        window.print();
      })
      .catch((err) => {
        setError("Failed to get API key");
        setApiKey(null);
        setBusy(false);
        console.log(err);
      });
  };

  const [image, setImage] = useState();

  useEffect(() => {
    getApiKey();
  }, []);

  if (error) {
    return (
      <div className="mt-20 h-screen overflow-hidden bg-gray-background text-center items-center justify-center">
        <div className={"text-lg"}>
          An unknown error has occurred. Please contact Copper support
        </div>
      </div>
    );
  }
  if (busy) {
    return (
      <div className="mt-20 h-screen overflow-hidden bg-gray-background text-center items-center justify-center">
        <div className={"text-lg"}>Please wait...</div>
      </div>
    );
  }
  return (
    <div className="h-full bg-gray-background text-center justify-center items-center">
      <div className={" max-w-2xl m-auto border-pink-600 mt-1"}>
        <div className={"text-lg font-bold w-full text-center"}>
          <Trans>
            For Merchant use ONLY. Do not present this code to the Consumer!
          </Trans>
        </div>
        <div className={"text-sm pt-3 text-red-400 text-center"}>
          <Trans>
            Upon departure of staff, or if you have otherwise leaked this QR
            code it is advised to generate a new code in the Copper Console
          </Trans>
        </div>
        <div className={"m-10 text-center"}>
          <img className={"m-auto"} src={image} width={400} height={400}></img>
        </div>
        <div className={"text-xl font-bold mt-4 ml-4 w-full text-center"}>
          <Trans>How to accept QR payments:</Trans>
        </div>
        <div className={"text-lg mt-5 w-full text-left"}>
          <Trans>
            1. Use your Camera app to scan this QR code to open the Store app
            (no installation required)
          </Trans>
        </div>
        <div className={"text-lg mt-5 w-full text-left"}>
          <Trans>
            2. Enter the amount to be paid and click “Continue”, a new QR code
            will be generated
          </Trans>
        </div>
        <div className={"text-lg mt-5 w-full text-left"}>
          <Trans>
            3. Ask your customer to scan the generated QR with their Camera app
          </Trans>
        </div>
        <div className={"text-lg  mt-5 w-full  text-left"}>
          <Trans>4. Confirm the payment is received on your app</Trans>
        </div>
        <div className={"text-xl font-bold mt-4 text-center w-full"}>
          <Trans> How to view recent transactions:</Trans>
        </div>
        <div className={"text-lg mt-5 w-full text-left"}>
          <Trans>
            1. Use your Camera app to scan this QR code to open the Store app
            (no installation required)
          </Trans>
        </div>
        <div className={"text-lg mt-5 w-full text-left"}>
          <Trans>2. Click the “Recent” Tab</Trans>
        </div>
        <div className={"text-lg mt-5 w-full text-left"}>
          <Trans>3. View the transactions of the current business day</Trans>
        </div>
        <div className={"text-lg  mt-5 w-full  text-left"}>
          <Trans>4. Refresh the list by sliding downwards</Trans>
        </div>
      </div>
    </div>
  );
};
